import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import {
  DialogActions,
  DialogContent,
  Chip,
  Typography,
} from '@material-ui/core';
import {
  AutocompleteInput,
  getDateInputValue,
  handleNumbers,
  ReferenceInput,
  SelectComponent,
} from '.';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';

export const CaseDiagnosisForm = ({
  record = {},
  isEdit,
  handleClose,
  caseId,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [formData, setFormData] = useState({
    case_id: caseId,
  });
  const [services, setServices] = useState([]);

  const handleDelete = useCallback(value => {
    setServices(cur => cur.filter(c => c !== value));
  }, []);
  const onClick = useCallback(
    value => {
      value[0].name = value[0].name.split(' - ')[0].split(' , ')[0];
      setServices(curServices => [...curServices, ...value]);
    },
    [setServices],
  );

  useEffect(() => {
    if (isEdit && record) {
      setFormData(f => ({
        ...f,
        ...record,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    const service_ids = Array.from(new Set(services.map(row => row.value)));
    if (isEdit) {
      return dataProvider
        .update('case-diagnosis', {
          id: record.id,
          data: { service_ids: service_ids, ...value },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('case-diagnosis', {
          data: { service_ids: service_ids, ...value },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                {!isEdit ? (
                  <Fragment>
                    <AutocompleteInput
                      className={classes.input}
                      trimLength={1}
                      customOnChange={customOnChange}
                      reference='diagnoses/list'
                      name='services'
                      label='Diagnoses'
                      openOnFocus
                      autocompleteProps={{ openOnFocus: true }}
                      viewAll
                      parentOnClick={onClick}
                      getName
                      inputStyle={{ marginRight: 0 }}
                      fullWidth
                      clearInputOnClick
                      multiSelect
                      highlightItems={services?.map(value => value.value)}
                    />
                    <div>
                      {!!services.length && (
                        <div style={{ margin: 10 }}>
                          {services.map(row => {
                            return (
                              <Chip
                                key={row.value}
                                label={
                                  <Typography
                                    style={{
                                      whiteSpace: 'normal',
                                      fontSize: 13,
                                    }}
                                  >
                                    {row.name}
                                  </Typography>
                                }
                                onDelete={() => handleDelete(row)}
                                className={classes.chip}
                                style={{
                                  height: '100%',
                                  maxWidth: 350,
                                }}
                                size='small'
                                disabled={!services}
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </Fragment>
                ) : (
                  <ReferenceInput
                    reference='diagnoses/list'
                    customOnChange={customOnChange}
                    name='service_id'
                    label='Diagnoses'
                    disabled
                    style={{ marginRight: 0 }}
                    fullWidth
                  />
                )}
                <SelectComponent
                  customOnChange={customOnChange}
                  label='Status'
                  name='status'
                  choices={statusList}
                  fullWidth
                />
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

const statusList = [
  { id: 'Active', name: 'Active' },
  { id: 'Resolved', name: 'Resolved' },
];
