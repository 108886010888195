import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import { useAuthenticated, useNotify, Notification } from 'react-admin';
import { TextField, Button, CssBaseline, Typography } from '@material-ui/core';
import { Logo } from '../components/common/Logo';
import { authPost } from '../server';
import { useStyles } from './login.styles';

export const ForgotPassword = () => {
  useAuthenticated({ page: 'forgot-password' });
  const notify = useNotify();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [view, setView] = useState('form');
  const handleChange = e => {
    const {
      target: { value },
    } = e;
    setEmail(value);
  };
  const submit = async e => {
    e.preventDefault();
    const response = await authPost('/forgot-password', { email });
    const { error } = response;
    if (error) {
      notify(
        typeof error === 'string'
          ? error
          : error.message || 'ra.notification.http_error',
        'warning',
      );
      return;
    }
    setView('message');
  };
  return (
    <main className={classes.main}>
      <CssBaseline />
      <div className={classes.paper}>
        <Logo classes={classes} />
        {view === 'form' ? (
          <Fragment>
            <Typography
              className={clsx(classes.welcome, classes.welcomeForgotPassword)}
            >
              Password recovery
            </Typography>
            <Typography
              className={clsx(classes.signIn, classes.instructionsMsg)}
            >
              Don't worry, happens to the best of us. Enter your email and we'll
              send you a link to reset your password.
            </Typography>
            <form className={classes.form} noValidate onSubmit={submit}>
              <div className={classes.inputWrapper}>
                <TextField
                  required
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  id='email'
                  label='Email'
                  name='email'
                  value={email}
                  autoComplete='email'
                  autoFocus
                  onChange={handleChange}
                />
              </div>
              <div className={classes.inputWrapper}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  disabled={!email}
                  disableElevation
                >
                  <span className={classes.buttonText}>
                    Email me a reset link
                  </span>
                </Button>
              </div>
            </form>
          </Fragment>
        ) : (
          <Fragment>
            <Typography
              className={clsx(classes.welcome, classes.welcomeForgotPassword)}
            >
              Email sent
            </Typography>
            <Typography
              className={clsx(classes.signIn, classes.instructionsMsg)}
            >
              We've sent you an email with a link to reset your password. If it
              doesn't arrive soon, check your spam folder.
            </Typography>
          </Fragment>
        )}
      </div>
      <Notification />
    </main>
  );
};
