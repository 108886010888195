import React, { Fragment, useState, memo } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useRefresh } from 'react-admin';
import { NewCommunication, NewEvent, TaskForm } from './modals';
import {
  makeStyles,
  Fab,
  Fade,
  List,
  ListItem,
  ListItemAvatar,
  Tooltip,
  ClickAwayListener,
} from '@material-ui/core';
import {
  AddIcon,
  InsertCommentIcon,
  EventIcon,
  AddAlertIcon,
  SendIcon,
  styles,
} from '../../design';
import { fileManagementActions } from '../../state/actions';

const useStyles = makeStyles(theme => ({
  fabPosition: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(2),
    minWidth: 75,
    zIndex: 20,
    ...styles.flexColumn,
  },
  fab: {
    backgroundColor: theme.palette.primary.buttons,
    '&:hover': {
      backgroundColor: '#66afdb',
    },
  },
  fabIcon: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.text,
    height: 24,
    '&:hover': {
      backgroundColor: theme.palette.secondary.text,
    },
  },
  addIcon: {
    animation: 'spin 6s linear infinite',
  },
  rotate: {
    transform: 'rotate(45deg)',
  },
  optionsContainer: {
    marginBottom: 15,
    marginLeft: 20,
  },
}));

export const FrequentlyAccessed = memo(({ caseId, isEdit }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [communicationOpen, setCommunicationModal] = useState(false);
  const [tasksOpen, setTasksModal] = useState(false);
  const [eventOpen, setEventModal] = useState(false);

  function openSendFiles() {
    dispatch(
      fileManagementActions.updateFileManagementData({ drawStatus: 'open' }),
    );
  }

  const handleModals = type => {
    setOptionsOpen(false);
    if (type === 'communication') {
      setCommunicationModal(!communicationOpen);
    } else if (type === 'tasks') {
      setTasksModal(!tasksOpen);
    } else if (type === 'send') {
      openSendFiles();
    } else {
      setEventModal(!eventOpen);
    }
  };
  return (
    <Fragment>
      <ClickAwayListener onClickAway={() => setOptionsOpen(false)}>
        <div className={classes.fabPosition}>
          <Fade
            in={optionsOpen}
            className={classes.optionsContainer}
            mountOnEnter
            unmountOnExit
          >
            <List>
              <ListItem disableGutters>
                <Tooltip title='email/fax' placement='left'>
                  <ListItemAvatar>
                    <Fab
                      className={classes.fabIcon}
                      size='small'
                      onClick={() => handleModals('send')}
                    >
                      <SendIcon className={classes.fabIcon} />
                    </Fab>
                  </ListItemAvatar>
                </Tooltip>
              </ListItem>
              <ListItem disableGutters>
                <Tooltip title='add task' placement='left'>
                  <ListItemAvatar>
                    <Fab
                      className={classes.fabIcon}
                      size='small'
                      onClick={() => handleModals('tasks')}
                    >
                      <AddAlertIcon className={classes.fabIcon} />
                    </Fab>
                  </ListItemAvatar>
                </Tooltip>
              </ListItem>
              <ListItem disableGutters>
                <Tooltip title='add communication' placement='left'>
                  <ListItemAvatar>
                    <Fab
                      className={classes.fabIcon}
                      size='small'
                      onClick={() => handleModals('communication')}
                    >
                      <InsertCommentIcon className={classes.fabIcon} />
                    </Fab>
                  </ListItemAvatar>
                </Tooltip>
              </ListItem>
              <ListItem disableGutters>
                <Tooltip title='add event' placement='left'>
                  <ListItemAvatar>
                    <Fab
                      className={classes.fabIcon}
                      size='small'
                      onClick={() => handleModals('event')}
                    >
                      <EventIcon className={classes.fabIcon} />
                    </Fab>
                  </ListItemAvatar>
                </Tooltip>
              </ListItem>
            </List>
          </Fade>
          <Fab
            className={classes.fab}
            color='primary'
            size='medium'
            onClick={() => setOptionsOpen(!optionsOpen)}
          >
            {
              <AddIcon
                className={clsx(classes.addIcon, {
                  [classes.rotate]: optionsOpen,
                })}
              />
            }
          </Fab>
        </div>
      </ClickAwayListener>
      {tasksOpen && (
        <TaskForm
          open={tasksOpen}
          handleClose={() => setTasksModal(false)}
          caseId={caseId}
          isEdit={isEdit}
          refresh={refresh}
        />
      )}
      {communicationOpen && (
        <NewCommunication
          open={communicationOpen}
          handleClose={() => setCommunicationModal(false)}
          caseId={caseId}
          isEdit={isEdit}
          refresh={refresh}
        />
      )}
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          caseId={caseId}
          isEdit={isEdit}
          refresh={refresh}
        />
      )}
    </Fragment>
  );
});
