import { makeStyles } from '@material-ui/core';
// import { styles } from '../design';
import { style } from './residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  funcShowItem: {
    fontSize: 11,
  },
  addContainer: {
    ...style(theme).addContainer,
    width: 350,
  },
  actionButton: {
    backgroundColor: theme.palette.secondary.text,
    color: theme.palette.primary.main,
  },
  editIcon: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    marginLeft: 7,
    cursor: 'pointer',
  },
  fileIcons: {
    color: '#E32939',
  },
  viewIcon: {
    color: theme.palette.secondary.main,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}));
