import React, {
  Fragment,
  cloneElement,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import clsx from 'clsx';
import qs from 'query-string';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from 'react-final-form';
import { differenceInCalendarDays, isBefore, format } from 'date-fns';
import {
  useRefresh,
  List,
  Show,
  ShowView,
  SimpleShowLayout,
  BulkDeleteButton,
  TextField,
  FunctionField,
  BooleanField,
  ChipField,
  TopToolbar,
  ReferenceField,
  Pagination,
  Labeled,
  usePermissions,
  useDataProvider,
  useNotify,
  cacheDataProviderProxy,
  required,
  useUnselectAll,
  useListContext,
  // ShowGuesser,
} from 'react-admin';
import {
  Drawer,
  IconButton,
  Chip,
  Divider,
  Button,
  Typography,
  Tooltip,
  DialogActions,
  DialogContent,
  Dialog,
  Grid,
  DialogContentText,
} from '@material-ui/core';
import { CustomButton } from '../design/material-ui';
import { authGet } from '../server';
import {
  getChip,
  constants,
  currencyFormat,
  weekDaysObj,
  limitStringSize,
} from '../utils';
import {
  ToggleButton,
  DateField,
  TrackUser,
  Datagrid,
  FilterMenu,
  FilterChips,
  CaseManagerSelect,
  StatusFilter,
  ConfirmedActionModal,
} from '../components/common';
import {
  TaskForm,
  NewEvent,
  Attachments,
  NotesModal,
} from '../components/common/modals';
import {
  AddAttachmentModal,
  ReferenceInput,
  FormTextField,
} from '../components/common/modals/modalForms';
import { ResidentShowFields, ResidentShowActions } from '../resources';
import {
  useGetCaseId,
  useShowController,
  useGetUserDivision,
  useSidebarOpen,
} from '../hooks';
import { uiActions } from '../state/actions';
import { useStyles } from './auths.styles';
import {
  CloseIcon,
  AttachmentIcon,
  AuthIcon,
  AddIcon,
  EditIcon,
  AlertIcon,
  InfoIcon,
  AddAlertIcon,
  SaveButton,
  LibraryAddCheckIcon,
} from '../design';
const { eventTypes, authFilters: filters } = constants;

const FacilityLevelActions = ({
  setDivision,
  filterActions,
  activeStatusFilters = {},
  filterManager,
  loading,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <div style={{ display: 'flex', paddingBottom: 20 }}>
            <span className={classes.title}>Authorizations</span>
            {/* Needed for spacing */}
            <span style={{ marginRight: 25 }} />
            <ToggleButton
              setDivision={setDivision}
              className={classes.toggleWrapper}
            />
          </div>
          <CustomButton
            Icon={AddIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Add'
            type='button'
            variant='text'
            size='small'
            onClick={() => setEventModal(true)}
            disabled={isViewAdmin}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <CaseManagerSelect filterManager={filterManager} />
          <FilterMenu filterActions={filterActions} filters={filters} />
          <FilterChips
            activeFilters={activeStatusFilters}
            filterActions={filterActions}
            disabled={loading}
          />
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='authorization'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const CaseLevelActions = ({
  basePath,
  data = {},
  resource,
  showAll,
  setShowAll,
  residentCaseCount,
  isViewAdmin,
}) => {
  const caseId = useGetCaseId();
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [showLevelUpdate, setShowLevelUpdate] = useState(false);
  const [levelOne, setLevelOne] = useState(false);
  useEffect(() => {
    const getLevelOneReason = async () => {
      const response = await authGet(`/cases/${caseId}/level-one`);
      const { data } = response;
      setLevelOne(data);
    };
    setLevelOne(false);
    if (caseId) getLevelOneReason();
  }, [caseId]);

  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <span className={classes.title}>Auths</span>
          <div>
            <CustomButton
              Icon={AddIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label='Add'
              type='button'
              variant='text'
              size='small'
              onClick={() => setEventModal(true)}
              disabled={isViewAdmin}
              style={{ marginRight: 5 }}
            />
            {levelOne && (
              <CustomButton
                Icon={AddIcon}
                color='#1061A0'
                backgroundColor='#EFF4FB'
                label='Update Level 1 Details'
                type='button'
                variant='text'
                size='small'
                onClick={() => setShowLevelUpdate(true)}
                disabled={isViewAdmin}
              />
            )}
          </div>
        </div>
        <div style={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 3 }}>
          {residentCaseCount > 1 && (
            <StatusFilter setShowAll={setShowAll} showAll={showAll} />
          )}
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='authorization'
          refresh={refresh}
        />
      )}
      {showLevelUpdate && (
        <UpdateLevelOneAuths
          open={showLevelUpdate}
          handleClose={() => setShowLevelUpdate(false)}
          caseId={caseId}
        />
      )}
    </Fragment>
  );
};

const AuthsShowActions = ({
  basePath,
  data = {},
  title,
  resource,
  closeAside,
  resourceRecord,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const { is_retro, authorization_status: { status } = {} } = data || {};
  return (
    <div>
      <TopToolbar className={classes.header}>
        <div className={classes.headerRow}>
          <span className={classes.title}>{title}</span>{' '}
          <span className={classes.statusChipWrapper}>
            <Chip
              size='small'
              label={status}
              className={clsx(classes.chip, classes[getChip(status)])}
            />
            {is_retro ? (
              <span style={{ marginLeft: 2 }}>
                <Chip
                  size='small'
                  label='Retro Auth'
                  className={clsx(classes.chip, classes[getChip('Retro')])}
                />
              </span>
            ) : null}
          </span>
          <IconButton onClick={closeAside} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
        <ActionButtons
          resourceRecord={resourceRecord}
          isViewAdmin={isViewAdmin}
        />
      </TopToolbar>
    </div>
  );
};

const ActionButtons = ({ resourceRecord = {}, isViewAdmin }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [addTaskOpen, setAddTaskModal] = useState(false);
  const [eventOpen, setEventModal] = useState(false);
  const [attchOpen, setAttchModal] = useState(false);
  const taskCount = get(resourceRecord, 'v_event_task.task_count', 0);
  const hasAttachments =
    Array.isArray(resourceRecord.document_ids) &&
    !!resourceRecord.document_ids.length;
  const documentCount = resourceRecord?.document_ids?.length;
  return (
    <Fragment>
      <div className={classes.actionContainer}>
        <span style={{ contentAlign: 'left' }}>
          <IconButton
            className={classes.showBtn}
            onClick={() => setEventModal(true)}
            size='small'
            disabled={isViewAdmin}
          >
            <EditIcon />
          </IconButton>
          {'         '}
          <IconButton
            className={classes.showBtn}
            onClick={() => setAddTaskModal(true)}
            size='small'
            disabled={isViewAdmin}
          >
            <AddAlertIcon />
          </IconButton>
          {'         '}
          <CustomButton
            Icon={AttachmentIcon}
            className={classes.showBtn}
            variant='text'
            badgeContent={documentCount}
            onClick={() => setAttchModal(true)}
            size='small'
            notRed
            fullSize
          />
        </span>
        <CustomButton
          Icon={AlertIcon}
          color='#829CB5'
          label='TASKS'
          type='button'
          variant='text'
          size='small'
          className={classes.showBtn}
          badgeContent={taskCount}
          component={Link}
          disabled={!taskCount}
          to={`/cases/${
            resourceRecord.case_id
          }/tasks/not-completed?${qs.stringify({
            event_type: 'Auth',
            event_id: resourceRecord.id,
          })}`}
        />
      </div>
      {addTaskOpen && (
        <TaskForm
          open={addTaskOpen}
          handleClose={() => setAddTaskModal(false)}
          caseId={resourceRecord.case_id}
          refresh={refresh}
          related_to_data={{
            related_to_type: 'Auth',
            related_to_id: resourceRecord.id,
            related_to_date: resourceRecord.start_date,
            insurance_auth_number: resourceRecord.insurance_auth_number,
          }}
        />
      )}
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          record={resourceRecord}
          form='authorization'
          isEdit
          refresh={refresh}
        />
      )}
      {attchOpen && (
        <Fragment>
          {hasAttachments ? (
            <Attachments
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              document_ids={resourceRecord.document_ids}
              title='Authorizations'
              eventId={resourceRecord.id}
              eventName={eventTypes.AUTHORIZATION_NAME}
              caseId={resourceRecord.case_id}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          ) : (
            <AddAttachmentModal
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              title='Authorizations'
              eventId={resourceRecord.id}
              eventName={eventTypes.AUTHORIZATION_NAME}
              caseId={resourceRecord.case_id}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const BulkActionButtons = ({
  toggleAside,
  recordOpenId,
  facilityLevel,
  ...props
}) => {
  const { selectedIds = [] } = props;
  const dispatch = useDispatch();
  const caseIdFromUrl = useGetCaseId();
  function onClick() {
    if (
      typeof toggleAside === 'function' &&
      selectedIds.indexOf(recordOpenId) > -1
    ) {
      toggleAside(false);
    }
    if (caseIdFromUrl) {
      setTimeout(() => {
        dispatch(uiActions.getCaseTasksCount(caseIdFromUrl));
      }, 6000);
    }
  }
  return (
    <Fragment>
      {!facilityLevel && (
        <UpdateLevelsButton
          label='Update Levels'
          selectedIds={selectedIds}
          {...props}
        />
      )}
      <BulkDeleteButton {...props} onClick={onClick} />
    </Fragment>
  );
};

const ListView = ({
  rowClick,
  listRowStyle,
  division,
  rowRecord,
  ...props
}) => {
  const isSidebarOpen = useSidebarOpen();
  const classes = useStyles({ isSidebarOpen });
  const unselectAll = useUnselectAll('case-authorizations');
  const unselectAllCases = useUnselectAll('authorizations');
  useEffect(() => {
    return () => {
      unselectAllCases();
      unselectAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classes.listContainer}>
      <Datagrid
        {...props}
        rowClick={rowClick}
        rowStyle={(record, index) => listRowStyle(record, index, rowRecord.id)}
      >
        <FunctionField
          source='insurance_auth_number'
          label='Number'
          render={record => {
            const { insurance_auth_number, ending_soon } = record;
            return (
              <div className={classes.infoContainer}>
                <span style={{ marginRight: 3 }}>{insurance_auth_number}</span>
                {ending_soon ? (
                  <Tooltip title='Ending soon' placement='right'>
                    <InfoIcon className={classes.info} />
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            );
          }}
        />
        <FunctionField
          source='authorization_type'
          label='Rate type'
          render={record => {
            const { authorization_type, sub_level } = record;
            if (!authorization_type) {
              return null;
            }
            return sub_level
              ? `${authorization_type.type} - ${sub_level}`
              : authorization_type.type;
          }}
        />
        <FunctionField
          source='case_name'
          label='Case name'
          sortBy='last_name'
          render={record => {
            const { case_name, case_id } = record;
            if (!case_name || !case_id) {
              return null;
            }
            return (
              <Button
                className={classes.referenceBtn}
                color='primary'
                onClick={e => e.stopPropagation()}
                component={Link}
                to={`/cases/${case_id}/timeline`}
              >
                {case_name}
              </Button>
            );
          }}
        />
        <FunctionField
          label='Status'
          render={record => {
            const { is_retro } = record;
            return (
              <ReferenceField
                source='authorization_status_id'
                reference='auths-status/list'
                link={false}
              >
                <FunctionField
                  source='name'
                  render={record => {
                    return (
                      <div style={{ width: 180 }}>
                        <ChipField
                          record={record}
                          source='name'
                          className={clsx(
                            classes.chip,
                            classes[getChip(record.name)],
                          )}
                        />
                        {is_retro ? (
                          <Chip
                            size='small'
                            label='Retro Auth'
                            className={clsx(
                              classes.chip,
                              classes[getChip('Retro')],
                            )}
                          />
                        ) : null}
                      </div>
                    );
                  }}
                />
              </ReferenceField>
            );
          }}
        />
        <DateField source='start_date' label='Start date' />
        <FunctionField
          source='end_date'
          label='End date'
          render={record => (
            <DateField
              record={record}
              source={record.actual_end_date ? 'actual_end_date' : 'end_date'}
              label='End date'
            />
          )}
        />
        <FunctionField
          source='rate'
          label='Average rate'
          render={record => currencyFormat(record.rate)}
        />
        <TextField source='payer_name' label='Payer' />
        <FunctionField
          label='Payer priority'
          render={record => {
            return record && record.is_primary !== null
              ? record.is_primary
                ? 'Primary'
                : 'Secondary'
              : null;
          }}
        />
        <FunctionField
          label='Attachments'
          render={record => {
            const { document_ids } = record;
            return Array.isArray(document_ids) && document_ids.length ? (
              <CustomButton
                Icon={AttachmentIcon}
                className={classes.attchListIcon}
                variant='text'
                badgeContent={document_ids.length}
                size='small'
                notRed
                fullSize
              />
            ) : null;
          }}
        />
      </Datagrid>
    </div>
  );
};

export const AuthsList = ({ staticContext, ..._props }) => {
  const refresh = useRefresh();
  const userDivision = useGetUserDivision();
  const { caseId, ...props } = getResourceProps(_props);
  const { location: { search } = {} } = props;
  const facilityLevel = props.resource === 'authorizations';
  const classes = useStyles();
  const [division, setDivision] = useState(userDivision);
  const [activeStatusFilters, setActiveStatusFilters] = useState();
  const [asideOpen, toggleAside] = useState(false);
  const [rowRecord, setRowRecord] = useState({});
  const [caseManager, setCaseManager] = useState();
  const [showAll, setShowAll] = useState(true);
  const firstRender = useRef(true);
  const residentCaseCount = useSelector(state =>
    get(state, 'ui.residentCaseCount', 0),
  );
  const { filters: filtersQuery, 'user-id': userId } = qs.parse(search);
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  useEffect(() => {
    toggleAside(false);
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    refresh();
  }, [props.location.pathname, refresh]);

  useEffect(() => {
    if (Array.isArray(filtersQuery)) {
      filtersQuery.forEach(filterId => {
        const filterObj = filters.filter(f => f.id === filterId);
        setActiveStatusFilters(curFilters => ({ ...curFilters, ...filterObj }));
      });
    } else {
      setActiveStatusFilters({ '1': 'Approved' });
    }
    if (userId) {
      setCaseManager(userId);
    }
  }, [filtersQuery, userId]);

  const rowClick = (id, basePath, record) => {
    setRowRecord({
      ...record,
    });
    toggleAside(true);
    return null;
  };

  const listRowStyle = (record, index, activeId) => {
    if (record && record.id === activeId && asideOpen) {
      return { backgroundColor: '#EFF4FB' };
    } else if (caseId && parseInt(record?.case_id) !== parseInt(caseId)) {
      return { backgroundColor: '#EFF4FB', opacity: 0.5, fontStyle: 'italic' };
    }
  };

  const filterActions = useCallback(({ actionType, id, name }) => {
    if (actionType === 'removeFilter') {
      setActiveStatusFilters(currentFilters => {
        const { [id]: filterToRemove, ...otherFilters } = currentFilters;
        return otherFilters;
      });
    } else if (actionType === 'addFilter') {
      setActiveStatusFilters(currentFilters => ({
        ...currentFilters,
        [id]: name,
      }));
    }
  }, []);

  const filterManager = useCallback(value => {
    toggleAside(false);
    setCaseManager(value);
  }, []);

  const toggleShowAll = useCallback(value => {
    toggleAside(false);
    setShowAll(value);
  }, []);

  if (!activeStatusFilters) return null;
  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={false}
        bulkActionButtons={
          !isViewAdmin ? (
            <BulkActionButtons
              toggleAside={toggleAside}
              recordOpenId={rowRecord.id}
              facilityLevel={facilityLevel}
            />
          ) : (
            false
          )
        }
        className={clsx(classes.list, {
          [classes.listWithDrawer]: asideOpen,
        })}
        actions={
          facilityLevel ? (
            <FacilityLevelActions
              setDivision={setDivision}
              filterActions={filterActions}
              activeStatusFilters={activeStatusFilters}
              filterManager={filterManager}
              isViewAdmin={isViewAdmin}
            />
          ) : (
            <CaseLevelActions
              residentCaseCount={residentCaseCount}
              showAll={showAll}
              setShowAll={toggleShowAll}
              isViewAdmin={isViewAdmin}
            />
          )
        }
        filter={{
          division: facilityLevel ? division : undefined,
          filtered: Object.keys(activeStatusFilters),
          caseManagerId: caseManager,
          all: showAll,
        }}
        sort={{
          field: caseId ? 'default_case' : 'start_date',
          order: 'DESC',
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <ListView
          empty={false}
          rowClick={rowClick}
          listRowStyle={listRowStyle}
          rowRecord={rowRecord}
          division={division}
        />
      </List>
      <Drawer
        variant='persistent'
        open={asideOpen}
        anchor='right'
        elevation={16}
        PaperProps={{ square: true }}
        classes={{
          paperAnchorDockedRight: clsx(classes.aside),
        }}
      >
        {asideOpen
          ? cloneElement(<AuthShow />, {
              ...props,
              id: rowRecord.id,
              closeAside: () => toggleAside(false),
              resource: 'authorizations',
              classes: classes,
              residentId: rowRecord.v_case.resident_id,
              isViewAdmin: isViewAdmin,
            })
          : null}
      </Drawer>
    </Fragment>
  );
};

export const AuthShow = ({
  closeAside,
  classes,
  residentId,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const maxNotesLength = 160;
  const showProps = useShowController({ ...props });
  const { record } = showProps;
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <AuthsShowActions
            closeAside={closeAside}
            title='Auth'
            resourceRecord={record}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <TextField
            source='payer_name'
            label='Payer'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='insurance_auth_number'
            label='Auth number'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            label='Case manager'
            source='user.name'
            className={classes.showItem}
            textAlign='right'
          />
          <FunctionField
            source='is_mltc'
            label='Case type'
            className={classes.showItem}
            render={record => (record.is_mltc ? 'MLTC' : 'Subacute')}
          />
          <FunctionField
            source='authorization_type.type'
            label='Rate type'
            className={classes.showItem}
            render={record =>
              record.sub_level
                ? `${record.authorization_type?.type} - ${record.sub_level}`
                : record.authorization_type?.type
            }
          />
          {record?.authorization_type?.type === 'Level 1' && (
            <ReferenceField
              label='Reason for Level 1 auth'
              source='level_one_reason_id'
              reference='level-one-reasons/list'
              textAlign='right'
              className={classes.showItem}
              link={false}
            >
              <TextField
                source='name'
                textAlign='right'
                className={classes.showItem}
              />
            </ReferenceField>
          )}
          {record?.level_one_reason_id === 2 && (
            <ReferenceField
              label='Insufficient criteria details'
              source='insufficient_criteria_id'
              reference='insufficient-criteria-reasons/list'
              textAlign='right'
              className={classes.showItem}
              link={false}
            >
              <TextField
                source='name'
                textAlign='right'
                className={classes.showItem}
              />
            </ReferenceField>
          )}
          {record?.level_one_reason_id === 3 && (
            <ReferenceField
              label='Higher qualifying level'
              source='qualifies_for_level'
              reference='auth-types/list'
              textAlign='right'
              className={classes.showItem}
              link={false}
            >
              <TextField
                source='name'
                textAlign='right'
                className={classes.showItem}
              />
            </ReferenceField>
          )}
          {record?.authorization_type?.type === 'Level 1' && (
            <TextField
              source='level_one_reason'
              label='Level one reason details'
              textAlign='right'
              className={classes.showItem}
            />
          )}

          {record?.authorization_type?.type === 'Level 1' && (
            <FunctionField
              source='higher_level_requested'
              label='Higher level requested'
              textAlign='right'
              className={classes.showItem}
              render={record => {
                const updatedRecord = {
                  ...record,
                  higher_level_requested: !!record.higher_level_requested,
                };
                return (
                  <BooleanField
                    record={updatedRecord}
                    source='higher_level_requested'
                    style={{
                      color: updatedRecord.higher_level_requested
                        ? '#44C87A'
                        : '#5C738E',
                    }}
                  />
                );
              }}
            />
          )}

          {record?.authorization_type?.type === 'Level 1' &&
            record?.higher_level_requested === false && (
              <TextField
                source='no_request_reason'
                label='Reason higher level was not requested'
                textAlign='right'
                className={classes.showItem}
              />
            )}
          <TextField
            source='rev_code'
            label='Rev code'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='start_date'
            label='Start date'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='is_mltc'
            label='Days approved'
            className={classes.showItem}
            render={record => {
              const { start_date, end_date } = record;
              return (
                start_date &&
                end_date &&
                differenceInCalendarDays(
                  new Date(end_date),
                  new Date(start_date),
                ) + 1
              );
            }}
          />
          <DateField
            label='Initial end date'
            source='end_date'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            label='Actual end date'
            source='actual_end_date'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='is_mltc'
            label='Days available'
            className={classes.showItem}
            render={record => {
              const { end_date } = record;
              const now = new Date();
              return end_date && isBefore(now, new Date(end_date))
                ? differenceInCalendarDays(new Date(end_date), now)
                : 0;
            }}
          />
          <FunctionField
            source='rate'
            className={classes.showItem}
            label='Average rate'
            textAlign='right'
            render={record => currencyFormat(record.rate)}
          />
          <DateField
            source='letter_received'
            className={classes.showItem}
            label='Received date'
            textAlign='right'
          />
          <ReferenceField
            label='Letter status'
            source='auth_letter_status_id'
            reference='auth-letter-status/list'
            textAlign='right'
            className={classes.showItem}
            link={false}
          >
            <TextField
              source='name'
              textAlign='right'
              className={classes.showItem}
            />
          </ReferenceField>
          {record?.approval_chance !== null && (
            <FunctionField
              source='approval_chance'
              className={classes.showItem}
              label='Approval likelihood'
              textAlign='right'
              render={record => `${record.approval_chance}%`}
            />
          )}
          {record?.pending_reason !== null && (
            <TextField
              source='pending_reason'
              label='Pending reason'
              textAlign='right'
              className={classes.showItem}
            />
          )}
          {/* needed for spacing */}
          <div className={classes.showItem} />
          <FunctionField
            source='auth_notes'
            label='Notes'
            textAlign='right'
            className={clsx(classes.showItem, classes.showFullWidth)}
            render={record => {
              const { auth_notes } = record;
              const truncated = limitStringSize(
                auth_notes,
                maxNotesLength,
                true,
              );
              return auth_notes && auth_notes.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setShowNotesModal(true)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          <div className={classes.showSectionTitle}>Updates</div>
          <FunctionField
            source='initial_update_date'
            className={classes.showItem}
            label='Schedule type'
            render={record => getScheduleType(record)}
          />
          <DateField
            source='initial_update_date'
            className={classes.showItem}
            label='Initial update'
            textAlign='right'
          />
          <FunctionField
            source='update_weekday'
            label='Days of week'
            className={classes.showItem}
            render={record => {
              const { update_weekday } = record;
              return (
                Array.isArray(update_weekday) &&
                update_weekday.map(u => weekDaysObj[u + '']).join(', ')
              );
            }}
          />
          <TextField
            source='update_frequency'
            className={classes.showItem}
            label='Frequency'
            textAlign='right'
          />
          <FunctionField
            source='inactive_schedule'
            label='Disable sched'
            textAlign='right'
            className={classes.showItem}
            render={record => {
              const updatedRecord = {
                ...record,
                inactive_schedule: !!record.inactive_schedule,
              };
              return (
                <BooleanField
                  record={updatedRecord}
                  source='inactive_schedule'
                  className={classes.showItem}
                  style={{
                    color: updatedRecord.inactive_schedule
                      ? '#44C87A'
                      : '#5C738E',
                  }}
                />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {/* <div className={classes.showItem} /> */}
          <FunctionField
            source='update_type'
            label='Update type'
            className={clsx(classes.showItem, classes.showFullWidth)}
            render={record => {
              const { update_type } = record;
              return Array.isArray(update_type) && update_type.join(', ');
            }}
          />
          <TextField
            source='update_notes'
            className={classes.showItem}
            label='Update notes'
            textAlign='right'
          />
          <FunctionField
            source='authorizationCreatedBy.name'
            label='Created by'
            className={classes.showItem}
            render={record => {
              const { authorizationCreatedBy, created_at } = record;
              return (
                <TrackUser
                  userField={authorizationCreatedBy}
                  timestamp={created_at}
                />
              );
            }}
          />
          <FunctionField
            source='authorizationUpdatedBy.name'
            label='Last modified'
            className={classes.showItem}
            render={record => {
              const { authorizationUpdatedBy, updated_at } = record;
              return (
                <TrackUser
                  userField={authorizationUpdatedBy}
                  timestamp={updated_at}
                />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {showNotesModal && (
            <NotesModal
              open={showNotesModal}
              handleClose={() => setShowNotesModal(false)}
              note={record.auth_notes}
            />
          )}
        </SimpleShowLayout>
      </ShowView>
      <Divider className={classes.residentDivider} />
      <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
        Resident info
      </p>
      <Show
        actions={
          <ResidentShowActions
            closeAside={closeAside}
            isResidentPage={false}
            isViewAdmin={isViewAdmin}
          />
        }
        {...rest}
        resource='residents'
        id={residentId}
      >
        <ResidentShowFields classes={classes} includeCases />
      </Show>
    </div>
  );
};

export const authsResource = {
  name: 'authorizations',
  list: AuthsList,
  icon: AuthIcon,
  // show: CaseTimelineShow,
};

function getResourceProps(props) {
  if (props.basePath === '/authorizations') return props;
  const {
    location: { pathname } = {},
    match: { params: { id: caseId } = {} } = {},
  } = props;
  const basePath = pathname || '/cases';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-authorizations',
        hasList: true,
        hasEdit: !!authsResource.edit,
        hasShow: !!authsResource.show,
        hasCreate: !!authsResource.create,
      };
  return { ...props, basePath, caseId, ...resource };
}

function getScheduleType({
  initial_update_date,
  update_frequency_days,
  update_frequency,
  update_weekday,
}) {
  if (Array.isArray(update_weekday) && update_weekday.length) {
    return 'Recurring day';
  } else if (update_frequency_days || update_frequency) {
    return 'Recurring date';
  } else if (initial_update_date) {
    return 'One time';
  } else {
    return '';
  }
}
const UpdateLevelOneAuths = ({ caseId, open, handleClose, ...props }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({ auth_ids: [] });
  const dispatch = useDispatch();
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    setFormData({ ...formData, [name]: value });
  };
  const onSubmit = value => {
    return dataProvider
      .update('level-one-auths', {
        id: caseId,
        data: {
          ...value,
          case_id: caseId,
          level_one_reason: value.level_one_reason || null,
        },
      })
      .then(({ data }) => {
        notify('form.updated');
        dispatch(uiActions.getFaxInboxCount());
        handleClose();
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
    >
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <span className={classes.title}> Update Level 1 Details</span>
                <div
                  className={clsx(classes.inputContainerWrap)}
                  style={{ paddingTop: 10 }}
                >
                  <ReferenceInput
                    fullWidth
                    reference='case_events/list'
                    customOnChange={customOnChange}
                    name='auth_ids'
                    label='Authorizations'
                    options={{
                      filter: {
                        case_id: caseId,
                        event_type: 'Authorization',
                        level_one: true,
                      },
                    }}
                    multiple
                  ></ReferenceInput>
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    reference='level-one-reasons/list'
                    customOnChange={customOnChange}
                    name='level_one_reason_id'
                    label='Reason for Level 1 auth'
                    required
                    validate={required()}
                    fullWidth
                    style={{ marginRight: 0 }}
                  />
                  {formData.level_one_reason_id === 2 && (
                    <ReferenceInput
                      key={formData.level_one_reason_id}
                      reference='insufficient-criteria-reasons/list'
                      customOnChange={customOnChange}
                      name='insufficient_criteria_id'
                      label='Insufficient criteria details'
                      required
                      validate={required()}
                      fullWidth
                    />
                  )}
                  {formData.level_one_reason_id === 3 && (
                    <ReferenceInput
                      key={formData.level_one_reason_id}
                      reference='auth-types/list'
                      customOnChange={customOnChange}
                      name='qualifies_for_level'
                      label='Qualifying level'
                      required
                      validate={required()}
                      fullWidth
                      options={{
                        filter: {
                          levels: true,
                        },
                      }}
                    />
                  )}
                  <FormTextField
                    name='level_one_reason'
                    label='Reason details'
                    customOnChange={customOnChange}
                    required={formData.level_one_reason_id === 4}
                    validate={formData.level_one_reason_id === 4 && required()}
                    fullWidth
                    multiline={formData.level_one_reason_id === 4}
                    style={{ marginRight: 0 }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

const UpdateLevelsButton = ({ selectedIds, ...props }) => {
  const { data } = useListContext();
  const records = data
    ? Object.values(data)?.filter(row => selectedIds.includes(row.id))
    : null;
  const [open, setOpen] = useState(false);
  const [casePayer, setCasePayer] = useState();
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const unselectAll = useUnselectAll('case-authorizations');
  const onSuccess = () => {
    refresh();
    notify('Auths updated');
    unselectAll();
    setOpen(false);
    refresh();
  };
  const validateBulkAuths = async () => {
    const response = await authGet([
      `/bulk-auths-update`,
      {
        auth_ids: selectedIds,
      },
    ]);
    const { data, error } = response;
    if (error) {
      notify(error.message, 'warning');
      unselectAll();
    } else {
      setCasePayer(data.casePayer);
      setOpen(true);
    }
  };

  const submit = value => {
    dataProvider
      .updateMany('bulk-auths-update', {
        ids: selectedIds,
        data: {
          authorization_type_id: level,
        },
      })
      .then(() => typeof onSuccess === 'function' && onSuccess())
      .catch(error => {
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        );
        unselectAll();
      });
    unselectAll();
  };

  const [level, setLevel] = useState();
  const [levelName, setLevelName] = useState();
  const handleClick = () => {
    validateBulkAuths();
  };
  const handleDialogClose = () => {
    setLevel();
    setLevelName();
    setOpen(false);
    unselectAll();
  };
  const setLevelInfo = e => {
    setLevel(e.target.value);
    setLevelName(e.currentTarget.childNodes[0].nodeValue);
  };
  return (
    <Fragment>
      <CustomButton
        Icon={LibraryAddCheckIcon}
        style={{ cursor: 'pointer' }}
        variant='text'
        onClick={handleClick}
        label='Update Auth Levels'
      />
      <ConfirmedActionModal
        maxWidth='sm'
        disabled={!level}
        text={
          <div>
            <Form
              onSubmit={submit}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <ReferenceInput
                      reference='auth-types/list'
                      name='level'
                      label='Update to level'
                      required
                      fullWidth
                      customOnChange={e => setLevelInfo(e)}
                      options={{ filter: { cases_payers_id: casePayer } }}
                    />
                  </form>
                );
              }}
            />
            {level && (
              <div>
                <DialogContentText color={'error'} variant='subtitle2'>
                  Are you sure you want to update the following auths to{' '}
                  {levelName}?
                </DialogContentText>
                <div style={{ paddingTop: 10 }}>
                  <Grid container direction='row'>
                    <Grid item xs={4}>
                      <DialogContentText>Rate type </DialogContentText>
                    </Grid>
                    <Grid item xs={4}>
                      <DialogContentText>Auth number </DialogContentText>
                    </Grid>
                    <Grid item xs={4}>
                      <DialogContentText>Date range </DialogContentText>
                    </Grid>
                    {records?.map(row => (
                      <Grid container direction='row'>
                        <Grid item xs={4}>
                          {`${row.authorization_type?.type}`}
                        </Grid>
                        <Grid item xs={4}>
                          {`${row.insurance_auth_number}`}
                        </Grid>
                        <Grid item xs={4}>
                          {`${format(
                            new Date(`${row.start_date} 00:00`),
                            'M/d/yyyy',
                          )} 
                      ${
                        row.actual_end_date || row.end_date
                          ? `-  ${format(
                              new Date(
                                `${row.actual_end_date || row.end_date} 00:00`,
                              ),
                              'M/d/yyyy',
                            )}`
                          : ''
                      }`}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
            )}
          </div>
        }
        confirmButtonText='Update levels'
        onClose={() => handleDialogClose()}
        onConfirm={() => submit(records)}
        open={open}
      />
    </Fragment>
  );
};
