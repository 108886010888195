import { makeStyles } from '@material-ui/core';
import { styles } from '../design';

export const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    maxWidth: 920,
    margin: '0 auto',
  },
  inputCell: {
    flex: '1 0 235px',
    marginRight: 25,
    marginBottom: 26,
  },
  inputCellFullWidth: {
    marginBottom: 26,
  },
  negativeMargin: {
    marginTop: -26,
  },
  titleContainer: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    marginBottom: 26,
  },
  title: {
    fontFamily: theme.palette.text.fontFamily,
    fontSize: 20,
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.87)',
  },
  modalTitle: { padding: '18 24px' },
  saveButton: {
    backgroundColor: '#829CB5',
    '&:hover': {
      backgroundColor: '#829CB5',
    },
  },
  header: {
    paddingTop: 40,
    marginBottom: 0,
    fontSize: 18,
    fontWeight: 600,
    color: 'rgb(0,0,0)',
  },
  sectionHeader: {
    fontSize: 20,
    fontWeight: 500,
    marginTop: 15,
    marginBottom: 25,
  },
  sectionHeaderMargin50: {
    marginBottom: 50,
  },
  inputContainerWrap: {
    ...styles.flexRow,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    '& div:nth-child(3n)': {
      marginRight: 0,
    },
  },
  saveButtonContainer: {
    paddingTop: 16,
  },
  dropZone: {
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
    textAlign: 'center',
    borderStyle: 'dashed',
    border: 0.05,
    borderColor: '#9FB4CB',
    padding: 20,
  },
}));
