import { makeStyles } from '@material-ui/core';
import { styles } from '../design';
import { style } from '../resources/residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  addContainer: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    width: 280,
  },
  noLineBreak: {
    whiteSpace: 'nowrap',
  },
  showMore: {
    ...styles.cursorPointer,
    fontSize: '0.875rem',
  },
  title: {
    padding: 0,
    fontFamily: theme.palette.text.fontFamily,
    fontSize: 20,
    fontWeight: 'bold',
  },
  notesCell: {
    maxWidth: '15vw',
  },
}));
