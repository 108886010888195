import { makeStyles } from '@material-ui/core';
// import { styles } from '../../../design';

export const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 10,
  },
  eventTitle: {
    fontSize: 24,
    fontWeight: 'normal',
    padding: 0,
    display: 'inline',
  },
  fileIcons: {
    color: '#E32939',
  },
  viewIcon: {
    color: theme.palette.secondary.main,
  },
  tableRow: {
    height: 20,
    minHeight: 20,
  },
  emailLogBtn: {
    marginRight: 10,
    marginLeft: 10,
    textTransform: 'capitalize',
    fontSize: '1rem',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.text,
  },
}));
