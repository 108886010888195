/* eslint-disable import/no-anonymous-default-export */
import { stringify } from 'query-string';
import { fetchUtils } from 'react-admin';
import { reduxStore } from '../createAdminStore';
import { addUploadFeature } from './file';

export default (_apiUrl, httpClient = fetchUtils.fetchJson) => {
  return {
    getList: (resource, params) => {
      const facilityId = getFacilityId();
      const coveredUserId = getCoveredUserId();
      const facilityQuery = {
        _facilityId: facilityId,
        coveredUserId: coveredUserId,
      };
      const apiUrl = setUrl(_apiUrl, resource);
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...fetchUtils.flattenObject(params.filter),
        _sort: field,
        _order: order,
        _start: (page - 1) * perPage,
        _end: page * perPage,
        ...facilityQuery,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: parseInt(
          headers
            .get('x-total-count')
            .split('/')
            .pop(),
          10,
        ),
      }));
    },

    getOne: (resource, params) => {
      const facilityId = getFacilityId();
      const coveredUserId = getCoveredUserId();
      const facilityQuery = {
        _facilityId: facilityId,
        coveredUserId: coveredUserId,
      };
      const apiUrl = setUrl(_apiUrl, resource);
      const url = `${apiUrl}/${resource}/${params.id}?${stringify(
        facilityQuery,
      )}`;
      return httpClient(url).then(({ json }) => ({
        data: json,
      }));
    },

    getMany: (resource, params) => {
      const facilityId = getFacilityId();
      const coveredUserId = getCoveredUserId();
      const facilityQuery = {
        _facilityId: facilityId,
        coveredUserId: coveredUserId,
      };
      const apiUrl = setUrl(_apiUrl, resource);
      const query = {
        [`id_like`]: params.ids.join('|'),
        ...facilityQuery,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getBulk: (resource, params) => {
      const apiUrl = setUrl(_apiUrl, resource);
      const query = {
        ...params,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
      const facilityId = getFacilityId();
      const coveredUserId = getCoveredUserId();
      const facilityQuery = {
        _facilityId: facilityId,
        coveredUserId: coveredUserId,
      };
      const apiUrl = setUrl(_apiUrl, resource);
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...fetchUtils.flattenObject(params.filter),
        [params.target]: params.id,
        _sort: field,
        _order: order,
        _start: (page - 1) * perPage,
        _end: page * perPage,
        ...facilityQuery,
      };

      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: parseInt(
          headers
            .get('x-total-count')
            .split('/')
            .pop(),
          10,
        ),
      }));
    },

    update: async (resource, _params) => {
      const params = await addUploadFeature(_params);
      const facilityId = getFacilityId();
      const coveredUserId = getCoveredUserId();
      const facilityQuery = {
        _facilityId: facilityId,
        coveredUserId: coveredUserId,
      };
      const apiUrl = setUrl(_apiUrl, resource);
      const url = `${apiUrl}/${resource}/${params.id}?${stringify(
        facilityQuery,
      )}`;
      return httpClient(url, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json }));
    },

    updateMany: (resource, params) => {
      const facilityId = getFacilityId();
      const coveredUserId = getCoveredUserId();
      const facilityQuery = {
        _facilityId: facilityId,
        coveredUserId: coveredUserId,
      };
      const apiUrl = setUrl(_apiUrl, resource);
      return Promise.all(
        params.ids.map(id =>
          httpClient(
            `${apiUrl}/${resource}/${id}?${stringify(facilityQuery)}`,
            {
              method: 'PUT',
              body: JSON.stringify(params.data),
            },
          ),
        ),
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    },

    create: async (resource, _params) => {
      const params = await addUploadFeature(_params);
      const facilityId = getFacilityId();
      const coveredUserId = getCoveredUserId();
      const facilityQuery = {
        _facilityId: facilityId,
        coveredUserId: coveredUserId,
      };

      const apiUrl = setUrl(_apiUrl, resource);
      const url = `${apiUrl}/${resource}?${stringify(facilityQuery)}`;
      return httpClient(url, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...json.data },
      }));
    },

    delete: (resource, params) => {
      const facilityId = getFacilityId();
      const coveredUserId = getCoveredUserId();
      const facilityQuery = {
        _facilityId: facilityId,
        coveredUserId: coveredUserId,
      };

      const apiUrl = setUrl(_apiUrl, resource);
      const url = `${apiUrl}/${resource}/${params.id}?${stringify(
        facilityQuery,
      )}`;
      return httpClient(url, {
        method: 'DELETE',
      }).then(({ json }) => ({ data: json }));
    },

    deleteMany: (resource, params) => {
      const facilityId = getFacilityId();
      const coveredUserId = getCoveredUserId();
      const { ids } = params;
      const facilityQuery = {
        _facilityId: facilityId,
        coveredUserId: coveredUserId,
      };
      const apiUrl = setUrl(_apiUrl, resource);
      return httpClient(`${apiUrl}/${resource}?${stringify(facilityQuery)}`, {
        method: 'DELETE',
        body: JSON.stringify({ ids }),
      }).then(({ json }) => ({ data: json }));
    },
  };
};
/**
 * Get the Facility Id
 * @returns {number}
 */
export function getFacilityId() {
  const facilityIdLocalStorage = localStorage.getItem('facility_id');
  const store = reduxStore();
  const {
    facility: { id },
  } = store.getState();
  return id || facilityIdLocalStorage || 0;
}
/**
 * Get the Covered User Id
 * @returns {number}
 */
export function getCoveredUserId() {
  const coveredUserIdLocalStorage = localStorage.getItem('covered_user_id');
  const store = reduxStore();
  const {
    facility: { covered_user_id },
  } = store.getState();
  return covered_user_id || coveredUserIdLocalStorage || 0;
}

/**
 * A list of resources to add /case to url
 */
const caseResources = [
  'case-timeline',
  'case-authorizations',
  'case-ancillary-authorizations',
  'case-denials',
  'case-payers',
  'case-documents',
  'case-emails',
  'case-updates',
  'updates-schedules',
  'case-progress',
  'case-therapy',
  'case-communications',
  'case-eligibility',
  'case-pharmacy',
  'case-tasks',
  'clinical/manual-diagnoses',
  'clinical/diagnoses',
  'clinical/medications',
  'clinical/pharm-log',
  'clinical/progress_notes',
  'case-history',
];

function setUrl(url, resource) {
  const pathName = window.location.hash;
  const pathArray = pathName.split('/'); // Ex. ["#", "cases", "2", "profile", "1"]
  const caseId = pathArray[2];
  // check cases/id
  if (
    pathArray[1] === 'cases' &&
    caseId &&
    !isNaN(caseId) &&
    caseResources.includes(resource)
  ) {
    return `${url}/cases/${caseId}`;
  }
  return url;
}
