import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  chipsContainer: {
    flexBasis: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -50,
  },
  chips: {
    margin: 3,
    fontWeight: 500,
    backgroundColor: theme.palette.secondary.text,
    color: 'primary',
  },
  filterDropdown: {
    textTransform: 'capitalize',
    color: '#61728C',
    fontSize: 16,
    marginTop: 2,
    marginRight: 2,
    fontFamily: 'Nunito Sans',
  },
}));
