import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  useLogin,
  useRedirect,
  useNotify,
  Notification,
  useAuthenticated,
} from 'react-admin';
import { authPost } from '../server';
import {
  Typography,
  InputAdornment,
  IconButton,
  Button,
  CssBaseline,
  TextField,
} from '@material-ui/core';
import { useStyles } from './login.styles';
import { Visibility, VisibilityOff } from '../design';
import { Logo } from '../components/common/Logo';

export const SetPassword = ({ match = {} }) => {
  useAuthenticated({ page: 'forgot-password' });
  const { params: { token } = {} } = match;
  const login = useLogin();
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
  });
  useEffect(() => {
    if (!token) {
      notify('Missing token', 'warning');
      redirect('/login');
    } else {
      setState(s => ({ ...s, token }));
    }
  }, [token, notify, redirect]);
  const handleChange = e => {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  };
  const submit = async e => {
    e.preventDefault();
    const { password, confirmPassword, token } = state;
    const response = await authPost('/set-password', {
      password,
      confirm_password: confirmPassword,
      token,
    });
    const { error } = response;
    if (error) {
      notify(
        typeof error === 'string'
          ? error
          : error.message || 'ra.notification.http_error',
        'warning',
      );
      return;
    }
    login({ password: state.password, email: response.data.email }).catch(() =>
      notify('Invalid email or password'),
    );
  };
  const { password, confirmPassword } = state;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  return (
    <main className={classes.main}>
      <CssBaseline />
      <div className={classes.paper}>
        <Logo classes={classes} />
        <Typography
          className={clsx(classes.welcome, classes.welcomeForgotPassword)}
        >
          Change password
        </Typography>
        <Typography className={clsx(classes.signIn, classes.instructionsMsg)}>
          Enter your new password here
        </Typography>

        <form className={classes.form} noValidate onSubmit={submit}>
          <div className={classes.inputWrapper}>
            <TextField
              margin='dense'
              required
              variant='outlined'
              fullWidth
              name='password'
              label='New password'
              value={password}
              onChange={handleChange}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.inputWrapper}>
            <TextField
              margin='dense'
              required
              variant='outlined'
              fullWidth
              name='confirmPassword'
              label='Repeat new password'
              value={confirmPassword}
              onChange={handleChange}
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge='end'
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.inputWrapper}>
            <Button
              type='submit'
              variant='contained'
              className={classes.submit}
              color='primary'
              disableElevation
              disabled={
                !password ||
                !confirmPassword ||
                password.length < 8 ||
                password !== confirmPassword
              }
            >
              <span className={classes.buttonText}>Save new password</span>
            </Button>
          </div>
        </form>
      </div>
      <Notification />
    </main>
  );
};
