import { makeStyles } from '@material-ui/core';
import { styles, colors } from '../design';
import { chipStyles } from '../design/globalStyles';
export const style = theme => ({
  header: {
    width: '100%',
    display: 'block',
    paddingLeft: 12,
    paddingRight: 5,
    paddingTop: 24,
    boxSizing: 'border-box',
  },
  headerRow: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    // paddingRight: 20,
  },
  title: {
    fontFamily: theme.palette.text.fontFamily,
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.black,
    contentAlign: 'left',
  },
  subtitle: {
    fontFamily: theme.palette.text.fontFamily,
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.text.secondary,
    contentAlign: 'left',
  },
  statusChipWrapper: {
    width: 185,
  },
  chip: {
    borderRadius: 2,
    paddingTop: 15,
    paddingBottom: 15,
    textTransform: 'capitalize',
    height: 22,
    fontWeight: 300,
  },
  smallStatusChip: {
    '& span': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  activeChip: {
    ...styles.approved,
  },
  showLayout: {
    ...styles.flexRow,
    flexWrap: 'wrap',
    marginTop: -20,
    boxShadow: 'none',
    header: { fontWeight: 'bold', color: 'black' },
  },
  showItem: {
    color: 'black',
    flex: '1 0 110px',
    fontSize: 11,
    marginTop: 20,
    height: 55,
  },
  showFullWidth: {
    minWidth: '100%',
    marginBottom: 10,
  },
  casesTitle: {
    width: '100%',
    color: colors.black,
    fontSize: 18,
    fontWeight: 600,
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    marginBottom: 0,
  },
  caseListTable: { width: '100%' },
  aside: {
    zIndex: 0,
    maxWidth: 395,
    width: 395,
    paddingTop: 50,
    // background: 'inherit',
    // border: 'none',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    // marginRight: 400,
    maxWidth: 'calc(100% - 400px)',
    // overflowX: 'auto',
  },
  listContainer: {
    maxWidth: props =>
      props.isSidebarOpen ? 'calc(100vw - 315px)' : 'calc(100vw - 115px)',
    overflowX: 'auto',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  editButton: {
    color: '#B6B6B6',
  },
  listActionWrapper: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    width: '100%',
  },
  tabsActionWrapper: {
    paddingBottom: 0,
  },
  tabContainer: {
    minWidth: '100%',
    marginTop: 20,
  },
  addContainer: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    width: 200,
  },
  referenceBtn: {
    textTransform: 'capitalize',
    fontSize: '0.875rem',
  },
  exportButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.text,
    textTransform: 'capitalize',
    marginLeft: 10,
  },
  showMore: {
    ...styles.cursorPointer,
    fontSize: 12,
  },
  showSectionTitle: {
    width: '90%',
    display: 'block',
    marginTop: 7,
    whiteSpace: 'nowrap',
  },
  tab: {
    textTransform: 'capitalize',
    fontSize: 15,
    color: theme.palette.text.secondary,
  },
  selectedTab: {
    fontSize: 15,
    color: theme.palette.primary.buttons,
    textTransform: 'capitalize',
  },
  info: {
    height: 15,
    width: 15,
    fontWeight: 600,
  },
  showBtn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.text,
    fontSize: 14,
    borderRadius: 2,
    '&:hover': {
      background: '#E6EBF0',
    },
    pagination: {
      display: 'flex',
    },
  },
  notchedOutline: {
    border: 'none',
  },
  divider: { color: theme.palette.primary.main, height: 2 },
  checkbox: {
    color: theme.palette.primary.main,
  },
  label: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  // showEditBtn: {
  //   back
  // }
  ...chipStyles,
});
export const useStyles = makeStyles(theme => ({
  ...style(theme),
}));
