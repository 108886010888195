import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import {
  useAuthenticated,
  useNotify,
  Notification,
  useRedirect,
} from 'react-admin';
import {
  TextField,
  Button,
  CssBaseline,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { authGet, authPost } from '../server';
import { loginUser } from '../server/authProvider';
import { useStyles } from './login.styles';
import { RadioInput } from '../components/common/modals/modalForms';
import { Logo } from '../components/common/Logo';

export const MfaLogin = () => {
  useAuthenticated({ page: 'mfa-login' });
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();
  const [mfaCode, setMfaCode] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [sendingMethod, setSendingMethod] = useState('email');
  const [codeSent, setCodeSent] = useState(false);
  //const cellPhone = localStorage.getItem('cellPhone');
  const handleChange = e => {
    const {
      target: { value },
    } = e;
    setMfaCode(value);
  };

  const sendCode = async e => {
    e.preventDefault();
    const response = await authGet([
      'auth/send-mfa-code',
      {
        mfa_type: sendingMethod,
      },
    ]);
    const { error } = response;
    if (error) {
      notify(
        typeof error === 'string'
          ? error
          : error.message || 'ra.notification.http_error',
        'warning',
      );
      return;
    } else {
      setCodeSent(true);
    }
  };
  const submit = async e => {
    e.preventDefault();
    const response = await authPost('auth/multifactor-auth', {
      mfaCode,
      mfa_remember: rememberMe,
    });
    const { data, error } = response;
    if (error) {
      notify(
        typeof error === 'string'
          ? error
          : error.message || 'ra.notification.http_error',
        'warning',
      );
      return;
    } else {
      const { token } = data;
      await loginUser({ isMfa: true, mfaToken: token });
      redirect('/');
    }
  };
  const resendCode = async () => {
    notify('Resending...');
    const response = await authGet([
      'auth/send-mfa-code',
      {
        mfa_type: sendingMethod,
      },
    ]);
    const { error } = response;
    if (error) {
      notify(
        typeof error === 'string'
          ? error
          : error.message || 'ra.notification.http_error',
        'warning',
      );
      return;
    }
    notify('Code sent');
  };
  return (
    <main className={classes.main}>
      <CssBaseline />
      <div className={classes.paper}>
        <Logo classes={classes} />
        <Fragment>
          <Typography
            className={clsx(classes.welcome, classes.welcomeForgotPassword)}
          >
            Welcome
          </Typography>

          {!codeSent ? (
            <Fragment>
              <Typography
                className={clsx(classes.signIn, classes.instructionsMsg)}
              >
                Sign in with MFA
              </Typography>
              <Form
                onSubmit={sendCode}
                render={({ submitError, handleSubmit }) => {
                  return (
                    <form noValidate onSubmit={sendCode}>
                      <div
                        style={{
                          paddingBottom: 30,
                        }}
                      >
                        <RadioInput
                          customOnChange={e => setSendingMethod(e.target.value)}
                          className={clsx(
                            classes.signIn,
                            classes.instructionsMsg,
                          )}
                          name='sendingMethod'
                          defaultValue='email'
                          radios={
                            /* cellPhone !== 'undefined'  && cellPhone !== 'null'
                              ?           
                              [ {
                                    label: 'Send an email',
                                    value: 'email',
                                  },
                                  {
                                    label: `Send a text message to ${normalizePhone(
                                      cellPhone,
                                    )}`,
                                    value: 'text',
                                  },]:   */
                            [
                              {
                                label: 'Send an email',
                                value: 'email',
                              },
                            ]
                          }
                        />
                      </div>

                      <div>
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                          disabled={!sendingMethod}
                          disableElevation
                        >
                          <span className={classes.buttonText}>Send code</span>
                        </Button>
                      </div>
                    </form>
                  );
                }}
              />
            </Fragment>
          ) : (
            <Fragment>
              <Typography
                className={clsx(classes.signIn, classes.instructionsMsg)}
              >
                Please enter your MFA code
              </Typography>
              <form className={classes.form} noValidate onSubmit={submit}>
                <div style={{ marginTop: -25, paddingBottom: 15 }}>
                  <TextField
                    required
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='mfaCode'
                    label='Code'
                    name='mfaCode'
                    value={mfaCode}
                    autoFocus
                    onChange={handleChange}
                    type='number'
                  />
                  <Typography
                    className={classes.mfaMsg}
                    onClick={() => {
                      setRememberMe(!rememberMe);
                    }}
                  >
                    <Checkbox checked={rememberMe} />
                    Remember me for 7 days
                  </Typography>
                </div>
                <div className={classes.inputWrapper}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    disabled={mfaCode.length < 4}
                    disableElevation
                  >
                    <span className={classes.buttonText}>Sign in</span>
                  </Button>
                </div>
                <Typography
                  className={classes.buttonLink}
                  variant='body2'
                  onClick={() => resendCode()}
                  color='primary'
                  style={{ marginTop: -8 }}
                >
                  Resend code
                </Typography>
              </form>
            </Fragment>
          )}
        </Fragment>
      </div>
      <Notification />
    </main>
  );
};
