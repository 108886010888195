import React, { useState, useCallback, Fragment, useEffect } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { required, email } from 'react-admin';
import {
  DialogContentText,
  Divider,
  Button,
  IconButton,
} from '@material-ui/core';
import {
  ReferenceInput,
  FormTextField,
  SelectComponent,
  composeValidators,
  minLength,
  minReasonLength,
  CheckboxInput,
  RadioInput,
} from '../../modalForms';
import { AddIcon, RemoveIcon, CloseIcon } from '../../../../../design';
import { AutocompleteInput, validateDate } from '../inputs';
import { useStyles } from '../../modal.styles';
import { HIGHEST_NO_AUTH_PAYER_ID, ToggleInsuranceNetwork } from './helpers';
import { AuthFields } from './authFields';
import { constants } from '../../../../../utils';
import { SearchIcon } from '../../../../../design';
import { CheckEligibility } from '../checkEligibility';
import { authGet } from '../../../../../server';
import { useGetUserDivision } from '../../../../../hooks';
const { caseTypes } = constants;
const roles = localStorage.getItem('roles');
export const SecondaryInsuranceInputs = ({
  customOnChange,
  values,
  removeAuthFields,
  isEdit,
  lastCase,
  formData,
  setFormData,
  isInNetwork = true,
  setIsInNetwork,
  notify,
  secondarySubLevelChoices,
}) => {
  const [addAuth, setAddAuth] = useState(false);
  const userId = useSelector(state => state.user.profile.id);
  const coveredUserId = useSelector(state => state.facility.covered_user_id);
  const userDivision = useGetUserDivision();
  const openAuth = useCallback(() => {
    const fields = {
      s_update_type: [],
      s_update_weekday: [],
      s_schedule_type: '',
      s_case_type:
        formData.s_case_type ||
        (userDivision === 'all' ? 0 : userDivision === 'subacute' ? 0 : 1),
      s_user_id: formData.s_user_id || coveredUserId || userId,
    };
    if (values.is_readmission) {
      fields.s_start_date = values.admit_date;
    } else {
      fields.s_start_date = values.tracking_date;
    }
    setFormData(f => ({ ...f, ...fields }));
    setAddAuth(true);
  }, [
    setFormData,
    values.admit_date,
    values.is_readmission,
    values.tracking_date,
    formData.s_case_type,
    formData.s_user_id,
    coveredUserId,
    userId,
    userDivision,
  ]);
  useEffect(() => {
    setFormData(f => ({
      ...f,
      s_case_type:
        formData.s_case_type ||
        (userDivision === 'all' ? 0 : userDivision === 'subacute' ? 0 : 1),
      s_user_id: formData.s_user_id || coveredUserId || userId,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, userId, setFormData, addAuth]);
  const classes = useStyles();
  const showInsuranceInputs = values.s_insurance === 0;
  const showInsuranceLists = !values.is_readmission && values.s_insurance >= 0;
  const [showEligibility, setShowEligibility] = useState(false);
  const [seekPayer, setSeekPayer] = useState(false);

  useEffect(() => {
    const payerId = values.s_insurance;
    async function checkSeekPayer() {
      const response = await authGet([
        `seek-payer-codes`,
        { payer_id: payerId },
      ]);
      if (response !== 'error') {
        setSeekPayer(response.data);
      }
    }
    checkSeekPayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.s_insurance]);
  return (
    <Fragment>
      <ToggleInsuranceNetwork
        state={isInNetwork}
        setState={setIsInNetwork}
        name='isInNetworkSecondary'
        disabled={values.is_readmission}
      />
      {values.is_readmission ? (
        <ReferenceInput
          key={isInNetwork + ''}
          perPage={300}
          reference='facility-payers/list'
          customOnChange={customOnChange}
          name='s_insurance'
          label='Insurance'
          validate={required()}
          required
          fullWidth
          viewAll
          options={{ filter: { in_network: isInNetwork } }}
          disabled={values.s_insurance}
        />
      ) : (
        <AutocompleteInput
          key={isInNetwork + ''}
          perPage={300}
          reference='facility-payers/list'
          customOnChange={customOnChange}
          name='s_insurance'
          label='Insurance'
          validate={required()}
          required
          fullWidth
          viewAll
          openOnFocus
          autocompleteProps={{ openOnFocus: true }}
          options={{ filter: { in_network: isInNetwork } }}
        />
      )}
      {showInsuranceLists && (
        <Fragment>
          {showInsuranceInputs && (
            <Fragment>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  name='s_insurance_name'
                  validate={required()}
                  required
                  label='Company name'
                  customOnChange={customOnChange}
                />
                <ReferenceInput
                  key={[
                    `${values.s_insurance}_lob`,
                    values.tracking_date,
                    values.admit_date,
                    values.s_insurance,
                  ]}
                  reference='lob/list'
                  customOnChange={customOnChange}
                  name='s_line_of_business_id'
                  label='Type (LOB)'
                  validate={required()}
                  required
                  options={{
                    filter: {
                      payer_id: values.s_insurance,
                      effective_date: values.tracking_date || values.admit_date,
                      in_network: isInNetwork,
                    },
                  }}
                />
              </div>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  name='s_group_provider'
                  label='Group provider'
                  customOnChange={customOnChange}
                  style={{ marginRight: 0 }}
                />
              </div>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  name='s_remit_address'
                  label='Remit to address'
                  fullWidth
                  customOnChange={customOnChange}
                  style={{ marginRight: 0 }}
                />
              </div>
            </Fragment>
          )}
          <DialogContentText
            align='left'
            variant='h6'
            className={classes.header}
          >
            Member details
          </DialogContentText>
          <Divider className={classes.divider} />
          <div className={clsx(classes.inputContainerWrap)}>
            <FormTextField
              name='s_residents_insurance_id'
              validate={
                values.s_insurance > HIGHEST_NO_AUTH_PAYER_ID
                  ? required()
                  : undefined
              }
              required={values.s_insurance > HIGHEST_NO_AUTH_PAYER_ID}
              label='Member ID #'
              customOnChange={customOnChange}
            />
            {!showInsuranceInputs ? (
              <ReferenceInput
                key={[
                  `${values.s_insurance}_lob`,
                  values.tracking_date,
                  values.admit_date,
                  values.s_insurance,
                ]}
                reference='lob/list'
                customOnChange={customOnChange}
                name='s_line_of_business_id'
                label='Type (LOB)'
                validate={required()}
                required
                options={{
                  filter: {
                    payer_id: values.s_insurance,
                    effective_date: values.tracking_date || values.admit_date,
                    in_network: isInNetwork,
                  },
                }}
              />
            ) : (
              <div style={{ flex: '1 0 160px' }} />
            )}
            {!!values.days_of_coverage && (
              <Fragment>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    customOnChange={customOnChange}
                    name='s_copay_start_day'
                    label='Copay starts on day'
                    type='number'
                    style={{ marginRight: 10 }}
                  />
                  <FormTextField
                    customOnChange={customOnChange}
                    name='s_copay_start_date'
                    type='date'
                    label='Copay start date'
                    validate={composeValidators([validateDate()])}
                    style={{ marginRight: 10 }}
                  />
                  <FormTextField
                    customOnChange={customOnChange}
                    name='s_copay_end_date'
                    type='date'
                    label='Copay end date'
                    validate={composeValidators([validateDate()])}
                    style={{ marginRight: 0 }}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </Fragment>
      )}

      {seekPayer && !showEligibility && (
        <div style={{ paddingTop: 20 }}>
          <Button
            styles={{ itemAlign: 'center' }}
            onClick={() => {
              values.first_name &&
              values.last_name &&
              values.dob &&
              values.gender_id &&
              values.s_insurance &&
              values.s_residents_insurance_id
                ? setShowEligibility(true)
                : notify(
                    'Missing required information. Please include name, date of birth, gender, payer, and member ID.',
                    'warning',
                  );
            }}
          >
            <SearchIcon className={clsx(classes.leftIcon, classes.icon)} />
            Check eligibility
          </Button>
        </div>
      )}
      {showEligibility && (
        <div
          style={{
            width: '100%',
            textAlign: 'right',
          }}
        >
          <IconButton
            onClick={() => {
              setShowEligibility(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
      {showEligibility && (
        <CheckEligibility
          setFormData={setFormData}
          params={{
            first_name: values.first_name,
            last_name: values.last_name,
            dob: values.dob,
            gender: values.gender_id,
            insurance: values.s_insurance,
            residents_insurance_id: values.s_residents_insurance_id,
          }}
        />
      )}
      {showInsuranceLists && (
        <Fragment>
          {(values.s_insurance === 0 ||
            values.s_insurance > HIGHEST_NO_AUTH_PAYER_ID) && (
            <Fragment>
              <DialogContentText
                align='left'
                variant='h6'
                className={classes.header}
              >
                Insurance case manager details
              </DialogContentText>
              <Divider className={classes.divider} />
              <ReferenceInput
                key={`${values.s_insurance}_rep`}
                reference='reps/list'
                customOnChange={customOnChange}
                name='s_payer_rep'
                label='Insurance case manager name'
                fullWidth
                options={{
                  filter: { payer_id: values.s_insurance },
                }}
                resetOption={{
                  id: 0,
                  name: 'Create new insurance case manager',
                }}
              />
              <div className={clsx(classes.inputContainerWrap)}>
                {values.s_payer_rep === 0 && (
                  <FormTextField
                    customOnChange={customOnChange}
                    name='s_rep_name'
                    validate={required()}
                    required
                    label='Insurance case manager name'
                    style={{ marginRight: 0 }}
                  />
                )}
              </div>

              {values.s_payer_rep >= 0 && (
                <Fragment>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      customOnChange={customOnChange}
                      name='s_rep_phone'
                      type='text'
                      format='phone'
                      validate={composeValidators([
                        required(),
                        minLength(10, 'Invalid phone number'),
                      ])}
                      required
                      label='Insurance case manager phone'
                      disabled={!!values.s_payer_rep}
                    />
                    <FormTextField
                      customOnChange={customOnChange}
                      name='s_rep_phone_ext'
                      label='Ext.'
                      disabled={!!values.s_payer_rep}
                    />
                  </div>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      customOnChange={customOnChange}
                      name='s_rep_fax'
                      validate={composeValidators([
                        minLength(10, 'Invalid phone number'),
                      ])}
                      type='text'
                      format='phone'
                      label='Insurance case manager fax'
                      disabled={!!values.s_payer_rep}
                    />
                    <FormTextField
                      customOnChange={customOnChange}
                      name='s_rep_email'
                      validate={composeValidators([email()])}
                      label='Insurance case manager email'
                      type='email'
                      disabled={!!values.s_payer_rep}
                    />
                  </div>
                </Fragment>
              )}
              {values.s_payer_rep >= 0 && (
                <FormTextField
                  name='s_rep_comments'
                  label='Insurance case manager notes'
                  multiline
                  fullWidth
                  customOnChange={customOnChange}
                  disabled={!!values.s_payer_rep}
                  validate={minReasonLength()}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
      {(values.s_insurance === 0 ||
        values.s_insurance > HIGHEST_NO_AUTH_PAYER_ID) &&
        !addAuth && (
          <div className={clsx(classes.inputContainerWrap)}>
            <SelectComponent
              customOnChange={customOnChange}
              label='Case type'
              validate={required()}
              required
              name='s_case_type'
              choices={caseTypes}
            />
            <ReferenceInput
              reference='facility-users/list'
              customOnChange={customOnChange}
              name='s_user_id'
              label='Case manager'
              validate={required()}
              required
              options={{
                filter: {
                  create: !!!isEdit,
                  case_manager: !!roles?.includes('case_manager'),
                  all_roles: true,
                },
                sort: { field: 'last_name', order: 'ASC' },
              }}
              style={{ marginRight: 0 }}
              shouldFetchMore
            />
          </div>
        )}
      {values.s_payer_rep >= 0 &&
        values.s_user_id &&
        (values.s_rep_fax || values.s_rep_email) && (
          <CheckboxInput
            name='s_send_notification'
            label='Auto-send admission notification'
            customOnChange={customOnChange}
            checked={formData.s_send_notification}
          />
        )}
      {values.s_send_notification && values.s_rep_fax && values.s_rep_email && (
        <RadioInput
          row
          name='s_send_notification_type'
          customOnChange={customOnChange}
          value={formData.s_send_notification_type}
          label=''
          radios={[
            {
              label: 'Fax',
              value: 'fax',
            },
            {
              label: 'Email',
              value: 'email',
            },
          ]}
        />
      )}
      {(values.s_insurance === 0 ||
        values.s_insurance > HIGHEST_NO_AUTH_PAYER_ID) && (
        <div className={classes.secondaryActionContainer}>
          {!addAuth ? (
            <DialogContentText
              color='primary'
              onClick={openAuth}
              className={classes.secondaryAction}
            >
              <AddIcon /> Add auth for secondary payer
            </DialogContentText>
          ) : (
            <DialogContentText
              color='primary'
              onClick={() => {
                removeAuthFields('secondary');
                setAddAuth(false);
              }}
              className={classes.secondaryAction}
            >
              <RemoveIcon style={{ marginRight: 5 }} /> Remove auth for
              secondary payer
            </DialogContentText>
          )}
        </div>
      )}
      {addAuth &&
        Array.isArray(values.s_update_weekday) &&
        Array.isArray(values.s_update_type) && (
          <AuthFields
            customOnChange={customOnChange}
            values={values}
            setFormData={setFormData}
            type='secondary'
            lastCase={lastCase}
            subLevelChoices={secondarySubLevelChoices}
          />
        )}
    </Fragment>
  );
};
