import React, { useState, useEffect, Fragment } from 'react';
import {
  useListController,
  ListView,
  TextField,
  TopToolbar,
  // Filter,
  // SelectInput,
  FunctionField,
  // NumberField,
  // DateField,
  useRefresh,
  Pagination,
  useUnselectAll,
  useNotify,
  useDataProvider,
  cacheDataProviderProxy,
  required,
} from 'react-admin';
import { Form } from 'react-final-form';
import { Datagrid, Loading, DateField } from '../../components/common';
import { NewEvent, NotesModal } from '../../components/common/modals';
import {
  FormTextField,
  minDate,
  composeValidators,
  validateDate,
} from '../../components/common/modals/modalForms';
import { currencyFormat } from '../../utils';
import { useStyles } from './payers.styles';
import {
  AddIcon,
  EditIcon,
  CustomButton,
  CloseIcon,
  DeactivateFacilityIcon,
} from '../../design';
import {
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@material-ui/core';

const ListActions = ({ record, payer_facility_id, inactive, permissions }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span />
        {!inactive && (
          <CustomButton
            Icon={AddIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Rate'
            type='button'
            variant='text'
            size='small'
            onClick={() => setEventModal(true)}
          />
        )}
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='rates'
          meta={{
            line_of_businesses: record.line_of_businesses,
          }}
          refresh={refresh}
          payerFacilityId={parseInt(payer_facility_id)}
        />
      )}
    </Fragment>
  );
};

// const FiltersView = ({ classes, ...props }) => {
//   return (
//     <Filter {...props} className={classes.filterContainer}>
//       <SelectInput
//         label='Rate Schedule'
//         source='start_date'
//         alwaysOn
//         options={{ variant: 'standard' }}
//       />
//     </Filter>
//   );
// };

export const Rates = ({
  showContract,
  record,
  permissions = [],
  id,
  lob,
  hideBulkActionButtons,
  isViewAdmin,
  showHistory,
  inactive,
  ...props
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [historyOpen, setHistoryModal] = useState(false);
  const [rateRecord, setRateRecord] = useState();
  const [historyRecord, setHistoryRecord] = useState();
  const [deactivateOpen, setDeactivateModal] = useState(false);
  const [deactivateRecord, setDeactivateRecord] = useState();
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState('');

  useEffect(() => {
    // see comment on case_communication
    if (rateRecord) {
      setEventModal(true);
    }
  }, [rateRecord]);

  const unselectAll = useUnselectAll('payers-facility/rates');
  useEffect(() => {
    return () => {
      unselectAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setEventModal(false);
    setRateRecord(undefined);
  };

  useEffect(() => {
    if (selectedNote) {
      setShowNotesModal(true);
    }
  }, [selectedNote]);
  const handleNotesModalClose = () => {
    setShowNotesModal(false);
    setSelectedNote('');
  };

  const openHistoryModal = record => {
    setHistoryModal(true);
    setHistoryRecord(record);
  };

  const handleHistoryModalClose = () => {
    setHistoryModal(false);
    setHistoryRecord(undefined);
  };

  const openDeactivateModal = record => {
    setDeactivateModal(true);
    setDeactivateRecord(record);
  };

  const handleDeactivateModalClose = () => {
    setDeactivateModal(false);
    setDeactivateRecord(undefined);
  };

  const controllerProps = useListController({
    ...props,
    resource: showHistory
      ? 'payers-facility/rate-logs'
      : inactive
      ? 'payers-facility/inactive-rates'
      : 'payers-facility/rates',
    filter: { id, contract: showContract, lob: lob },
  });
  const { loading } = controllerProps;
  return loading ? (
    <Loading />
  ) : (
    <div className='show-list'>
      <ListView
        empty={false}
        {...controllerProps}
        exporter={false}
        actions={
          Array.isArray(permissions) &&
          (permissions.indexOf('admin') > -1 ||
            permissions.indexOf('supervisor') > -1 ||
            permissions.indexOf('contract_user') > -1) ? (
            <ListActions
              record={record}
              payer_facility_id={id}
              inactive={inactive}
            />
          ) : null
        }
        bulkActionButtons={
          hideBulkActionButtons || isViewAdmin ? false : undefined
        }
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
        // filters={<FiltersView classes={classes} />}
      >
        <Datagrid>
          <TextField source='level_name' />
          <TextField source='lines_of_business' label='Lines of business' />
          <DateField
            source='effective_date'
            label='Effective date'
            headerClassName={classes.listItemLabel}
          />
          {(showHistory || inactive) && (
            <DateField
              source='inactive_date'
              label='Inactive date'
              headerClassName={classes.listItemLabel}
            />
          )}
          <FunctionField
            source='rev_codes'
            label='Rev codes'
            headerClassName={classes.listItemLabel}
            render={record => {
              const { rev_codes: _rev_codes } = record;
              // could be null...
              const rev_codes = _rev_codes ? _rev_codes : null;
              return Array.isArray(rev_codes) ? rev_codes.join(', ') : null;
            }}
            sortable={false}
          />
          <FunctionField
            source='rate'
            label='Reimbursement'
            headerClassName={classes.listItemLabel}
            render={record => {
              const { percent, rate } = record;
              return !!percent ? `${percent}%` : currencyFormat(rate);
            }}
          />
          {showContract && <TextField source='category' label='Category' />}
          {showContract && <TextField source='services' label='Services' />}
          {!showContract && !showHistory && (
            <FunctionField
              source=''
              label=''
              sortable={false}
              render={record => {
                return Array.isArray(permissions) &&
                  (permissions.indexOf('admin') > -1 ||
                    permissions.indexOf('supervisor') > -1 ||
                    permissions.indexOf('contract_user') > -1) &&
                  (inactive || record.has_rate_logs) ? (
                  <Button
                    style={{ textTransform: 'capitalize' }}
                    color='primary'
                    onClick={() => openHistoryModal(record)}
                  >
                    View rate history
                  </Button>
                ) : null;
              }}
            />
          )}
          {!inactive && (
            <FunctionField
              source=''
              label=''
              sortable={false}
              render={record => {
                return Array.isArray(permissions) &&
                  (permissions.indexOf('admin') > -1 ||
                    permissions.indexOf('supervisor') > -1 ||
                    permissions.indexOf('contract_user') > -1) ? (
                  <IconButton
                    style={{ color: '#5C738E' }}
                    onClick={() => setRateRecord(record)}
                    size='small'
                  >
                    <EditIcon />
                  </IconButton>
                ) : null;
              }}
            />
          )}
          {!inactive && (
            <FunctionField
              source=''
              label=''
              sortable={false}
              render={record => {
                return Array.isArray(permissions) &&
                  (permissions.indexOf('admin') > -1 ||
                    permissions.indexOf('supervisor') > -1 ||
                    permissions.indexOf('contract_user') > -1) ? (
                  <Tooltip title='Deactivate'>
                    <IconButton
                      style={{ color: '#5C738E' }}
                      onClick={() => openDeactivateModal(record)}
                      size='small'
                    >
                      <DeactivateFacilityIcon />
                    </IconButton>
                  </Tooltip>
                ) : null;
              }}
            />
          )}
        </Datagrid>
      </ListView>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={rateRecord}
          meta={{
            line_of_businesses: record.line_of_businesses,
          }}
          form='rates'
          isEdit
          refresh={refresh}
          payerFacilityId={id}
        />
      )}
      {historyOpen && (
        <RateHistoryModal
          open={historyOpen}
          handleClose={handleHistoryModalClose}
          record={historyRecord}
          {...props}
        />
      )}
      {showNotesModal && (
        <NotesModal
          open={showNotesModal}
          handleClose={handleNotesModalClose}
          note={selectedNote}
        />
      )}
      {deactivateOpen && (
        <DeactivateRateModal
          open={deactivateOpen}
          handleClose={handleDeactivateModalClose}
          record={deactivateRecord}
          {...props}
        />
      )}
    </div>
  );
};

const RateHistoryModal = ({ record, open, handleClose, ...props }) => {
  // const notify = useNotify();
  // const [contract, setContract] = useState();

  // for react-admin
  const ratesProps = {
    ...props,
    resource: 'payers-facility/rate-logs',
    options: { label: 'Rates ' },
    basePath: '/payers-facility',
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: true,
  };

  if (!record) return null;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      maxWidth='lg'
      fullWidth
      disableScrollLock
    >
      <DialogContent>
        <h4>Rate history</h4>
        <Rates
          id={record.id}
          hideBulkActionButtons
          {...ratesProps}
          showHistory={true}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton
          Icon={CloseIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Close'
          type='button'
          variant='text'
          size='small'
          onClick={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

const DeactivateRateModal = ({ record, open, isEdit, handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const [inactiveDate, setInactiveDate] = useState();
  const dataProvider = cacheDataProviderProxy(useDataProvider());

  const onSubmit = value => {
    return dataProvider
      .update('deactivate-rate', {
        id: record.id,
        data: {
          inactive_date: inactiveDate,
        },
      })
      .then(({ data }) => {
        notify('form.updated');
        handleClose();
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      disableScrollLock
      fullWidth
      maxWidth='xs'
    >
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <span className={classes.eventTitle}> Deactivate rate</span>
                <div
                  className={classes.inputContainerWrap}
                  style={{ paddingTop: 10 }}
                >
                  <FormTextField
                    name='inactive_date'
                    className={classes.input}
                    label='Inactive date'
                    style={{ marginRight: 0 }}
                    customOnChange={e => setInactiveDate(e.target.value)}
                    type='date'
                    required
                    fullWidth
                    validate={composeValidators([
                      required(),
                      validateDate(),
                      minDate(
                        record.effective_date,
                        `Inactive date must be later than rate's effective date`,
                      ),
                    ])}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <CustomButton
                  onClick={handleSubmit}
                  label='Deactivate'
                  type='button'
                  variant='text'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};
