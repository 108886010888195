import React, {
  Fragment,
  cloneElement,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import clsx from 'clsx';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  useRefresh,
  List,
  Show,
  ShowView,
  SimpleShowLayout,
  BulkDeleteButton,
  TextField,
  FunctionField,
  // ReferenceField,
  // ChipField,
  TopToolbar,
  useUnselectAll,
  useNotify,
} from 'react-admin';
import {
  Drawer,
  IconButton,
  // Chip,
  Divider,
  Button,
  Typography,
} from '@material-ui/core';
import { authGet, authPost } from '../server';
import { CustomButton } from '../design/material-ui';
import {
  // getChip,
  constants,
  limitStringSize,
} from '../utils';
import {
  ToggleButton,
  DateField,
  TrackUser,
  Datagrid,
  StatusFilter,
  FilterChips,
  FilterMenu,
} from '../components/common';
import {
  TaskForm,
  NewEvent,
  Attachments,
  NotesModal,
} from '../components/common/modals';
import { AddAttachmentModal } from '../components/common/modals/modalForms';
import { ResidentShowFields, ResidentShowActions } from '../resources';
import { useGetCaseId, useShowController, useGetUserDivision } from '../hooks';
import { uiActions } from '../state/actions';
import {
  CloseIcon,
  AttachmentIcon,
  DenialIcon,
  AddIcon,
  EditIcon,
  AddAlertIcon,
  NotificationIcon,
} from '../design';
import { useStyles } from './auths.styles';
const { eventTypes } = constants;

const FacilityLevelActions = ({
  setDivision,
  isViewAdmin,
  activeStatusFilters = {},
  filterActions,
  filters,
  loading,
  setShowResolved,
  showResolved,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <div style={{ display: 'flex' }}>
            <span className={classes.title}>Denials</span>
            {/* Needed for spacing */}
            <span style={{ marginRight: 25 }} />
            <ToggleButton
              setDivision={setDivision}
              className={classes.toggleWrapper}
            />
          </div>
          <CustomButton
            Icon={AddIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Add'
            type='button'
            variant='text'
            size='small'
            onClick={() => setEventModal(true)}
            disabled={isViewAdmin}
          />
        </div>
        <div>
          <div style={{ display: 'flex', marginLeft: -30, marginTop: 10 }}>
            <FilterMenu filterActions={filterActions} filters={filters} />
            <FilterChips
              activeFilters={activeStatusFilters}
              filterActions={filterActions}
              disabled={loading}
            />
          </div>
          <div style={{ paddingTop: 10, paddingBottom: 15, paddingLeft: 3 }}>
            <StatusFilter
              setShowAll={setShowResolved}
              showAll={showResolved}
              label='Show resolved denials'
            />
          </div>
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='denialAppeal'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const CaseLevelActions = ({
  showAll,
  setShowAll,
  residentCaseCount,
  isViewAdmin,
  activeStatusFilters = {},
  filterActions,
  filters,
  loading,
  showResolved,
  setShowResolved,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <span className={classes.title}>Denials</span>
          <div>
            <CustomButton
              Icon={AddIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label='Add'
              type='button'
              variant='text'
              size='small'
              onClick={() => setEventModal(true)}
              disabled={isViewAdmin}
            />
          </div>
        </div>
        <div>
          <div style={{ display: 'flex', marginLeft: -30, marginTop: 10 }}>
            <FilterMenu filterActions={filterActions} filters={filters} />
            <FilterChips
              activeFilters={activeStatusFilters}
              filterActions={filterActions}
              disabled={loading}
            />
          </div>

          <div style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 3 }}>
            {residentCaseCount > 1 && (
              <StatusFilter setShowAll={setShowAll} showAll={showAll} />
            )}
          </div>
          <StatusFilter
            setShowAll={setShowResolved}
            showAll={showResolved}
            label='Show resolved denials'
          />
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='denialAppeal'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const DenialsShowActions = ({
  title,
  resource,
  closeAside,
  resourceRecord,
  isViewAdmin,
}) => {
  const classes = useStyles();
  return (
    <div>
      <TopToolbar className={classes.header}>
        <div className={classes.headerRow}>
          <span className={classes.title}>{title}</span>
          <IconButton onClick={closeAside} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
        <ActionButtons
          resourceRecord={resourceRecord}
          isViewAdmin={isViewAdmin}
        />
      </TopToolbar>
    </div>
  );
};

const ActionButtons = ({ resourceRecord = {}, isViewAdmin }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [addTaskOpen, setAddTaskModal] = useState(false);
  const [eventOpen, setEventModal] = useState(false);
  const [attchOpen, setAttchModal] = useState(false);
  const taskCount = get(resourceRecord, 'v_event_task.task_count', 0);
  const hasAttachments =
    Array.isArray(resourceRecord.document_ids) &&
    !!resourceRecord.document_ids.length;
  const documentCount = resourceRecord?.document_ids?.length;
  return (
    <Fragment>
      <div className={classes.actionContainer}>
        <span style={{ contentAlign: 'left' }}>
          <IconButton
            className={classes.showBtn}
            onClick={() => setEventModal(true)}
            size='small'
            disabled={isViewAdmin}
          >
            <EditIcon />
          </IconButton>
          {'         '}
          <IconButton
            className={classes.showBtn}
            onClick={() => setAddTaskModal(true)}
            size='small'
            disabled={isViewAdmin}
          >
            <AddAlertIcon />
          </IconButton>
          {'         '}
          <CustomButton
            Icon={AttachmentIcon}
            className={classes.showBtn}
            variant='text'
            badgeContent={documentCount}
            onClick={() => setAttchModal(true)}
            size='small'
            notRed
            fullSize
          />
        </span>
        <CustomButton
          Icon={NotificationIcon}
          color='#829CB5'
          label='Tasks'
          type='button'
          variant='text'
          size='small'
          className={classes.showBtn}
          badgeContent={taskCount}
          component={Link}
          disabled={!taskCount}
          to={`/cases/${
            resourceRecord.case_id
          }/tasks/not-completed?${qs.stringify({
            event_type: 'Denial',
            event_id: resourceRecord.id,
          })}`}
        />
      </div>
      {addTaskOpen && (
        <TaskForm
          open={addTaskOpen}
          handleClose={() => setAddTaskModal(false)}
          caseId={resourceRecord.case_id}
          refresh={refresh}
          related_to_data={{
            related_to_type: 'Denial',
            related_to_id: resourceRecord.id,
            related_to_date:
              resourceRecord.resolved_date || resourceRecord.verbal_received,
          }}
        />
      )}
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          record={resourceRecord}
          form='denialAppeal'
          isEdit
          refresh={refresh}
        />
      )}
      {attchOpen && (
        <Fragment>
          {hasAttachments ? (
            <Attachments
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              document_ids={resourceRecord.document_ids}
              title='Denials'
              eventId={resourceRecord.id}
              eventName={eventTypes.DENIAL_NAME}
              caseId={resourceRecord.case_id}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          ) : (
            <AddAttachmentModal
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              title='Denials'
              eventId={resourceRecord.id}
              eventName={eventTypes.DENIAL_NAME}
              caseId={resourceRecord.case_id}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const BulkActionButtons = ({ toggleAside, recordOpenId, ...props }) => {
  const { selectedIds = [] } = props;
  const dispatch = useDispatch();
  const caseIdFromUrl = useGetCaseId();
  function onClick() {
    if (
      typeof toggleAside === 'function' &&
      selectedIds.indexOf(recordOpenId) > -1
    ) {
      toggleAside(false);
    }
    if (caseIdFromUrl) {
      setTimeout(() => {
        dispatch(uiActions.getCaseTasksCount(caseIdFromUrl));
      }, 6000);
    }
  }
  return <BulkDeleteButton {...props} onClick={onClick} />;
};

export const DenialsList = ({ staticContext, ..._props }) => {
  const refresh = useRefresh();
  const userDivision = useGetUserDivision();
  const { caseId, ...props } = getResourceProps(_props);
  const facilityLevel = props.resource === 'denials';
  const classes = useStyles();
  const [division, setDivision] = useState(userDivision);
  const [asideOpen, toggleAside] = useState(false);
  const [rowRecord, setRowRecord] = useState({});
  const [showAll, setShowAll] = useState(true);
  const [showResolved, setShowResolved] = useState(false);
  const residentCaseCount = useSelector(state =>
    get(state, 'ui.residentCaseCount', 0),
  );
  const [filters, setFilters] = useState([]);
  const [activeStatusFilters, setActiveStatusFilters] = useState({});
  const firstRender = useRef(true);
  useEffect(() => {
    toggleAside(false);
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    refresh();
  }, [props.location.pathname, refresh]);

  const unselectAll = useUnselectAll('case-denials');
  const unselectAllCases = useUnselectAll('denials');
  useEffect(() => {
    return () => {
      unselectAllCases();
      unselectAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchFilters() {
      const response = await authGet('/denial-types/list');
      const { data } = response;
      if (data) {
        setFilters(data);
      }
    }

    fetchFilters();
  }, []);

  const rowClick = (id, basePath, record) => {
    setRowRecord({
      ...record,
    });
    toggleAside(true);
    return null;
  };

  const listRowStyle = (record, index, activeId) => {
    if (record && record.id === activeId && asideOpen) {
      return { backgroundColor: '#EFF4FB' };
    } else if (caseId && parseInt(record?.case_id) !== parseInt(caseId)) {
      return { backgroundColor: '#EFF4FB', opacity: 0.5, fontStyle: 'italic' };
    }
  };

  const toggleShowAll = useCallback(value => {
    toggleAside(false);
    setShowAll(value);
  }, []);

  const toggleShowResolved = useCallback(value => {
    toggleAside(false);
    setShowResolved(value);
  }, []);

  const filterActions = useCallback(({ actionType, id, name }) => {
    if (actionType === 'removeFilter') {
      setActiveStatusFilters(currentFilters => {
        const { [id]: filterToRemove, ...otherFilters } = currentFilters;
        return otherFilters;
      });
    } else if (actionType === 'addFilter') {
      setActiveStatusFilters(currentFilters => ({
        ...currentFilters,
        [id]: name,
      }));
    }
  }, []);

  const { permissions = '' } = props;
  const userPermissions = permissions.split(',');

  const isAdminOrSupervisor =
    userPermissions?.indexOf('admin') > -1 ||
    userPermissions?.indexOf('supervisor') > -1;

  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  const notify = useNotify();
  const handleResend = async (record, sendType, denialId) => {
    const {
      id,
      email_addresses,
      email_docs,
      fax_number,
      fax_name,
      case_id,
      label,
      html,
    } = record;
    const docs = email_docs.map(row => row.document);
    if (fax_number && !docs) return;
    notify('Resending...');
    const response = await authPost(`/cases/${case_id}/send-communication`, {
      docs,
      fax_number:
        fax_number && fax_name
          ? {
              fax: fax_number,
              name: fax_name,
            }
          : undefined,
      email_addresses: email_addresses
        ? email_addresses.split(', ')
        : undefined,
      subject: label,
      text: html,
      log_id: id,
      denial_id: denialId,
      sent_to_facility: sendType === 'facility',
      sent_to_payer: sendType === 'payer',
    });
    const { error } = response;
    if (error) {
      return notify(error.message, 'warning');
    }
    notify(fax_number ? 'Fax sent' : 'Email sent');
    refresh();
  };
  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={false}
        bulkActionButtons={
          Array.isArray(userPermissions) && isAdminOrSupervisor ? (
            <BulkActionButtons
              toggleAside={toggleAside}
              recordOpenId={rowRecord.id}
            />
          ) : (
            false
          ) // disable delete
        }
        className={clsx(classes.list, {
          [classes.listWithDrawer]: asideOpen,
        })}
        actions={
          facilityLevel ? (
            <FacilityLevelActions
              setDivision={setDivision}
              isViewAdmin={isViewAdmin}
              filterActions={filterActions}
              activeStatusFilters={activeStatusFilters}
              filters={filters}
              setShowResolved={toggleShowResolved}
              showResolved={showResolved}
            />
          ) : (
            <CaseLevelActions
              residentCaseCount={residentCaseCount}
              showAll={showAll}
              setShowAll={toggleShowAll}
              isViewAdmin={isViewAdmin}
              filterActions={filterActions}
              activeStatusFilters={activeStatusFilters}
              filters={filters}
              setShowResolved={toggleShowResolved}
              showResolved={showResolved}
            />
          )
        }
        filter={{
          all: showAll,
          division: facilityLevel ? division : undefined,
          filtered: Object.keys(activeStatusFilters),
          resolved: showResolved,
        }}
        sort={{
          field: 'verbal_received',
          order: 'DESC',
        }}
      >
        <Datagrid
          rowClick={rowClick}
          rowStyle={(record, index) =>
            listRowStyle(record, index, rowRecord.id)
          }
        >
          <TextField source='denial_type' label='Denial type' />
          {facilityLevel && (
            <FunctionField
              source='case_name'
              label='Case name'
              sortBy='last_name'
              render={record => (
                <Button
                  className={classes.referenceBtn}
                  color='primary'
                  onClick={e => e.stopPropagation()}
                  component={Link}
                  to={`/cases/${record.case_id}/timeline`}
                >
                  {record.case_name}
                </Button>
              )}
            />
          )}
          <TextField source='status' label='Status' />
          <DateField
            source='verbal_received'
            label='Verbal received date'
            headerClassName={classes.noWrap}
          />
          <DateField
            source='denial_received'
            label='Letter received date'
            headerClassName={classes.noWrap}
          />
          <FunctionField
            source='sent_to_facility'
            label='Sent to facility'
            className={classes.showItem}
            render={record => {
              const {
                sent_to_facility,
                signed_denial_received,
                sentToFacility,
              } = record;
              const email = Array.isArray(sentToFacility)
                ? sentToFacility.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at),
                  )[0]
                : null;
              return (
                <div>
                  <DateField
                    record={{
                      ...record,
                      sent_to_facility,
                    }}
                    source='sent_to_facility'
                    label='Sent to facility'
                  />
                  {email && !signed_denial_received && (
                    <Button
                      className={classes.resend}
                      color='primary'
                      onClick={e => {
                        e.stopPropagation();
                        handleResend(email, 'facility', record.id);
                      }}
                    >
                      Resend
                    </Button>
                  )}
                </div>
              );
            }}
          />

          <DateField
            source='signed_denial_received'
            label='Signed received date'
            headerClassName={classes.noWrap}
          />
          <FunctionField
            source='sent_to_insurance'
            label='Sent to insurance'
            className={classes.showItem}
            render={record => {
              const { sent_to_insurance, sentToPayer } = record;
              const email = Array.isArray(sentToPayer)
                ? sentToPayer.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at),
                  )[0]
                : null;
              const { fax_number, fax_result } = email || {};
              return (
                <div>
                  <DateField
                    record={{ ...record, sent_to_insurance }}
                    source='sent_to_insurance'
                    label='Sent to insurance'
                  />
                  {email &&
                    (!fax_number ||
                      fax_result?.status === 'SendingFailed' ||
                      fax_result?.status === 'Unknown') && (
                      <Button
                        className={classes.resend}
                        color='primary'
                        onClick={e => {
                          e.stopPropagation();
                          handleResend(email, 'payer', record.id);
                        }}
                      >
                        Resend
                      </Button>
                    )}
                </div>
              );
            }}
          />
          <DateField source='last_covered' label='Last covered' />
          <TextField source='resolution_name' label='Resolution' />
        </Datagrid>
      </List>
      <Drawer
        variant='persistent'
        open={asideOpen}
        anchor='right'
        elevation={16}
        PaperProps={{ square: true }}
        classes={{
          paperAnchorDockedRight: clsx(classes.aside),
        }}
      >
        {asideOpen
          ? cloneElement(<DenialsShow />, {
              ...props,
              id: rowRecord.id,
              closeAside: () => toggleAside(false),
              resource: 'denials',
              classes: classes,
              residentId: rowRecord.resident_id,
              isViewAdmin: isViewAdmin,
            })
          : null}
      </Drawer>
    </Fragment>
  );
};

export const DenialsShow = ({
  closeAside,
  classes,
  residentId,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const maxNotesLength = 160;
  const showProps = useShowController({ ...props });
  const { record } = showProps;
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <DenialsShowActions
            closeAside={closeAside}
            title='Denial'
            resourceRecord={record}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <TextField
            source='denial_type'
            label='Denial type'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='verbal_received'
            className={classes.showItem}
            label='Verbal received'
            textAlign='right'
          />
          <DateField
            source='denial_received'
            className={classes.showItem}
            label='Letter received'
            textAlign='right'
          />
          <DateField
            source='last_covered'
            className={classes.showItem}
            label='Last covered'
            textAlign='right'
          />
          <TextField
            source='post_lcd_option'
            label='Post LCD'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='v_authorization.insurance_auth_number'
            label='Auth number'
            textAlign='right'
            className={classes.showItem}
          />
          <div className={classes.showItem} />
          <FunctionField
            source='signature_required'
            label='Signature required'
            className={classes.showItem}
            render={record => {
              const { signature_required } = record;
              return signature_required ? 'Yes' : 'No';
            }}
          />
          <FunctionField
            source='signature_today'
            label='Same day signature'
            className={classes.showItem}
            render={record => {
              const { signature_today } = record;
              return signature_today ? 'Yes' : 'No';
            }}
          />
          <DateField
            source='signature_required_by'
            className={classes.showItem}
            label='Signature required by'
            textAlign='right'
          />
          <DateField
            source='sent_to_facility'
            className={classes.showItem}
            label='Denial sent to facility'
            textAlign='right'
          />
          <DateField
            source='signed_denial_received'
            className={classes.showItem}
            label='Signed denial received'
            textAlign='right'
          />
          <DateField
            source='sent_to_insurance'
            className={classes.showItem}
            label='Signed denial sent to ins.'
            textAlign='right'
          />
          <TextField
            source='appeal_type_name_l1'
            label='Level 1 appeal'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='date_sent_l1'
            className={classes.showItem}
            label='Level 1 sent'
            textAlign='right'
          />
          <TextField
            source='appeal_status_l1'
            label='Level 1 status'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='appeal_type_name_l2'
            label='Level 2 appeal'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='date_sent_l2'
            className={classes.showItem}
            label='Level 2 sent'
            textAlign='right'
          />
          <TextField
            source='appeal_status_l2'
            label='Level 2 status'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='appeal_type_name_l3'
            label='Level 3 appeal'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='date_sent_l3'
            className={classes.showItem}
            label='Level 3 sent'
            textAlign='right'
          />
          <TextField
            source='appeal_status_l3'
            label='Level 3 status'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='appeal_type_name_l4'
            label='Level 4 appeal'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='date_sent_l4'
            className={classes.showItem}
            label='Level 4 sent'
            textAlign='right'
          />
          <TextField
            source='appeal_status_l4'
            label='Level 4 status'
            textAlign='right'
            className={classes.showItem}
          />

          <TextField
            source='appeal_type_name_l5'
            label='Level 5 appeal'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='date_sent_l5'
            className={classes.showItem}
            label='Level 5 sent'
            textAlign='right'
          />
          <TextField
            source='appeal_status_l5'
            label='Level 5 status'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='resolution_name'
            label='Resolution'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='resolved_date'
            label='Resolved'
            className={classes.showItem}
            render={record =>
              !!record.resolved_date ? (
                <DateField
                  record={record}
                  source='resolved_date'
                  textAlign='right'
                  className={classes.funcShowItem}
                />
              ) : null
            }
          />
          {record && record.discharge_location ? (
            <FunctionField
              source='discharge_location'
              label='Location'
              className={classes.showItem}
              render={record => startCase(record.discharge_location)}
            />
          ) : (
            <div className={classes.showItem} />
          )}
          <FunctionField
            source='comment'
            label='Comments'
            textAlign='right'
            className={clsx(classes.showItem, classes.commentField)}
            render={record => {
              const { comment } = record;
              const truncated = limitStringSize(comment, maxNotesLength, true);
              return comment && comment.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setShowNotesModal(true)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          <FunctionField
            source='vDenialCreatedBy.name'
            label='Created by'
            className={classes.showItem}
            render={record => {
              const { vDenialCreatedBy, created_at } = record;
              return (
                <TrackUser
                  userField={vDenialCreatedBy}
                  timestamp={created_at}
                />
              );
            }}
          />
          <FunctionField
            source='vDenialUpdatedBy.name'
            label='Last modified'
            className={classes.showItem}
            render={record => {
              const { vDenialUpdatedBy, updated_at } = record;
              return (
                <TrackUser
                  userField={vDenialUpdatedBy}
                  timestamp={updated_at}
                />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {/* <div className={classes.showItem} /> */}
          {showNotesModal && (
            <NotesModal
              open={showNotesModal}
              handleClose={() => setShowNotesModal(false)}
              note={record.comment}
            />
          )}
        </SimpleShowLayout>
      </ShowView>
      <Divider className={classes.residentDivider} />
      <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
        Resident info
      </p>
      <Show
        actions={
          <ResidentShowActions
            closeAside={closeAside}
            isResidentPage={false}
            isViewAdmin={isViewAdmin}
          />
        }
        {...rest}
        resource='residents'
        id={residentId}
      >
        <ResidentShowFields classes={classes} includeCases />
      </Show>
    </div>
  );
};

export const denialsResource = {
  name: 'denials',
  list: DenialsList,
  icon: DenialIcon,
  // show: CaseTimelineShow,
};

function getResourceProps(props) {
  if (props.basePath === '/denials') return props;
  const {
    location: { pathname } = {},
    match: { params: { id: caseId } = {} } = {},
  } = props;
  const basePath = pathname || '/cases';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-denials',
        hasList: true,
        hasEdit: !!denialsResource.edit,
        hasShow: !!denialsResource.show,
        hasCreate: !!denialsResource.create,
      };
  return { ...props, basePath, caseId, ...resource };
}
