import { makeStyles } from '@material-ui/core';
import { styles } from '../../../design';

export const useStyles = makeStyles(theme => ({
  root: { fontStyle: 'Nunito Sans' },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 10,
  },
  eventTitle: {
    fontSize: 16,
    fontWeight: 600,
    padding: 0,
    display: 'inline',
  },
  modalTitle: { padding: '18 24px', fontSize: 18, fontWeight: 600 },
  saveButton: {
    backgroundColor: theme.palette.primary.buttons,
    '&:hover': {
      backgroundColor: theme.palette.primary.buttons,
    },
    textTransform: 'capitalize',
  },
  header: {
    paddingTop: 40,
    marginBottom: 0,
    fontSize: 18,
    fontWeight: 600,
    color: 'rgb(0,0,0)',
  },
  subHeader: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 14,
    marginTop: 3,
  },
  sectionSubHeader: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: '-5px',
  },
  input: {
    fontWeight: 'normal',
    color: '#000000',
  },
  inputContainer: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    '& >:first-child': {
      marginRight: 30,
    },
  },
  inputContainerWrap: {
    ...styles.flexRow,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    paddingTop: 0,
    paddingBottom: 3,

    '& div:nth-child(2n)': {
      marginRight: 0,
    },
    '& div:nth-child(1)': {
      marginRight: 20,
    },
  },

  therapyContainerWrap: {
    ...styles.flexRow,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    paddingTop: 0,
    paddingBottom: 3,

    '& div:nth-child(3n)': {
      marginRight: 0,
    },
  },
  smallInput: {
    flex: '1 0 155px',
    marginLeft: theme.spacing(1),
  },
  mediumInput: {
    flex: '1 0 200px',
    marginLeft: theme.spacing(1),
  },
  textField: {
    marginLeft: 3,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  padding16: {
    paddingRight: 16,
    paddingLeft: 16,
  },
  divider: {
    // color: '#829CB5',
    // height: 2,
    marginTop: 4,
    marginBottom: 24,
  },
  title: {
    color: '#121212',
    fontSize: 15,
    fontWeight: 600,
  },
  secondaryActionContainer: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    marginTop: 10,
    width: '100%',
  },
  secondaryAction: {
    ...styles.flexRow,
    ...styles.justifystart,
    cursor: 'pointer',
  },
  sectionButtonLabel: {
    textTransform: 'none',
  },
  iconButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.text,
    textTransform: 'none',
    width: 130,
  },
  removeIcon: {
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  radio: {
    marginTop: 15,
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    fontWeight: 500,
  },
  chip: {
    margin: 2,
    height: 22,
    backgroundColor: theme.palette.secondary.text,
    color: theme.palette.primary.main,
  },
  carvOuts: {
    maxWidth: 310,
  },
  insuranceTitle: {
    color: '#121212',
    fontSize: 15,
    marginTop: 20,
    marginBottom: 15,
    fontWeight: 600,
  },
  removeEvent: {
    ...styles.cursorPointer,
    fontSize: 12,
    marginTop: -10,
  },
  removeSuperior: {
    paddingTop: 0,
    marginTop: '-13px',
    display: 'block',
    textTransform: 'capitalize',
  },
  dropZone: {
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
    textAlign: 'center',
    borderStyle: 'dashed',
    border: 0.05,
    borderColor: '#9FB4CB',
    padding: 20,
  },
  instructions: {
    fontSize: 14,
  },
  subtitle: {
    fontFamily: theme.palette.text.fontFamily,
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.text.secondary,
    contentAlign: 'left',
  },
  warnColor: {
    color: `${theme.palette.warning.main} !important`,
  },
}));
