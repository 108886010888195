import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import clsx from 'clsx';
import qs from 'query-string';
import {
  useListController,
  ListView,
  Pagination,
  TextField,
  FunctionField,
  // NumberField,
  ChipField,
  TopToolbar,
  useRefresh,
  // ReferenceField,
  useRedirect,
  useUpdate,
  useNotify,
  usePermissions,
} from 'react-admin';
// import { authGet } from '../server';
import { Button, Paper, Tabs, Tab, Chip, IconButton } from '@material-ui/core';
import { getChip, weekDaysObj } from '../utils';
import {
  ToggleButton,
  Datagrid,
  DateField,
  CaseManagerSelect,
} from '../components/common';
import { NewEvent } from '../components/common/modals';
import { useGetUserDivision } from '../hooks';
import { useStyles } from './updates.styles';
import {
  UpdateIcon,
  AddIcon,
  EditIcon,
  CustomButton,
  NewUpdateIcon,
} from '../design';

const FacilityLevelActions = ({
  setDivision,
  formBasePath,
  filterManager,
  caseManagerQuery,
  isViewAdmin,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <div style={{ display: 'flex', paddingBottom: 20 }}>
            <span className={classes.title}>Updates</span>
            {/* Needed for spacing */}
            <span style={{ marginRight: 25 }} />
            <ToggleButton
              setDivision={setDivision}
              className={classes.toggleWrapper}
            />
          </div>
          <CustomButton
            Icon={AddIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Add'
            type='button'
            variant='text'
            size='small'
            component={Link}
            to={`${formBasePath}/form`}
            disabled={isViewAdmin}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <CaseManagerSelect
            filterManager={filterManager}
            initialValue={[parseInt(caseManagerQuery)]}
          />
        </div>
      </TopToolbar>
    </Fragment>
  );
};

const CaseLevelActions = ({
  formBasePath,
  caseId,
  basePath,
  rootBasePath,
  setListType,
  isViewAdmin,
}) => {
  const classes = useStyles();
  // const facilityId = useSelector(state => state.facility.id);
  // const [caseStatus, setCaseStatus] = useState('');
  // useEffect(() => {
  //   async function fetchCase() {
  //     if (!facilityId || !caseId) return;
  //     const response = await authGet(`cases/${caseId}`);
  //     if (response.error) return;
  //     setCaseStatus(response.data.case_status ? response.data.case_status : '');
  //   }
  //   fetchCase();
  // }, [caseId, facilityId]);
  return (
    <Fragment>
      <TopToolbar
        className={clsx(
          classes.listActionWrapper,
          classes.flexWrap,
          classes.tabsActionWrapper,
        )}
      >
        <span className={classes.title}>Updates</span>
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Add'
          type='button'
          variant='text'
          size='small'
          component={Link}
          to={`${formBasePath}/form`}
          disabled={isViewAdmin}
        />
        <UpdatesTabs
          setListType={setListType}
          classes={classes}
          basePath={basePath}
          rootBasePath={rootBasePath}
        />
      </TopToolbar>
    </Fragment>
  );
};

export const UpdatesList = ({ staticContext, ..._props }) => {
  const userDivision = useGetUserDivision();
  const { formBasePath, rootBasePath, caseId, ...props } = getResourceProps(
    _props,
  );
  const facilityLevel = props.resource === 'updates';
  const { basePath, location: { search } = {} } = props;
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [division, setDivision] = useState(userDivision);
  const [dateDue, setDateDue] = useState();
  const [caseManager, setCaseManager] = useState();
  const [caseManagerQuery, setCaseManagerQuery] = useState();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  const [listType] = useState(
    basePath === `${rootBasePath}updates/list` ? 0 : 1,
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    const { date_due, 'user-id': userId } = qs.parse(search);
    if (date_due) {
      setDateDue(date_due);
    } else {
      setDateDue(undefined);
    }
    if (userId) {
      setCaseManager(userId);
      setCaseManagerQuery(userId);
    } else {
      setCaseManagerQuery(null);
    }
  }, [search]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  const filterManager = useCallback(value => {
    setCaseManager(value);
  }, []);

  const setListType = value => {
    if (value === listType) return;
    const { location: { search } = {} } = props;
    const path = value === 0 ? 'updates/list' : 'updates/schedules';
    const queryParams = qs.parse(search);
    redirect(`${rootBasePath}${path}?${qs.stringify({ ...queryParams })}`);
  };

  const controllerProps = useListController({
    ...props,
    // filters={<UpdatesFilter />}
    filter: {
      date: dateDue,
      division: facilityLevel ? division : undefined,
      caseManagerId: caseManager,
    },
    pagination: (
      <Pagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        style={{
          display: 'flex',
        }}
      />
    ),
    // if a user changes the pagination react-admin will override the current query params
    perPage: dateDue ? 100 : undefined,
  });

  if (caseManagerQuery === undefined) return null;

  return facilityLevel || listType === 0 ? (
    <UpdatesListView
      controllerProps={controllerProps}
      setDivision={setDivision}
      setListType={setListType}
      basePath={basePath}
      rootBasePath={rootBasePath}
      formBasePath={formBasePath}
      caseId={caseId}
      facilityLevel={facilityLevel}
      filterManager={filterManager}
      caseManagerQuery={caseManagerQuery}
      isViewAdmin={isViewAdmin}
    />
  ) : (
    <UpdatesScheduelsView
      controllerProps={controllerProps}
      setListType={setListType}
      basePath={basePath}
      rootBasePath={rootBasePath}
      formBasePath={formBasePath}
      caseId={caseId}
      isViewAdmin={isViewAdmin}
    />
  );
};

const UpdatesListView = ({
  controllerProps,
  facilityLevel,
  setDivision,
  formBasePath,
  basePath,
  rootBasePath,
  setListType,
  caseId,
  filterManager,
  caseManagerQuery,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [updatesRecord, setUpdatesRecord] = useState();

  useEffect(() => {
    // see comment on case_communication
    if (updatesRecord) {
      setEventModal(true);
    }
  }, [updatesRecord]);

  const handleModalClose = () => {
    setEventModal(false);
    setUpdatesRecord(undefined);
  };

  return (
    <Fragment>
      <ListView
        empty={false}
        {...controllerProps}
        exporter={false}
        bulkActionButtons={false}
        className={clsx(classes.list)}
        actions={
          facilityLevel ? (
            <FacilityLevelActions
              setDivision={setDivision}
              formBasePath={formBasePath}
              basePath={basePath}
              rootBasePath={rootBasePath}
              setListType={setListType}
              filterManager={filterManager}
              caseManagerQuery={caseManagerQuery}
              isViewAdmin={isViewAdmin}
            />
          ) : (
            <CaseLevelActions
              formBasePath={formBasePath}
              basePath={basePath}
              rootBasePath={rootBasePath}
              caseId={caseId}
              setListType={setListType}
              isViewAdmin={isViewAdmin}
            />
          )
        }
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid>
          <FunctionField
            source='next_update'
            label='Next update'
            sortBy='next_update_date'
            render={record => {
              const { next_update } = record;
              return (
                <span className={classes.noWrap}>
                  {next_update}
                  <EditIcon
                    className={classes.editIcon}
                    onClick={() =>
                      !isViewAdmin ? setUpdatesRecord(record) : false
                    }
                    disabled={isViewAdmin}
                  />
                </span>
              );
            }}
          />
          <FunctionField
            source='status'
            label='Status'
            render={record => {
              return (
                <ChipField
                  record={record}
                  source='status'
                  className={clsx(
                    classes.chip,
                    classes[getChip(record.status)],
                  )}
                />
              );
            }}
          />
          <FunctionField
            source='case_name'
            label='Case name'
            sortBy='last_name'
            render={record => (
              <Button
                className={classes.referenceBtn}
                color='primary'
                onClick={e => e.stopPropagation()}
                component={Link}
                to={`/cases/${record.case_id}/timeline`}
              >
                {record.case_name}
              </Button>
            )}
          />
          <FunctionField
            source='update_weekday'
            label='Days of week'
            sortable={false}
            render={record => {
              const { update_weekday } = record;
              return (
                Array.isArray(update_weekday) &&
                update_weekday.map(u => weekDaysObj[u + '']).join(', ')
              );
            }}
          />
          <TextField source='insurance_auth_number' label='Auth #' />
          <TextField source='payer' label='Payer' />
          <TextField source='update_frequency' label='Frequency' />
          <FunctionField
            sortable={false}
            source='update_type'
            label='Type'
            render={record =>
              record.update_type && record.update_type.join(', ')
            }
          />
          <TextField source='update_notes' label='Update notes' />
          <FunctionField
            source='status'
            label='Actions'
            sortable={false}
            render={record => {
              return (
                <IconButton
                  classes={{ root: classes.showBtn }}
                  component={Link}
                  to={`${formBasePath}/form?${qs.stringify({
                    nextUpdate: record.next_update_date,
                    title: `Progress ${format(
                      new Date(`${record.next_update_date} 00:00`),
                      'M/d/yyyy',
                    )} - ${record.full_name}`,
                    previousPath: basePath,
                    authId: record.auth_id,
                    level: record.level,
                    payer: record.payer,
                    authStart: record.start_date,
                    authEnd: record.raw_end,
                    progressId: record.progress_id,
                    insurance_auth_number: record.insurance_auth_number,
                  })}`}
                  disabled={isViewAdmin}
                >
                  <NewUpdateIcon />
                </IconButton>
              );
            }}
          />
        </Datagrid>
      </ListView>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          form='editUpdateDate'
          refresh={refresh}
          record={updatesRecord}
        />
      )}
    </Fragment>
  );
};

const UpdatesScheduelsView = ({
  controllerProps,
  formBasePath,
  basePath,
  rootBasePath,
  setListType,
  caseId,
  isViewAdmin,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <ListView
        empty={false}
        {...controllerProps}
        exporter={false}
        bulkActionButtons={false}
        className={clsx(classes.list)}
        actions={
          <CaseLevelActions
            formBasePath={formBasePath}
            basePath={basePath}
            rootBasePath={rootBasePath}
            caseId={caseId}
            setListType={setListType}
            isViewAdmin={isViewAdmin}
          />
        }
      >
        <Datagrid>
          <FunctionField
            source='id'
            label='Auth number'
            headerClassName={classes.noWrap}
            render={record => {
              const { id, auth_id } = record;
              if (!caseId || !id) {
                return null;
              }
              return (
                <Button
                  className={classes.referenceBtn}
                  color='primary'
                  onClick={e => e.stopPropagation()}
                  component={Link}
                  to={`/cases/${caseId}/timeline?${qs.stringify({
                    event_id: id,
                  })}`}
                >
                  {auth_id}
                </Button>
              );
            }}
          />
          <TextField
            sortable={false}
            source='recurrence'
            label='Schedule type'
            className={classes.capitalize}
          />
          <DateField
            source='date'
            label='Date/start date'
            sortBy='next_update_date'
          />
          <TextField source='frequency' label='Frequency' />
          <TextField source='payer' label='Insurance' />
          <FunctionField
            sortable={false}
            source='type'
            label='Update type'
            render={record =>
              Array.isArray(record.type) && record.type.join(', ')
            }
          />
          <FunctionField
            label='Status'
            source='inactive'
            render={record => {
              const { inactive } = record;
              const status = inactive ? 'Inactive' : 'Active';
              return (
                <Chip
                  size='small'
                  label={status}
                  className={clsx(classes.chip, classes[getChip(status)])}
                />
              );
            }}
          />
          <FunctionField
            source='status'
            label='Action'
            sortable={false}
            render={record => (
              <ActionButton record={record} isViewAdmin={isViewAdmin} />
            )}
          />
        </Datagrid>
      </ListView>
    </Fragment>
  );
};

export const updatesResource = {
  name: 'updates',
  list: UpdatesList,
  icon: UpdateIcon,
};

function getResourceProps(props) {
  if (props.basePath === '/updates')
    return { ...props, formBasePath: '/progress' };
  const {
    location: { pathname } = {},
    match: { params: { id: caseId } = {} } = {},
  } = props;
  const formBasePath = `/cases/${caseId}/progress`;
  const split = pathname.split('/');
  const listType = split[4];
  const basePath = pathname;
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: listType === 'list' ? 'case-updates' : 'updates-schedules',
        hasList: true,
        hasEdit: !!updatesResource.edit,
        hasShow: !!updatesResource.show,
        hasCreate: !!updatesResource.create,
      };

  return {
    ...props,
    basePath,
    formBasePath,
    caseId,
    rootBasePath: caseId ? `/cases/${caseId}/` : '/',
    ...resource,
  };
}

const UpdatesTabs = ({ setListType, classes, basePath, rootBasePath }) => {
  const [value, setValue] = useState(
    basePath === `${rootBasePath}updates/list` ? 0 : 1,
  );
  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
      setListType(newValue);
    },
    [setListType],
  );
  return (
    <Paper className={classes.tabContainer} square>
      <Tabs
        value={value}
        indicatorColor='primary'
        textColor='inherit'
        onChange={handleChange}
      >
        <Tab
          label='Updates'
          classes={{ root: classes.tab, selected: classes.selectedTab }}
        />
        <Tab
          label='Updates schedule'
          classes={{ root: classes.tab, selected: classes.selectedTab }}
        />
      </Tabs>
    </Paper>
  );
};

const ActionButton = ({ record, isViewAdmin }) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const { inactive } = record;
  const [setStatus, { loading }] = useUpdate(
    'authorizations',
    record.id,
    { inactive_schedule: inactive === 0 ? 1 : 0 },
    record,
    {
      onSuccess: () => {
        notify('Changes saved', 'info');
        refresh();
      },
    },
  );
  return (
    <Button
      classes={{ root: classes.actionButton }}
      onClick={setStatus}
      disabled={loading || isViewAdmin}
    >
      {inactive === 0 ? 'Cancel' : 'Restart'}
    </Button>
  );
};
