import React, { Fragment, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import qs from 'query-string';
import startCase from 'lodash/startCase';
import jsonExport from 'jsonexport/dist';
import { format, isFuture, isToday, isValid, differenceInDays } from 'date-fns';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  List,
  Pagination,
  ExportButton,
  downloadCSV,
  ShowView,
  SimpleShowLayout,
  BulkDeleteButton,
  TextField,
  FunctionField,
  // NumberField,
  ChipField,
  TopToolbar,
  ReferenceManyField,
  useRefresh,
  useRedirect,
  ReferenceField,
  useUnselectAll,
} from 'react-admin';
import {
  useSidebarOpen,
  useShowController,
  useGetUserDivision,
  useUpdatePageQueryParams,
} from '../hooks';
import {
  Drawer,
  IconButton,
  Chip,
  Paper,
  Tabs,
  Tab,
  Tooltip,
  Button,
  Link as MuiLink,
} from '@material-ui/core';
import { getChip } from '../utils';
import {
  ToggleButton,
  DateField,
  Loading,
  FilterMenu,
  FilterChips,
  CaseManagerSelect,
} from '../components/common';
import { TrackUser, Datagrid } from '../components/wrappers';
import { NewEvent } from '../components/common/modals';
import { normalizePhone } from '../components/common/modals/modalForms';
import {
  PeopleIcon,
  CloseIcon,
  EditIcon,
  CustomButton,
  InfoIcon,
  LaunchIcon,
  ThumbtackIcon,
} from '../design';
import { constants } from '../utils';
import { useStyles } from './residents.styles';
const { noAuthPayers } = constants;
const noAuthPayersArray = Object.values(noAuthPayers);

const exporter = fields => {
  const fieldsForExport = fields.map(field => {
    const {
      id,
      first_name,
      middle_name,
      last_name,
      name,
      case_id,
      resident_status_id,
      facility_id,
      created_by,
      rep_phone: _rep_phone,
      phone_ext,
      original_admit_date,
      facility_name,
      auth_id,
      primary_payer_id,
      secondary_payer_id,
      original_room_number,
      residents_insurance_id,
      facility_resident_id,
      ...rest
    } = field;
    const ext = phone_ext ? phone_ext : '';
    const rep_phone = _rep_phone
      ? `${normalizePhone(_rep_phone)} ${ext}`
      : null;
    rest.rep_phone = rep_phone;
    //Need to do this so that Excel does not drop the leading zero
    rest.facility_resident_id = facility_resident_id
      ? `\t${facility_resident_id}`
      : '';
    rest.residents_insurance_id = residents_insurance_id
      ? `\t${residents_insurance_id}`
      : '';
    return rest;
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'resident_status',
        'facility_resident_id',
        'full_name',
        'room_number',
        'admit_date',
        'last_admit_date',
        'payer_name',
        'payer_type',
        'residents_insurance_id',
        'authorization_type',
        'auth_through',
        'next_update',
        'rep_name',
        'rep_phone',
        'rep_fax',
        'discharge_date',
        'expired_date',
        'payer_change_date',
      ],
      rename: [
        'Status',
        'Patient Id',
        'Patient Name',
        'RM#',
        'Admit Date',
        'Last Admit Date',
        'Insurance',
        'Insurance Type',
        'Insurance ID',
        'Rate Type',
        'Auth Through',
        'Next Update',
        'Insurance Case Manager Name',
        'Insurance Case Manager Phone',
        'Insurance Case Manager Fax',
        'Discharge Date',
        'Expired Date',
        'Payer Change Date',
      ],
    },
    (err, csv) => {
      downloadCSV(
        csv,
        `Residents_${
          Array.isArray(fields) ? fields[0].facility_name : ''
        }_${format(new Date(), 'MM/dd/yyyy')}`,
      );
    },
  );
};

const ResidentListActions = ({
  resource,
  division,
  setDivision,
  insuranceTab,
  search,
  setInsuranceTab,
  total,
  currentSort,
  filterValues = {},
  permanentFilter = {},
  filterActions,
  activeStatusFilters = {},
  filterManager,
  caseManager,
  loading,
  queryId,
  persist,
  setPersist,
  persistedDivision,
}) => {
  const classes = useStyles();
  const handlePersist = switchSettings => {
    if ((!persist && switchSettings) || (persist && !switchSettings)) {
      localStorage.setItem('residentsDivision', division);
      localStorage.setItem('residentsInsurance', insuranceTab.id);
      localStorage.setItem(
        'residentsFilters',
        Object.entries(activeStatusFilters).map(row => row[0] + ':' + row[1]),
      );
      localStorage.setItem('residentsCaseManager', caseManager);
    } else {
      localStorage.removeItem('residentsDivision');
      localStorage.removeItem('residentsInsurance');
      localStorage.removeItem('residentsFilters');
      localStorage.removeItem('residentsCaseManager');
    }
    if (switchSettings) setPersist(!persist);
  };
  useEffect(() => {
    if (persist) {
      handlePersist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [division, insuranceTab, activeStatusFilters, caseManager]);
  return (
    <TopToolbar
      className={clsx(classes.listActionWrapper, classes.flexWrap, {
        [classes.tabsActionWrapper]: division === 'mltc',
      })}
    >
      <div className={classes.listActionWrapper}>
        <div style={{ display: 'flex', paddingBottom: 20 }}>
          <span className={classes.title} style={{ paddingRight: 25 }}>
            Residents
          </span>
          {/* Needed for spacing */}

          <ToggleButton
            setDivision={setDivision}
            className={classes.toggleWrapper}
            initialDivision={queryId ? 'all' : persistedDivision || undefined}
          />

          <Tooltip title='Keep filters'>
            <div>
              <IconButton
                onClick={handlePersist}
                style={{ color: persist ? '#1061A0' : '#9FB4CB' }}
              >
                <ThumbtackIcon />
              </IconButton>
            </div>
          </Tooltip>
        </div>
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={exporter}
          className={classes.exportButton}
        />
      </div>

      <div style={{ display: 'flex' }}>
        <CaseManagerSelect
          filterManager={filterManager}
          initialValue={
            caseManager ? caseManager && [parseInt(caseManager)] : undefined
          }
        />
        <FilterMenu filterActions={filterActions} filters={filters} />
        <FilterChips
          activeFilters={activeStatusFilters}
          filterActions={filterActions}
          disabled={loading}
        />
      </div>

      {division === 'mltc' && (
        <InsuranceTabs
          setInsuranceTab={setInsuranceTab}
          insuranceTab={insuranceTab}
          classes={classes}
          search={search}
        />
      )}
    </TopToolbar>
  );
};

export const ResidentShowActions = ({
  data = {},
  closeAside,
  isResidentPage = true,
  isViewAdmin,
}) => {
  const [eventOpen, setEventModal] = useState(false);
  const classes = useStyles();
  const refresh = useRefresh();
  const { first_name, last_name, resident_status, integration_id } = data || {};
  const integration_isactive = useSelector(
    state => state.facility?.integration_isactive,
  );

  const handleModalClose = () => {
    setEventModal(false);
  };

  return (
    <div>
      <TopToolbar className={classes.header}>
        {isResidentPage && (
          <div className={classes.headerRow}>
            <span
              className={classes.title}
            >{`${last_name}, ${first_name}`}</span>
            <div style={{ display: 'flex' }}>
              {isResidentPage && (
                // <div className={classes.statusChipWrapper}>
                <Chip
                  size='small'
                  label={resident_status}
                  className={clsx(
                    classes.chip,
                    classes[getChip(resident_status)],
                  )}
                />
                // </div>
              )}
              <div style={{ padding: 2 }} />
              <CustomButton
                style={{ boxShadow: 'none' }}
                className={classes.showBtn}
                onClick={() => setEventModal(true)}
                size='small'
                label='Edit'
                Icon={EditIcon}
                disabled={isViewAdmin}
              />
            </div>
            <IconButton onClick={closeAside} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        {!isResidentPage && (
          <CustomButton
            style={{ boxShadow: 'none' }}
            className={classes.showBtn}
            onClick={() => setEventModal(true)}
            size='small'
            label='Edit'
            Icon={EditIcon}
            disabled={isViewAdmin}
          />
        )}
        <div className={classes.headerRow} style={{ paddingRight: 5 }}>
          {integration_isactive && integration_id && (
            <Button
              color='primary'
              component={MuiLink}
              underline='none'
              href={`https://usnpint.pointclickcare.com/admin/client/cp_residentdashboard.jsp?ESOLclientid=${integration_id}`}
              target='_blank'
              rel='noopener noreferrer'
              style={{ textTransform: 'none' }}
              startIcon={<LaunchIcon />}
            >
              PointClickCare
            </Button>
          )}
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={data}
          form='editResident'
          isEdit
          refresh={refresh}
        />
      )}
    </div>
  );
};

const BulkActionButtons = ({
  toggleAside,
  residentId,
  queryId,
  residentData,
  ...props
}) => {
  const redirect = useRedirect();
  const { selectedIds = [] } = props;
  function onClick() {
    if (
      typeof toggleAside === 'function' &&
      selectedIds.indexOf(residentId) > -1
    ) {
      toggleAside(false);
      if (queryId) {
        redirect('list', '/residents-list');
      }
    }
    const residentIds = Object.values(residentData)
      ?.filter(row => selectedIds.includes(row.id))
      .map(row => row.resident_id);
    if (residentIds.length) {
      selectedIds.length = 0;
      selectedIds.push(...residentIds);
    }
  }
  return <BulkDeleteButton {...props} onClick={onClick} />;
};

const ListView = ({
  rowClick,
  listRowStyle,
  residentId,
  division,
  insuranceTab,
  empty,
  setResidentData,
  ...props
}) => {
  const isSidebarOpen = useSidebarOpen();
  const classes = useStyles({ isSidebarOpen });
  const { data } = props;
  setResidentData(data);
  return (
    <div className={classes.listContainer}>
      <Datagrid
        {...props}
        rowClick={rowClick}
        rowStyle={(record, index) => listRowStyle(record, index, residentId)}
      >
        <FunctionField
          label='Status'
          source='resident_status'
          className={classes.noWrap}
          headerClassName={classes.noWrap}
          render={record => {
            const { appeal_status } = record;
            return (
              <span>
                <ChipField
                  record={record}
                  source='resident_status'
                  className={clsx(
                    classes.chip,
                    classes.smallStatusChip,
                    classes[getChip(record.resident_status)],
                  )}
                />
                {appeal_status ? (
                  <Tooltip title={`Appeal ${appeal_status}`} placement='right'>
                    <InfoIcon className={classes.info} htmlColor='#F14FB0' />
                  </Tooltip>
                ) : null}
              </span>
            );
          }}
        />
        <FunctionField
          source='full_name'
          label='Resident name'
          sortBy='last_name'
          render={record => {
            const { full_name, case_id } = record;
            if (!full_name || !case_id) {
              return full_name;
            }
            return (
              <Button
                className={classes.referenceBtn}
                color='primary'
                onClick={e => e.stopPropagation()}
                component={Link}
                to={`/cases/${case_id}/timeline`}
              >
                {full_name}
              </Button>
            );
          }}
        />
        <FunctionField
          source='admit_date'
          label='Last admit'
          className={classes.noWrap}
          headerClassName={classes.noWrap}
          render={record => (
            <DateField
              record={record}
              source={record.last_admit_date ? 'last_admit_date' : 'admit_date'}
            />
          )}
        />
        <TextField
          source='payer_name'
          label={
            (division === 'mltc' ? insuranceTab.label : 'Primary') + ' payer'
          }
          className={classes.noWrap}
          headerClassName={classes.noWrap}
        />
        <TextField
          source='payer_type'
          label='Type'
          className={classes.noWrap}
          headerClassName={classes.noWrap}
        />
        <FunctionField
          label='Rate type'
          source='authorization_type'
          className={classes.noWrap}
          headerClassName={classes.noWrap}
          render={record => {
            const {
              level_one_reason_id,
              authorization_type,
              sub_level,
            } = record;
            return (
              <span>
                <div
                  className={classes.noWrap}
                  headerClassName={classes.noWrap}
                >
                  {sub_level
                    ? `${authorization_type} - ${sub_level}`
                    : authorization_type}
                </div>
                {level_one_reason_id ? (
                  <Tooltip
                    title={
                      <ReferenceField
                        label='Reason for Level 1 auth'
                        source='level_one_reason_id'
                        reference='level-one-reasons/list'
                        textAlign='right'
                        link={false}
                      >
                        <TextField source='name' />
                      </ReferenceField>
                    }
                    placement='right'
                  >
                    <InfoIcon className={classes.info} htmlColor='#66afdb' />
                  </Tooltip>
                ) : null}
              </span>
            );
          }}
        />
        <FunctionField
          source='auth_through'
          label='Auth thru'
          className={classes.noWrap}
          headerClassName={classes.noWrap}
          render={record => {
            const { auth_through, ending_soon } = record;
            const date =
              auth_through && auth_through !== null
                ? format(new Date(`${auth_through} 00:00`), 'M/d/yyyy')
                : null;
            const daysLeft =
              auth_through && auth_through !== null
                ? differenceInDays(
                    new Date(`${auth_through} 00:00`),
                    Date.now(),
                  )
                : null;
            return (
              <div className={classes.infoContainer}>
                <span style={{ marginRight: 3 }}>{date}</span>
                {ending_soon ? (
                  <Tooltip
                    title={
                      daysLeft >= 0
                        ? `Ending ${
                            daysLeft === 0 ? 'tomorrow' : `in ${daysLeft} days`
                          }`
                        : `${-daysLeft} days overdue`
                    }
                    placement='right'
                  >
                    <InfoIcon
                      className={classes.info}
                      htmlColor={
                        daysLeft >= 0
                          ? '#1061A0'
                          : daysLeft >= -5
                          ? 'rgba(245, 191, 79)'
                          : 'red'
                      }
                    />
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            );
          }}
        />
        <FunctionField
          source='next_update'
          label='Next update'
          sortable={false}
          className={classes.noWrap}
          headerClassName={classes.noWrap}
          render={record => {
            const { next_update } = record;
            const date = next_update ? new Date(next_update) : undefined;
            return (
              <div className={classes.infoContainer}>
                <span style={{ marginRight: 3 }}>{next_update}</span>
                {isValid(date) && !isFuture(date) ? (
                  <Tooltip
                    title={isToday(date) ? 'Due today' : 'Overdue'}
                    placement='right'
                  >
                    <InfoIcon
                      className={classes.info}
                      color={isToday(date) ? 'primary' : 'error'}
                    />
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            );
          }}
        />
        <TextField
          source='rep_name'
          label='Insurance case manager name'
          className={classes.noWrap}
          headerClassName={classes.noWrap}
        />
        <FunctionField
          source='rep_phone'
          label='Insurance case manager phone'
          headerClassName={classes.noWrap}
          render={record => {
            const ext = record.phone_ext ? record.phone_ext : '';
            return record.rep_phone
              ? `${normalizePhone(record.rep_phone)} ${ext}`
              : null;
          }}
        />
        <FunctionField
          source='rep_fax'
          label='Insurance case manager fax'
          className={classes.noWrap}
          headerClassName={classes.noWrap}
          render={record => (
            <div className={classes.noWrap}>
              {normalizePhone(record.rep_fax)}
            </div>
          )}
        />
      </Datagrid>
    </div>
  );
};

const caseRowClick = (id, basePath, record) => {
  return `/cases/${id}/timeline`;
};

export const ResidentList = props => {
  const classes = useStyles();
  const userDivision = useGetUserDivision();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { location: { search } = {} } = props;
  const { resident_id: queryId } = qs.parse(search);
  const [asideOpen, toggleAside] = useState(false);
  const [residentId, setResidentId] = useState();
  const persistedDivision = localStorage.getItem('residentsDivision');
  const persistedInsuranceTab = localStorage.getItem('residentsInsurance');
  const persistedFilters = localStorage.getItem('residentsFilters');
  const persistedCaseManager = localStorage.getItem('residentsCaseManager');
  const [persist, setPersist] = useState(!!persistedDivision);
  const [division, setDivision] = useState(
    queryId ? 'all' : persistedDivision || userDivision,
  );
  const [insuranceTab, setInsuranceTab] = useState(
    insuranceTabs[persistedInsuranceTab] || insuranceTabs.primary,
  );
  const [residentData, setResidentData] = useState();
  const [caseManager, setCaseManager] = useState(
    !isNaN(parseInt(persistedCaseManager))
      ? parseInt(persistedCaseManager)
      : undefined,
  );
  const persistedActiveFilters = persistedFilters
    ? Object.fromEntries(
        persistedFilters?.split(',').map(row => row.split(':')),
      )
    : undefined;
  const subacuteActiveStatusFilters = {
    '1': 'Active',
    '17': 'Denied Subacute',
    '19': 'Denied Subacute/MLTC',
    '10': 'Unknown',
  };
  const mltcActiveStatusFilters = {
    '1': 'Active',
    '18': 'Denied MLTC',
    '19': 'Denied Subacute/MLTC',
    '10': 'Unknown',
  };
  const allActiveStatusFilters = {
    '1': 'Active',
    '17': 'Denied Subacute',
    '18': 'Denied MLTC',
    '19': 'Denied Subacute/MLTC',
    '10': 'Unknown',
  };
  const [activeStatusFilters, setActiveStatusFilters] = useState(
    persistedActiveFilters ||
      (division === 'subacute'
        ? subacuteActiveStatusFilters
        : division === 'mltc'
        ? mltcActiveStatusFilters
        : allActiveStatusFilters),
  );

  const setInsurance = value => {
    setInsuranceTab(insuranceTabs[value]);
    toggleAside(false);
  };

  const rowClick = (id, basePath, record) => {
    setResidentId(record.resident_id);
    toggleAside(true);
    return null;
  };
  useEffect(() => {
    setActiveStatusFilters(
      division === 'subacute'
        ? subacuteActiveStatusFilters
        : division === 'mltc'
        ? mltcActiveStatusFilters
        : allActiveStatusFilters,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [division]);

  useEffect(() => {
    if (queryId && residentId !== queryId) {
      setResidentId(parseInt(queryId));
      setInsuranceTab(insuranceTabs.primary);
      toggleAside(true);
      setActiveStatusFilters({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryId, residentId]);

  const unselectAll = useUnselectAll('residents-list');
  const unselectAllSecondary = useUnselectAll('residents-secondary');
  useEffect(() => {
    return () => {
      unselectAll();
      unselectAllSecondary();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterActions = useCallback(({ actionType, id, name }) => {
    if (actionType === 'removeFilter') {
      setActiveStatusFilters(currentFilters => {
        const { [id]: filterToRemove, ...otherFilters } = currentFilters;
        return otherFilters;
      });
    } else if (actionType === 'addFilter') {
      setActiveStatusFilters(currentFilters => ({
        ...currentFilters,
        [id]: name,
      }));
    }
  }, []);

  const filterManager = useCallback(value => {
    toggleAside(false);
    setCaseManager(value);
  }, []);

  const handleSetDivision = useCallback(
    value => {
      if (value !== 'all' && queryId) {
        updatePageQueryParams({ resident_id: null });
      }
      setInsuranceTab(insuranceTabs.primary);
      setDivision(value);
      toggleAside(false);
    },
    [queryId, updatePageQueryParams],
  );

  const listRowStyle = (record, index, activeId) => {
    if (record && record.id === activeId && asideOpen) {
      return { backgroundColor: '#EFF4FB' };
    }
    if (record && !record.auth_through && !record.authorization_type) {
      if (
        (insuranceTab.id === 'primary' &&
          !noAuthPayersArray.includes(record.primary_payer_id)) ||
        (insuranceTab.id === 'secondary' &&
          !noAuthPayersArray.includes(record.secondary_payer_id))
      )
        return {
          backgroundColor: 'rgba(249,	233, 234)',
        };
    }
  };

  const { permissions = '' } = props;
  const userPermissions = permissions.split(',');
  const redirect = useRedirect();
  /* TODO: create whitelist option to block all 
  other routes and keep user from getting into 
  screen in the first place */
  useEffect(() => {
    if (userPermissions.indexOf('contract_user') > -1) {
      redirect('/payers-facility');
    }
  }, [userPermissions, redirect]);
  if (userPermissions.indexOf('contract_user') > -1) return null;
  const isAdminOrSupervisor =
    userPermissions?.indexOf('admin') > -1 ||
    userPermissions?.indexOf('supervisor') > -1;
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  /* TODO: create whitelist option to block all 
  other routes and keep user from getting into 
  screen in the first place */

  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={exporter}
        bulkActionButtons={
          Array.isArray(userPermissions) && isAdminOrSupervisor ? (
            <BulkActionButtons
              toggleAside={toggleAside}
              residentId={residentId}
              queryId={queryId}
              residentData={residentData}
            />
          ) : (
            false
          )
        }
        className={clsx(classes.list, {
          [classes.listWithDrawer]: asideOpen,
        })}
        actions={
          <ResidentListActions
            setDivision={handleSetDivision}
            division={division}
            filterActions={filterActions}
            activeStatusFilters={activeStatusFilters}
            insuranceTab={insuranceTab}
            setInsuranceTab={setInsurance}
            search={search}
            filterManager={filterManager}
            caseManager={caseManager}
            queryId={queryId}
            persist={persist}
            setPersist={setPersist}
            persistedDivision={persistedDivision}
          />
        }
        filter={{
          division: queryId ? 'all' : division,
          resident_id: queryId || undefined,
          resident_status: Object.keys(activeStatusFilters),
          insurance_type: division === 'mltc' ? insuranceTab.id : undefined,
          caseManagerId: caseManager,
        }}
        sort={{
          field: 'default',
          order: 'ASC',
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <ListView
          empty={false}
          key={insuranceTab.id} // needed to force re-render to change payer label
          rowClick={rowClick}
          listRowStyle={listRowStyle}
          residentId={residentId}
          division={division}
          insuranceTab={insuranceTab}
          setResidentData={setResidentData}
        />
      </List>
      <Drawer
        variant='persistent'
        open={asideOpen}
        anchor='right'
        elevation={16}
        PaperProps={{ square: true }}
        classes={{
          paperAnchorDockedRight: clsx(classes.aside),
        }}
      >
        {asideOpen ? (
          <ResidentShow
            {...props}
            id={residentId}
            closeAside={() => toggleAside(false)}
            includeCases
            insuranceTab={insuranceTab}
            isViewAdmin={isViewAdmin}
          />
        ) : null}
      </Drawer>
    </Fragment>
  );
};

const ResidentShow = ({
  closeAside,
  includeCases,
  resource,
  isResidentPage = true,
  insuranceTab = insuranceTabs.primary,
  isViewAdmin,
  ...props
}) => {
  const classes = useStyles();
  const showProps = useShowController({
    ...props,
    resource: insuranceTab.resource,
  });
  const { loaded } = showProps;
  if (!loaded) {
    return <Loading />;
  }
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <ResidentShowActions
            closeAside={closeAside}
            isResidentPage={isResidentPage}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <ResidentShowFields
          classes={classes}
          includeCases={includeCases}
          insuranceTab={insuranceTab}
        />
      </ShowView>
    </div>
  );
};

export const ResidentShowFields = ({
  classes,
  includeCases,
  isPrimary = true,
  insuranceTab,
  ...props
}) => {
  const { record = {} } = props;
  const payerLabel =
    (insuranceTab?.label ?? (isPrimary ? 'Primary' : 'Secondary')) + ' payer';
  return (
    <SimpleShowLayout className={clsx(classes.showLayout)} {...props}>
      <TextField
        source='full_name'
        className={classes.showItem}
        label='Resident name'
        textAlign='right'
      />
      <DateField
        source='dob'
        className={classes.showItem}
        label='DOB'
        textAlign='right'
      />
      <TextField
        source='gender'
        label='Gender'
        textAlign='right'
        className={classes.showItem}
      />
      <TextField
        source='facility_resident_id'
        className={classes.showItem}
        label='Resident ID'
        textAlign='right'
      />
      <TextField
        source='medicaid_id'
        className={classes.showItem}
        label='MCD #'
        textAlign='right'
      />
      <TextField
        source='medicare_id'
        className={classes.showItem}
        label='MCR #'
        textAlign='right'
      />
      <TextField
        source='room_number'
        className={classes.showItem}
        label='Room #'
        textAlign='right'
      />
      <DateField
        source='original_admit_date'
        className={classes.showItem}
        label='Orig admit date'
        textAlign='right'
      />
      {record.last_admit_date ? (
        <DateField
          source='last_admit_date'
          className={classes.showItem}
          label='Last admit date'
          textAlign='right'
        />
      ) : (
        <DateField
          source='admit_date'
          className={classes.showItem}
          label='Last admit date'
          textAlign='right'
        />
      )}
      {record?.tracking_date !== record?.admit_date && (
        <DateField
          source='tracking_date'
          className={classes.showItem}
          label={`${
            record?.tracking_reason
              ? record?.tracking_reason
                  ?.replace(/_/g, ' ')
                  .split()
                  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
              : 'Change plan start'
          }
              date`}
          textAlign='right'
        />
      )}
      <DateField
        source='expired_date'
        className={classes.showItem}
        label='Expired date'
        textAlign='right'
      />
      <DateField
        source='last_flu_shot'
        className={classes.showItem}
        label='Flu shot'
        textAlign='right'
      />
      <FunctionField
        source='flu_shot_status'
        className={classes.showItem}
        label='Flu shot status'
        textAlign='right'
        render={record => startCase(record.flu_shot_status)}
      />
      <DateField
        source='last_pne_shot'
        className={classes.showItem}
        label='PNA shot'
        textAlign='right'
      />
      <FunctionField
        source='pne_shot_status'
        className={classes.showItem}
        label='PNA shot status'
        textAlign='right'
        render={record => startCase(record.pne_shot_status)}
      />
      <FunctionField
        label='Length of stay'
        className={classes.showItem}
        render={record => `${record.length_of_stay} Days`}
      />
      <TextField
        source='payer_name'
        label={payerLabel}
        className={classes.showItem}
      />
      <TextField
        source='residents_insurance_id'
        label='Member ID'
        className={classes.showItem}
      />
      <TextField
        source='rep_name'
        label='Insurance case manager name'
        className={classes.showItem}
      />
      <FunctionField
        source='rep_phone'
        label='Insurance case manager phone'
        className={classes.showItem}
        render={record => {
          const ext = record.phone_ext ? record.phone_ext : '';
          return record.rep_phone
            ? `${normalizePhone(record.rep_phone)} ${ext}`
            : null;
        }}
      />
      <FunctionField
        source='rep_fax'
        label='Insurance case manager fax'
        className={classes.showItem}
        render={record => <div>{normalizePhone(record.rep_fax)}</div>}
      />
      <FunctionField
        source='vResidentCreatedBy.name'
        label='Created by'
        className={classes.showItem}
        render={record => {
          const { vResidentCreatedBy, created_at } = record;
          return (
            <TrackUser userField={vResidentCreatedBy} timestamp={created_at} />
          );
        }}
      />
      <FunctionField
        source='vResidentUpdatedBy.name'
        label='Last modified'
        className={classes.showItem}
        render={record => {
          const { vResidentUpdatedBy, updated_at } = record;
          return (
            <TrackUser userField={vResidentUpdatedBy} timestamp={updated_at} />
          );
        }}
      />
      {/* needed for spacing */}
      <div className={classes.showItem} />
      {includeCases && <p className={classes.casesTitle}>Cases</p>}
      {includeCases && (
        <ReferenceManyField
          addLabel={false}
          reference='cases'
          target='resident_id'
          source='id'
          className={classes.caseListTable}
          sort={{ field: 'admit_date', order: 'DESC' }}
          filter={{
            all: true,
          }}
        >
          <Datagrid rowClick={caseRowClick}>
            <TextField source='case_name' label='Case name' />
            <FunctionField
              label='Case status'
              source='case_status'
              headerClassName={classes.noWrap}
              render={record => (
                <ChipField
                  record={record}
                  source='case_status'
                  className={clsx(
                    classes.chip,
                    classes[getChip(record.case_status)],
                  )}
                />
              )}
            />
          </Datagrid>
        </ReferenceManyField>
      )}
    </SimpleShowLayout>
  );
};

export const residentResource = {
  name: 'residents-list',
  list: ResidentList,
  show: ResidentShow,
  icon: PeopleIcon,
  options: { label: 'Residents' },
};

const InsuranceTabs = ({ setInsuranceTab, insuranceTab, classes, search }) => {
  const { resident_id: queryId } = qs.parse(search);
  //Is this still needed? The queryId hook seems to be duplicated in ResidentList and the Tabs.value can always be insuranceTab.id
  const [value, setValue] = useState(insuranceTab.id);

  // need to set to primary if searching while on the residents page
  // with the secondary tab selected
  useEffect(() => {
    if (queryId) {
      setValue(0);
    }
  }, [queryId]);

  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue); //why is this needed? Shouldn't it trickle down from the List insuranceTab?
      setInsuranceTab(newValue);
    },
    [setInsuranceTab],
  );

  return (
    <Paper className={classes.tabContainer} square>
      <Tabs
        value={value}
        indicatorColor='primary'
        textColor='inherit'
        onChange={handleChange}
      >
        {Object.values(insuranceTabs).map(tab => (
          <Tab
            label={tab.label}
            value={tab.id}
            classes={{ root: classes.tab, selected: classes.selectedTab }}
          />
        ))}
      </Tabs>
    </Paper>
  );
};

const filters = [
  { id: '1', name: 'Active' },
  { id: '2', name: 'Denied' },
  { id: '3', name: 'Discharged community' },
  { id: '4', name: 'Discharged hospital' },
  { id: '5', name: 'Discharged another SNF' },
  { id: '12', name: 'Discharged ALF' },
  { id: '13', name: 'Discharged shelter' },
  { id: '14', name: 'Discharged AMA' },
  { id: '15', name: 'Discharged other' },
  { id: '6', name: 'Leave of absence' },
  { id: '7', name: 'Expired' },
  { id: '8', name: 'Medicaid pending' },
  { id: '9', name: 'Payer changed' },
  { id: '10', name: 'Unknown' },
  { id: '11', name: 'Untracked' },
  { id: '17', name: 'Denied Subacute' },
  { id: '18', name: 'Denied MLTC' },
  { id: '19', name: 'Denied Subacute/MLTC' },
];

const insuranceTabs = {
  primary: { id: 'primary', label: 'Primary', resource: 'residents' },
  secondary: {
    id: 'secondary',
    label: 'Secondary',
    resource: 'residents-secondary',
  },
};
