import React from 'react';
import { CaseAdmissionShow } from './CaseAdmissionShow';
import { CaseAppealShow } from './CaseAppealShow';
import { CaseAuthShow } from './CaseAuthShow';
import { CaseBedholdShow } from './CaseBedholdShow';
import { CaseDenialShow } from './CaseDenialShow';
import { CaseDischargeShow } from './CaseDischargeShow';
import { CasePayerChangeShow } from './CasePayerChangeShow';
import { CaseMltcPayerShow } from './CaseMltcPayerShow';

export const showResources = {
  'Denial Received': { resource: 'denials', show: <CaseDenialShow /> },
  'Denial Resolved': { resource: 'denials', show: <CaseDenialShow /> },
  'Kepro Appeal': { resource: 'appeals', show: <CaseAppealShow /> },
  'Peer to Peer Appeal': {
    resource: 'appeals',
    show: <CaseAppealShow />,
  },
  'Livanta Appeal': { resource: 'appeals', show: <CaseAppealShow /> },
  'Claim Appeal Appeal': { resource: 'appeals', show: <CaseAppealShow /> },
  'Internal Appeal': { resource: 'appeals', show: <CaseAppealShow /> },
  'External Appeal': { resource: 'appeals', show: <CaseAppealShow /> },
  'Skilled Auth Appeal': {
    resource: 'appeals',
    show: <CaseAppealShow />,
  },
  'ALOC Auth Appeal': { resource: 'appeals', show: <CaseAppealShow /> },
  'Custodial Auth Appeal': {
    resource: 'appeals',
    show: <CaseAppealShow />,
  },
  'Co-Insurance/Copay Auth Appeal': {
    resource: 'appeals',
    show: <CaseAppealShow />,
  },
  'Level Change Appeal': {
    resource: 'appeals',
    show: <CaseAppealShow />,
  },
  'Carve Out Appeal': { resource: 'appeals', show: <CaseAppealShow /> },
  'Retro Auth Appeal': { resource: 'appeals', show: <CaseAppealShow /> },
  'Other Appeal': { resource: 'appeals', show: <CaseAppealShow /> },
  'Fair Hearing Appeal': {
    resource: 'appeals',
    show: <CaseAppealShow />,
  },
  'DOH Appeal': { resource: 'appeals', show: <CaseAppealShow /> },
  'DFS Appeal': { resource: 'appeals', show: <CaseAppealShow /> },
  Discharge: { resource: 'discharge', show: <CaseDischargeShow /> },
  'Planned Discharge': {
    resource: 'discharge',
    show: <CaseDischargeShow />,
  },
  'Discharge - Readmitted': {
    resource: 'discharge',
    show: <CaseDischargeShow />,
  },
  Bedhold: { resource: 'bedholds', show: <CaseBedholdShow /> },
  Authorization: { resource: 'authorizations', show: <CaseAuthShow /> },
  AncillaryAuthorization: {
    resource: 'ancillary-authorizations',
    show: <CaseAuthShow />,
  },
  'Payer Change': {
    resource: 'payer-change',
    show: <CasePayerChangeShow />,
  },
  Admission: { resource: 'admissions', show: <CaseAdmissionShow /> },
  Readmission: { resource: 'admissions', show: <CaseAdmissionShow /> },
  'MLTC Payer Change': {
    resource: 'mltc-payer',
    show: <CaseMltcPayerShow />,
  },
  'Pickup of skilled date': {
    resource: 'admissions',
    show: <CaseAdmissionShow />,
  },
  'Pickup of plan date': {
    resource: 'admissions',
    show: <CaseAdmissionShow />,
  },
  'New plan enrollment date': {
    resource: 'admissions',
    show: <CaseAdmissionShow />,
  },
};
