import React, {
  useCallback,
  useState,
  useEffect,
  isValidElement,
  cloneElement,
} from 'react';
import { useRedirect, useNotify, usePermissions } from 'react-admin';
import { useSelector } from 'react-redux';
import {
  Toolbar,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Link,
} from '@material-ui/core';
import { useGetCaseId } from '../../hooks';
import { ProgressList } from './progressPage';
import { PccDiagnosisList } from './pccDiagnosisPage';
import { DiagnosisList } from './diagnosisPage';
import { TherapyList } from './therapyPage';
import { MedicationList } from './medicationPage';
import { PharmList } from '../../resources/pharmLog';
import { ProgressNotesList } from './progressNotesPage';
import { LaunchIcon, CloseIcon, CustomButton } from '../../design';
import { Rates } from '../../resources/payers/rates';
import { authGet } from '../../server';
import { useStyles } from './clinical.styles';
export function ClinicalPage(props) {
  const {
    location: { pathname },
  } = props;
  const parts = pathname.split('/');
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();
  const [position /*, setPosition */] = useState(parts[4]);
  const [integrationData, setIntegrationData] = useState({});
  const [showContract, setShowContract] = useState(false);
  const caseId = useGetCaseId();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  const facilityId = useSelector(state => state.facility.id);
  const [facilityIsIntegrated, setFacilityIsIntegrated] = useState();
  const facilityIntegrationKnown = facilityIsIntegrated !== undefined;

  useEffect(() => {
    if (facilityId !== undefined && !facilityIntegrationKnown) {
      authGet(`/facilities/${facilityId}`).then(response => {
        const {
          integration_id,
          integration_isactive,
          integration_type,
        } = response.data;
        const isIntegrated =
          integration_id && integration_isactive && integration_type;
        setFacilityIsIntegrated(isIntegrated);
      });
    }
  }, [facilityId, facilityIntegrationKnown, caseId]);

  useEffect(() => {
    (async function() {
      if (caseId) {
        const response = await authGet(`/cases/${caseId}/integration-data`);
        const { data, error } = response;
        if (error) {
          return notify(error.message, 'warning');
        }
        setIntegrationData(data);
      }
    })();
  }, [caseId, notify]);

  const handleChange = useCallback(
    (event, newValue) => {
      // setPosition(newValue);
      redirect(`/cases/${caseId}/clinical/${newValue}`);
    },
    [caseId, redirect],
  );

  const component =
    resources[position]?.component || resources.progress.component;

  return (
    <div>
      <Toolbar disableGutters>
        <div className={classes.toolbarContent}>
          <div className={classes.titleContainer}>
            <div className={classes.topContent}>
              <span className={classes.title}>Clinical</span>
              <span style={{ marginLeft: 10 }}>
                {integrationData?.payer_name}
              </span>
              {!!integrationData?.payer_facility_id && (
                <Button
                  style={{ textTransform: 'capitalize' }}
                  color='primary'
                  onClick={() => setShowContract(true)}
                >
                  View contract
                </Button>
              )}{' '}
            </div>
            {!!integrationData?.integration_id && (
              <Button
                color='primary'
                component={Link}
                underline='none'
                href={`https://usnpint.pointclickcare.com/clinical/pho/reports/administrationReport.xhtml?action=setupReport&client_id_number=${integrationData.integration_id}`}
                target='_blank'
                rel='noopener noreferrer'
                style={{ textTransform: 'none' }}
                startIcon={<LaunchIcon />}
              >
                View report in PointClickCare
              </Button>
            )}
          </div>
          <div className={classes.tabContainer}>
            <Tabs
              value={position}
              indicatorColor='primary'
              textColor='inherit'
              onChange={handleChange}
              centered
            >
              <Tab
                label='Progress'
                classes={{
                  root: classes.tab,
                  selected: classes.selectedTab,
                }}
                value='progress'
              />
              {facilityIsIntegrated ? (
                <Tab
                  label='Diagnosis'
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                  value='diagnosis'
                />
              ) : (
                <Tab
                  label='Diagnosis'
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                  value='manual-diagnosis'
                />
              )}
              <Tab
                label='Therapy'
                classes={{
                  root: classes.tab,
                  selected: classes.selectedTab,
                }}
                value='therapy'
              />
              {facilityIsIntegrated ? (
                <Tab
                  label='Medication'
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                  value='medication'
                />
              ) : (
                <Tab
                  label='Medication'
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                  value='pharm-log'
                />
              )}
              {facilityIsIntegrated && (
                <Tab
                  label='Progress Notes'
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                  value='progress-notes'
                />
              )}
            </Tabs>
          </div>
        </div>
      </Toolbar>
      {isValidElement(component) &&
        cloneElement(component, {
          ...props,
          integration_id: integrationData?.integration_id,
          isViewAdmin: isViewAdmin,
        })}
      {showContract && (
        <ContractModal
          open={showContract}
          handleClose={() => setShowContract(false)}
          payerFacilityId={integrationData?.payer_facility_id}
          lob={integrationData?.payer_type}
          {...props}
        />
      )}
    </div>
  );
}

const ContractModal = ({
  payerFacilityId,
  lob,
  open,
  handleClose,
  ...props
}) => {
  // const notify = useNotify();
  // const [contract, setContract] = useState();

  // for react-admin
  const ratesProps = {
    ...props,
    resource: 'payers-facility/rates',
    options: { label: 'Rates ' },
    basePath: '/payers-facility',
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: true,
  };

  // useEffect(() => {
  //   (async function() {
  //     if (payerFacilityId) {
  //       const response = await authGet(
  //         `/cases/${payerFacilityId}/integration-data`,
  //       );
  //       const { data, error } = response;
  //       if (error) {
  //         return notify(error.message, 'warning');
  //       }
  //       setContract(data);
  //     }
  //   })();
  // }, [payerFacilityId, notify]);

  if (!payerFacilityId) return null;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      maxWidth='lg'
      fullWidth
      disableScrollLock
    >
      <DialogContent>
        <Rates
          id={payerFacilityId}
          lob={lob}
          hideBulkActionButtons
          {...ratesProps}
          showContract={true}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton
          Icon={CloseIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Close'
          type='button'
          variant='text'
          size='small'
          onClick={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

const resources = {
  progress: { component: <ProgressList /> },
  diagnosis: { component: <PccDiagnosisList /> },
  'manual-diagnosis': { component: <DiagnosisList /> },
  therapy: {
    component: <TherapyList />,
  },
  medication: { component: <MedicationList /> },
  'pharm-log': { component: <PharmList /> },
  'progress-notes': { component: <ProgressNotesList /> },
};
