import React, { useState, useEffect, Fragment } from 'react';
import {
  useListController,
  ListView,
  TextField,
  TopToolbar,
  ReferenceField,
  FunctionField,
  useRefresh,
  Pagination,
  useUnselectAll,
} from 'react-admin';
import { Datagrid, Loading } from '../../components/common';
import { NewEvent } from '../../components/common/modals';
import { useStyles } from './payers.styles';
import { AddIcon, EditIcon, CustomButton } from '../../design';
import { IconButton } from '@material-ui/core';

const ListActions = ({ payer_facility_id, permissions }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span />
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Qualifying criteria'
          type='button'
          variant='text'
          size='small'
          onClick={() => setEventModal(true)}
        />
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='qualifyingCriteria'
          refresh={refresh}
          payerFacilityId={parseInt(payer_facility_id)}
        />
      )}
    </Fragment>
  );
};

export const QualifyingCriteria = ({
  record,
  permissions = [],
  id,
  hideBulkActionButtons,
  isViewAdmin,
  ...props
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [rateRecord, setRateRecord] = useState();
  const [cloneRecord, setCloneRecord] = useState();
  const [cloneOpen, setCloneOpen] = useState(false);
  useEffect(() => {
    // see comment on case_communication
    if (rateRecord) {
      setEventModal(true);
    }
  }, [rateRecord]);

  const unselectAll = useUnselectAll('payers-facility/rates-services');
  useEffect(() => {
    return () => {
      unselectAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setEventModal(false);
    setRateRecord(undefined);
  };

  const controllerProps = useListController({
    ...props,
    resource: 'payers-facility/rates-services',
    filter: { id },
  });

  const openClone = record => {
    setCloneRecord(record);
    setCloneOpen(true);
  };
  const closeClone = record => {
    setCloneRecord(undefined);
    setCloneOpen(false);
  };
  const { loading } = controllerProps;
  return loading ? (
    <Loading />
  ) : (
    <div className='show-list'>
      <ListView
        empty={false}
        {...controllerProps}
        exporter={false}
        actions={
          Array.isArray(permissions) &&
          (permissions.indexOf('admin') > -1 ||
            permissions.indexOf('supervisor') > -1 ||
            permissions.indexOf('contract_user') > -1) ? (
            <ListActions payer_facility_id={id} />
          ) : null
        }
        bulkActionButtons={
          isViewAdmin ? false : hideBulkActionButtons ? false : undefined
        }
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
        // filters={<FiltersView classes={classes} />}
      >
        <Datagrid>
          <ReferenceField
            source='rate.level'
            label='Level'
            headerClassName={classes.listItemLabel}
            reference='auth-types/list'
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>
          <TextField
            source='lines_of_business'
            label='Lines of business'
            headerClassName={classes.listItemLabel}
            sortable={false}
          />
          <TextField
            source='category.category'
            label='Category'
            headerClassName={classes.listItemLabel}
            sortable={false}
          />
          <TextField
            source='services'
            label='Qualifying services'
            headerClassName={classes.listItemLabel}
            sortable={false}
          />
          <TextField
            source='rules'
            label='Rules'
            headerClassName={classes.listItemLabel}
            sortable={false}
            //showMore field, like on notes.
          />
          <FunctionField
            source=''
            label=''
            sortable={false}
            render={record => {
              return Array.isArray(permissions) &&
                (permissions.indexOf('admin') > -1 ||
                  permissions.indexOf('supervisor') > -1 ||
                  permissions.indexOf('contract_user') > -1) ? (
                <IconButton
                  style={{ color: '#5C738E' }}
                  onClick={() => setRateRecord(record)}
                  size='small'
                >
                  <EditIcon />
                </IconButton>
              ) : null;
            }}
          />
          <FunctionField
            source=''
            label=''
            sortable={false}
            render={record => {
              return Array.isArray(permissions) &&
                (permissions.indexOf('admin') > -1 ||
                  permissions.indexOf('supervisor') > -1 ||
                  permissions.indexOf('contract_user') > -1) ? (
                <CustomButton
                  Icon={AddIcon}
                  color='#1061A0'
                  backgroundColor='#EFF4FB'
                  label='Copy details'
                  type='button'
                  variant='text'
                  size='small'
                  onClick={() => openClone(record)}
                />
              ) : null;
            }}
          />
        </Datagrid>
      </ListView>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={rateRecord}
          form='qualifyingCriteria'
          isEdit
          refresh={refresh}
          payerFacilityId={id}
        />
      )}
      {cloneOpen && (
        <NewEvent
          open={cloneOpen}
          handleClose={() => closeClone()}
          form='cloneQualifyingCriteria'
          refresh={refresh}
          payerFacilityId={parseInt(id)}
          record={cloneRecord}
        />
      )}
    </div>
  );
};
