import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import clsx from 'clsx';
import { isValid, isPast, isToday, addDays, format } from 'date-fns';
import qs from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  useRefresh,
  List,
  Pagination,
  TextField,
  FunctionField,
  DateField as AdminDateField,
  TopToolbar,
  // ReferenceField,
  useRedirect,
  ChipField,
  usePermissions,
} from 'react-admin';
import {
  Drawer,
  Button,
  Tab,
  Tabs,
  Paper,
  Chip,
  Menu,
  MenuItem,
  Fade,
} from '@material-ui/core';
import { CustomButton } from '../../design/material-ui';
import {
  ToggleButton,
  DateField,
  Datagrid,
  CaseManagerSelect,
  StatusFilter,
} from '../../components/common';
import { TaskForm, NewEvent } from '../../components/common/modals';
import {
  useGetTaskPermissions,
  useGetUserDivision,
  useGetUserRoles,
  useShowController,
} from '../../hooks';
import { useStyles } from './tasks.styles';
import { TasksAside } from './tasks_aside';
import {
  NotificationIcon,
  AddIcon,
  AttachmentIcon,
  DownloadIcon,
  ArrowDropDownIcon,
} from '../../design';
import { getChip } from '../../utils';
import { fileManagementActions } from '../../state/actions';
import { authGet } from '../../server';
const FacilityLevelActions = ({
  basePath,
  resource,
  setDivision,
  setStatus,
  rootBasePath,
  taskFilters = [],
  filterActions,
  loading,
  filterManager,
  caseManager,
  apiData,
  exportOpen,
  setExportOpen,
  data,
  isViewAdmin,
  ...rest
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [filters, setFilters] = useState([
    'my_tasks',
    'due_today',
    'overdue',
    'initial_evals',
    'auth_requests',
    'missing_auth',
    'denial_tasks',
    'retro_issue',
    'verification',
    'other_tasks',
    'ancillary_tasks',
  ]);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const addFilter = filterName => {
    filterActions({ actionType: 'addFilter', filterName });
    setAnchorEl(null);
  };

  useEffect(() => {
    const dataArray = Object.entries(data);
    if (dataArray.length > 0) {
      const dataFilters = dataArray
        .map(row => row[1]?.title?.toLowerCase()?.replace(/ /g, '_'))
        .filter(x => x !== undefined);

      const combined = [...new Set([...filters, ...dataFilters])].sort();
      setFilters(combined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <div style={{ display: 'flex', paddingBottom: 20 }}>
            <span className={classes.title}>Tasks</span>
            {/* Needed for spacing */}
            <span style={{ marginRight: 25 }} />
            <ToggleButton
              setDivision={setDivision}
              className={classes.toggleWrapper}
            />
          </div>
          <div>
            <CustomButton
              Icon={AddIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label='Add'
              type='button'
              variant='text'
              size='small'
              onClick={() => setEventModal(true)}
              disabled={isViewAdmin}
            />
            {'         '}
            <CustomButton
              Icon={DownloadIcon}
              backgroundColor='#EFF4FB'
              color='#1061A0'
              label='Export'
              type='button'
              variant='text'
              size='small'
              onClick={() => setExportOpen(true)}
            />
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <CaseManagerSelect
            filterManager={filterManager}
            initialValue={caseManager && [parseInt(caseManager)]}
          />
          <Button
            disableElevation
            onClick={handleClick}
            endIcon={<ArrowDropDownIcon />}
            className={classes.filterDropdown}
          >
            <span style={{ fontWeight: 300 }}> Filter</span>
          </Button>
          <Menu
            id='fade-menu'
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            {filters?.map(f => (
              <MenuItem key={f} onClick={_ => addFilter(f)}>
                {startCase(f.replace('_', ' '))}
              </MenuItem>
            ))}
          </Menu>
          {!!taskFilters.length && (
            <div
              style={{
                padding: 10,
              }}
            >
              <div style={{ display: 'flex' }}>
                {taskFilters.map(f => {
                  return (
                    <Chip
                      style={{
                        width: 'auto',
                        marginLeft: 15,
                        textTransform: 'capitalize',
                      }}
                      className={classes.chips}
                      key={f}
                      label={
                        typeof f === 'string' && startCase(f.replace('', '_'))
                      }
                      clickable
                      disabled={loading}
                      size='small'
                      onDelete={() =>
                        filterActions({
                          actionType: 'removeFilter',
                          filterName: f,
                        })
                      }
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <StatusTabs
          setStatus={setStatus}
          classes={classes}
          basePath={basePath}
          rootBasePath={rootBasePath}
        />
      </TopToolbar>
      {eventOpen && (
        <TaskForm
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const CaseLevelActions = ({
  basePath,
  setStatus,
  rootBasePath,
  resource,
  showAll,
  setShowAll,
  residentCaseCount,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <span className={classes.title}>Tasks</span>
          <div>
            <CustomButton
              Icon={AddIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label='Add'
              type='button'
              variant='text'
              size='small'
              onClick={() => setEventModal(true)}
              disabled={isViewAdmin}
            />
          </div>
        </div>
        <div style={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 3 }}>
          {residentCaseCount > 1 && (
            <StatusFilter setShowAll={setShowAll} showAll={showAll} />
          )}
        </div>
        <StatusTabs
          setStatus={setStatus}
          classes={classes}
          basePath={basePath}
          rootBasePath={rootBasePath}
        />
      </TopToolbar>
      {eventOpen && (
        <TaskForm
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

export const TasksList = ({ staticContext, ..._props }) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const userDivision = useGetUserDivision();
  const {
    is_admin,
    is_supervisor,
    is_case_manager,
    is_covering_user,
    allRoles,
  } = useGetUserRoles();
  const { rootBasePath, caseId, ...props } = getResourceProps(_props);
  const { basePath, location: { search } = {} } = props;
  const facilityLevel = props.resource === 'tasks';
  const classes = useStyles();
  const [division, setDivision] = useState(userDivision);
  const [taskStatus] = useState(
    basePath === `${rootBasePath}tasks/completed` ? 1 : 0,
  );
  const [eventFilter, setEventFilter] = useState();
  const [asideOpen, toggleAside] = useState(false);
  const [rowRecord, setRowRecord] = useState({});
  const [taskFilters, setTaskFilters] = useState();
  const [caseManager, setCaseManager] = useState();
  const [showAll, setShowAll] = useState(true);
  const residentCaseCount = useSelector(state =>
    get(state, 'ui.residentCaseCount', 0),
  );
  const [exportOpen, setExportOpen] = useState(false);
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  const firstRender = useRef(true);
  useEffect(() => {
    const {
      event_type,
      event_id,
      note_event_id,
      date_due,
      filters,
      'user-id': userId,
    } = qs.parse(search);
    if (date_due) {
      setEventFilter({ date: date_due });
    } else if (event_type && event_id) {
      setEventFilter({ event_id, event_type });
    } else if (note_event_id) {
      setEventFilter({ note_event_id });
    } else {
      setEventFilter({});
    }
    if (filters && filters.length) {
      let filterArray = [];
      try {
        filterArray = Array.from(new Set(JSON.parse(filters)));
      } catch (error) {
        setTaskFilters([]);
      }
      setTaskFilters(filterArray);
    } else {
      if (is_case_manager !== undefined) {
        setTaskFilters(
          (is_case_manager && allRoles?.length === 1) ||
            (allRoles?.length === 2 && is_covering_user && is_case_manager)
            ? ['my_tasks']
            : [],
        );
      }
    }
    if (userId) {
      setCaseManager(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_case_manager, search]);

  useEffect(() => {
    toggleAside(false);
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    refresh();
  }, [props.location.pathname, refresh]);

  const rowClick = (id, basePath, record) => {
    setRowRecord({
      ...record,
    });
    toggleAside(true);
    return null;
  };
  const listRowStyle = (record, index, activeId) => {
    if (record && record.id === activeId && asideOpen) {
      return { backgroundColor: '#EFF4FB' };
    } else if (caseId && parseInt(record?.case_id) !== parseInt(caseId)) {
      return { backgroundColor: '#EFF4FB', opacity: 0.5, fontStyle: 'italic' };
    }
  };

  const setStatus = value => {
    if (value === taskStatus) return;
    const { location: { search } = {} } = props;
    const path = value === 0 ? 'tasks/not-completed' : 'tasks/completed';
    const { date_due, ...queryParams } = qs.parse(search);
    redirect(`${rootBasePath}${path}?${qs.stringify({ ...queryParams })}`);
  };

  const apiData = {
    perPage: eventFilter && !!Object.keys(eventFilter).length ? 100 : undefined,
    sort: {
      field: taskStatus === 0 ? 'date_due' : 'completed_at',
      order: 'ASC',
    },
    filter: {
      division: facilityLevel ? division : undefined,
      is_completed: taskStatus === 1,
      taskFilters,
      caseManagerId: caseManager,
      all: showAll,
      ...eventFilter,
    },
  };

  const filterActions = ({ actionType, filterName }) => {
    if (actionType === 'removeFilter') {
      const _taskFilters = taskFilters.filter(f => f !== filterName);
      setTaskFilters(_taskFilters);
    } else if (actionType === 'addFilter') {
      setTaskFilters(curFilter =>
        Array.from(new Set([...curFilter, filterName])),
      );
    }
  };

  const filterManager = useCallback(value => {
    toggleAside(false);
    setCaseManager(value);
  }, []);

  const toggleShowAll = useCallback(value => {
    toggleAside(false);
    setShowAll(value);
  }, []);

  const Component = taskStatus === 0 ? NotCompletedList : CompletedList;
  return (
    <Fragment>
      <Component
        props={props}
        apiData={apiData}
        shouldFetchData={!!taskFilters && !!eventFilter}
        userPermissions={{ is_admin, is_supervisor }}
        toggleAside={toggleAside}
        rowRecord={rowRecord}
        asideOpen={asideOpen}
        facilityLevel={facilityLevel}
        setTaskStatus={setStatus}
        taskStatus={taskStatus}
        setDivision={setDivision}
        division={division}
        rowClick={rowClick}
        listRowStyle={listRowStyle}
        rootBasePath={rootBasePath}
        taskFilters={taskFilters}
        filterActions={filterActions}
        filterManager={filterManager}
        caseManager={caseManager}
        showAll={showAll}
        setShowAll={toggleShowAll}
        exportOpen={exportOpen}
        setExportOpen={setExportOpen}
        residentCaseCount={residentCaseCount}
        isViewAdmin={isViewAdmin}
      />
      <Drawer
        variant='persistent'
        open={asideOpen}
        anchor='right'
        elevation={16}
        PaperProps={{ square: true }}
        classes={{
          paperAnchorDockedRight: clsx(classes.aside),
        }}
      >
        {asideOpen ? (
          <TasksShow
            {...props}
            id={rowRecord.id}
            closeAside={() => toggleAside(false)}
            resource='tasks'
            isViewAdmin={isViewAdmin}
          />
        ) : null}
      </Drawer>
      {exportOpen && (
        <NewEvent
          open={exportOpen}
          handleClose={() => setExportOpen(false)}
          record={apiData}
          form='facilityTasksExportForm'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const NotCompletedList = ({
  props,
  apiData,
  userPermissions,
  shouldFetchData,
  toggleAside,
  rowRecord,
  asideOpen,
  facilityLevel,
  setTaskStatus,
  taskStatus,
  setDivision,
  division,
  rowClick,
  listRowStyle,
  rootBasePath,
  taskFilters,
  filterActions,
  filterManager,
  caseManager,
  showAll,
  setShowAll,
  exportOpen,
  setExportOpen,
  residentCaseCount,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const { canDeleteOwnTasks, canDeleteOthersTasks } = useGetTaskPermissions();
  const userId = useSelector(state => state.user.profile.id);
  const coveredUserId = useSelector(state => state.facility.covered_user_id);
  const dispatch = useDispatch();

  const fetchText = async (title, v_case, date_due) => {
    const levelMatch = title.substring(
      title.indexOf('for') + 3,
      title.indexOf('-'),
    );
    const clinicalMatch = title.substring(title.indexOf('-') + 1, title.length);
    const clinicalPhrase = clinicalMatch.split(',').join(' and ');
    let therapyInfo = '';
    if (
      clinicalMatch.includes('PT') ||
      clinicalMatch.includes('OT') ||
      clinicalMatch.includes('Speech') ||
      clinicalMatch.includes('Therapy')
    ) {
      const date = new Date(`${date_due} 00:00`);
      const response = await authGet([
        `cases/${v_case?.id}/case-therapy`,
        {
          start_date: format(addDays(date, -7), 'yyyy-MM-dd'),
          end_date: date_due,
          exact_dates: true,
        },
      ]);
      const { data } = response;
      if (data) {
        therapyInfo = `<br />The resident meets or exceeds the therapy requirements in the contract.  Here is the therapy received over the last 7 days:
        <br />
        <table style="border:1px solid; width:100%; border-collapse: collapse;">
        <tr style="border:1px solid">
        <td style="padding-right:10;border:1px solid">Date</td>
        <td style="padding-right:10;border:1px solid">PT</td>
        <td style="padding-right:10;border:1px solid">OT</td>
        <td style="padding-right:10;border:1px solid">Speech</td>
       </tr>`;
        data.map(row => {
          therapyInfo += `<tr style="border:1px solid">
        <td style="padding-right:10;border:1px solid">${row.date}</td>
        <td style="padding-right:10;border:1px solid">${
          row.pt_minutes ? row.pt_minutes : 0
        }</td>
        <td style="padding-right:10;border:1px solid">${
          row.ot_minutes ? row.ot_minutes : 0
        }</td>
        <td style="padding-right:10;border:1px solid">${
          row.slp_minutes ? row.slp_minutes : 0
        }</td>
       </tr>`;
          return row;
        });
        therapyInfo += `</table>`;
      }
    }
    const text = `Hello, <br />
            This is to inform you that ${v_case?.full_name} qualifies for ${levelMatch} as they match the clinical criteria for ${clinicalPhrase}. <br/>
            ${therapyInfo}
            Please contact me if you have any questions. <br />
            Thank you.`;
    dispatch(fileManagementActions.addText(text));
  };
  const { perPage, sort, filter } = apiData;
  if (!shouldFetchData) return null;
  return (
    <List
      empty={false}
      {...props}
      perPage={perPage}
      sort={sort}
      filter={filter}
      exporter={false}
      bulkActionButtons={
        false // disable delete
      }
      className={clsx(classes.list, {
        [classes.listWithDrawer]: asideOpen,
      })}
      actions={
        facilityLevel ? (
          <FacilityLevelActions
            setDivision={setDivision}
            setStatus={setTaskStatus}
            rootBasePath={rootBasePath}
            taskFilters={taskFilters}
            filterActions={filterActions}
            filterManager={filterManager}
            caseManager={caseManager}
            apiData={apiData}
            exportOpen={exportOpen}
            setExportOpen={setExportOpen}
            isViewAdmin={isViewAdmin}
          />
        ) : (
          <CaseLevelActions
            setStatus={setTaskStatus}
            rootBasePath={rootBasePath}
            showAll={showAll}
            setShowAll={setShowAll}
            residentCaseCount={residentCaseCount}
            isViewAdmin={isViewAdmin}
          />
        )
      }
      pagination={
        <Pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          style={{
            display: 'flex',
          }}
        />
      }
    >
      <Datagrid
        rowClick={rowClick}
        rowStyle={(record, index) => listRowStyle(record, index, rowRecord.id)}
        isRowSelectable={record => {
          const isMyTask =
            record && record.assigned_to_id === (coveredUserId || userId);
          return isMyTask ? canDeleteOwnTasks : canDeleteOthersTasks;
        }}
      >
        <FunctionField
          source='title'
          label='Task'
          render={record => {
            const { title, task_type: taskType } = record;
            const { task_type } = taskType || {};
            return title || task_type;
          }}
        />
        <FunctionField
          source='v_case.case_name'
          label='Case name'
          sortBy='last_name'
          render={record => {
            const { v_case: { case_name, id: case_id } = {} } = record;
            if (!case_name || !case_id) {
              return null;
            }
            return (
              <Button
                className={classes.referenceBtn}
                color='primary'
                onClick={e => e.stopPropagation()}
                component={Link}
                to={`/cases/${case_id}/timeline`}
              >
                {case_name}
              </Button>
            );
          }}
        />
        <FunctionField
          sortable={false}
          source='v_case.payers_name'
          label='Primary payer'
          render={record => {
            const { v_case: { payers_name } = {} } = record;
            if (!payers_name) {
              return null;
            }
            return payers_name;
          }}
        />
        <FunctionField
          source='related_to_type'
          label='Related to'
          render={record => {
            const { case_id, related_to_id, related_to_type } = record;
            if (!case_id || !related_to_id) {
              return null;
            }
            return (
              <Button
                className={classes.referenceBtn}
                color='primary'
                onClick={e => e.stopPropagation()}
                component={Link}
                to={
                  related_to_type === 'Failed Fax' ||
                  related_to_type?.includes('Email')
                    ? `/cases/${case_id}/emails?direction=Outbound&${qs.stringify(
                        {
                          log_id: related_to_id,
                        },
                      )}`
                    : `/cases/${case_id}/timeline?${qs.stringify({
                        event_id: related_to_id,
                      })}`
                }
              >
                {related_to_type}
              </Button>
            );
          }}
        />

        <TextField source='taskAssignedTo.name' label='Assigned to' />
        <DateField source='date_due' label='Date due' />
        <FunctionField
          source='status'
          label='Status'
          render={record => {
            const { status, date_due } = record;
            let className = 'notYetDue';
            const date = new Date(`${date_due} 00:00`);
            if (isValid(date) && !isToday(date) && isPast(date)) {
              className = 'pastDue';
            }
            return (
              <ChipField
                record={record}
                source='status'
                className={clsx(classes.chip, classes[getChip(status)], {
                  [classes[className]]: !!className,
                })}
              />
            );
          }}
        />
        <FunctionField
          source='title'
          label=''
          render={record => {
            const { title, v_case, date_due } = record;
            return !facilityLevel && title?.includes('Clinical Data Match') ? (
              <Button
                className={classes.resend}
                color='primary'
                onClick={() => fetchText(title, v_case, date_due)}
              >
                Send Notification
              </Button>
            ) : null;
          }}
        />
      </Datagrid>
    </List>
  );
};

const CompletedList = ({
  props,
  apiData,
  shouldFetchData,
  userPermissions,
  toggleAside,
  rowRecord,
  asideOpen,
  facilityLevel,
  setTaskStatus,
  taskStatus,
  setDivision,
  division,
  rowClick,
  listRowStyle,
  rootBasePath,
  taskFilters,
  filterActions,
  filterManager,
  caseManager,
  showAll,
  setShowAll,
  exportOpen,
  setExportOpen,
  residentCaseCount,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const { canDeleteOwnTasks, canDeleteOthersTasks } = useGetTaskPermissions();
  const userId = useSelector(state => state.user.profile.id);
  const coveredUserId = useSelector(state => state.facility.covered_user_id);
  if (!shouldFetchData) return null;
  const { perPage, sort, filter } = apiData;
  return (
    <List
      empty={false}
      {...props}
      perPage={perPage}
      sort={sort}
      filter={filter}
      exporter={false}
      bulkActionButtons={
        false // disable delete
      }
      className={clsx(classes.list, {
        [classes.listWithDrawer]: asideOpen,
      })}
      actions={
        facilityLevel ? (
          <FacilityLevelActions
            setDivision={setDivision}
            setStatus={setTaskStatus}
            rootBasePath={rootBasePath}
            taskFilters={taskFilters}
            filterActions={filterActions}
            filterManager={filterManager}
            caseManager={caseManager}
            exportOpen={exportOpen}
            setExportOpen={setExportOpen}
            isViewAdmin={isViewAdmin}
          />
        ) : (
          <CaseLevelActions
            setStatus={setTaskStatus}
            rootBasePath={rootBasePath}
            showAll={showAll}
            setShowAll={setShowAll}
            residentCaseCount={residentCaseCount}
            isViewAdmin={isViewAdmin}
          />
        )
      }
      pagination={
        <Pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          style={{
            display: 'flex',
          }}
        />
      }
    >
      <Datagrid
        rowClick={rowClick}
        rowStyle={(record, index) => listRowStyle(record, index, rowRecord.id)}
        isRowSelectable={record => {
          const isMyTask =
            record && record.assigned_to_id === (coveredUserId || userId);
          return isMyTask ? canDeleteOwnTasks : canDeleteOthersTasks;
        }}
      >
        <FunctionField
          source='title'
          label='Task'
          render={record => {
            const { title, task_type: taskType } = record;
            const { task_type } = taskType || {};
            return title || task_type;
          }}
        />
        <FunctionField
          source='v_case.case_name'
          label='Case name'
          sortBy='last_name'
          render={record => {
            const { v_case: { case_name, id: case_id } = {} } = record;
            if (!case_name || !case_id) {
              return null;
            }
            return (
              <Button
                className={classes.referenceBtn}
                color='primary'
                onClick={e => e.stopPropagation()}
                component={Link}
                to={`/cases/${case_id}/timeline`}
              >
                {case_name}
              </Button>
            );
          }}
        />
        <FunctionField
          sortable={false}
          source='v_case.payers_name'
          label='Primary payer'
          render={record => {
            const { v_case: { payers_name } = {} } = record;
            if (!payers_name) {
              return null;
            }
            return payers_name;
          }}
        />
        <FunctionField
          source='related_to_type'
          label='Related to'
          render={record => {
            const { case_id, related_to_id, related_to_type } = record;
            if (!case_id || !related_to_id) {
              return null;
            }
            return (
              <Button
                className={classes.referenceBtn}
                color='primary'
                onClick={e => e.stopPropagation()}
                component={Link}
                to={
                  related_to_type === 'Failed Fax' ||
                  related_to_type?.includes('Email')
                    ? `/cases/${case_id}/emails?direction=Outbound&${qs.stringify(
                        {
                          log_id: related_to_id,
                        },
                      )}`
                    : `/cases/${case_id}/timeline?${qs.stringify({
                        event_id: related_to_id,
                      })}`
                }
              >
                {related_to_type}
              </Button>
            );
          }}
        />
        <DateField source='date_sent' label='Date sent to ins' />
        <TextField source='taskCompletedBy.name' label='Completed by' />
        <AdminDateField
          source='completed_at'
          label='Completed at'
          showTime
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <FunctionField
          source='status'
          label='Status'
          render={record => {
            const { status } = record;
            return (
              <ChipField
                record={record}
                source='status'
                className={clsx(classes.chip, classes[getChip(status)])}
              />
            );
          }}
        />
        <TextField
          source='completed_reason'
          label='Reason for completion/dismissal'
        />
        <FunctionField
          label='Attachments'
          render={record => {
            const { TaskDocs } = record;
            return Array.isArray(TaskDocs) && TaskDocs.length ? (
              <CustomButton
                Icon={AttachmentIcon}
                className={classes.attchListIcon}
                variant='text'
                badgeContent={TaskDocs.length}
                size='small'
                notRed
                fullSize
              />
            ) : null;
          }}
        />
      </Datagrid>
    </List>
  );
};

export const TasksShow = ({ closeAside, isViewAdmin, ...props }) => {
  const showProps = useShowController({ closeAside, ...props });
  console.log(showProps);
  return (
    <TasksAside
      showProps={showProps}
      showPropsRecord={showProps.record}
      closeAside={closeAside}
      isViewAdmin={isViewAdmin}
    />
  );
};

export const tasksResource = {
  name: 'tasks',
  list: TasksList,
  icon: NotificationIcon,
  show: TasksShow,
};

function getResourceProps(props) {
  const {
    location: { pathname } = {},
    match: { params: { id: caseId } = {} } = {},
  } = props;
  const basePath = pathname;
  const resource = {
    resource:
      pathname === '/tasks/not-completed' || pathname === '/tasks/completed'
        ? 'tasks'
        : 'case-tasks',
    hasList: true,
    hasEdit: !!tasksResource.edit,
    hasShow: !!tasksResource.show,
    hasCreate: !!tasksResource.create,
  };
  return {
    ...props,
    basePath,
    rootBasePath: caseId ? `/cases/${caseId}/` : '/',
    caseId,
    ...resource,
  };
}

const StatusTabs = ({ setStatus, classes, basePath, rootBasePath }) => {
  const [value, setValue] = useState(
    basePath === `${rootBasePath}tasks/completed` ? 1 : 0,
  );
  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
      setStatus(newValue);
    },
    [setStatus],
  );
  return (
    <Paper className={classes.tabContainer} square>
      <Tabs
        value={value}
        indicatorColor='primary'
        textColor='inherit'
        onChange={handleChange}
      >
        <Tab
          label='Open tasks'
          classes={{ root: classes.tab, selected: classes.selectedTab }}
        />
        <Tab
          label='Closed tasks'
          classes={{ root: classes.tab, selected: classes.selectedTab }}
        />
      </Tabs>
    </Paper>
  );
};
