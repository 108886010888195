import { makeStyles } from '@material-ui/core';
import { styles, colors } from '../design';
import { style } from '../resources/residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  listActionWrapper: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    width: '100%',
    flexWrap: 'wrap',
    paddingBottom: 0,
  },
  title: {
    fontFamily: theme.palette.text.fontFamily,
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.black,
    paddingLeft: 12,
    marginBottom: 10,
  },
  fileIcons: {
    color: '#E32939',
  },
  viewIcon: {
    color: theme.palette.secondary.main,
  },
  emailLogBtn: {
    marginRight: 10,
    textTransform: 'capitalize',
    fontSize: '1rem',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.text,
  },
  editIcon: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    marginLeft: 7,
    cursor: 'pointer',
  },
}));
