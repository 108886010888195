import React from 'react';

export function Logo({ classes, onClick }) {
  const addImageFallback = event => {
    event.currentTarget.src = '/images/logos/generic.png';
  };
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };
  return (
    <img
      src={`/images/logos/${window.location.hostname}-logo.png`}
      alt='logo'
      onError={addImageFallback}
      className={classes.logo}
      onClick={handleClick}
    />
  );
}
