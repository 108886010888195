import React, { useState, useEffect, useCallback, Fragment } from 'react';

import clsx from 'clsx';
import { Form } from 'react-final-form';
import {
  required,
  email,
  useDataProvider,
  cacheDataProviderProxy,
  useNotify,
  useRefresh,
  usePermissions,
} from 'react-admin';
import { DialogActions, DialogContent, Button } from '@material-ui/core';
import {
  ReferenceInput,
  FormTextField,
  SelectComponent,
  SwitchInput,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  minLength,
  validatePhoneOnSubmit,
} from '../modalForms';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';

export const UsersForm = ({ record = {}, isEdit, handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    division: [],
    roles: [],
    facilities: [],
    is_active: true,
  });
  const [currFacilities, setCurrFacilities] = useState();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  useEffect(() => {
    if (isEdit && record) {
      const fields = sanitizeFields(record);
      setCurrFacilities(fields.facilities);
      setFormData(f => ({
        ...f,
        ...fields,
      }));
    }
  }, [isEdit, record]);

  const clearQualityReviewer = useCallback(() => {
    setFormData(f => ({ ...f, quality_reviewer_id: undefined }));
  }, []);
  const clearBackendReviewer = useCallback(() => {
    setFormData(f => ({ ...f, backend_reviewer_id: undefined }));
  }, []);
  const clearTeamLeader = useCallback(() => {
    setFormData(f => ({ ...f, team_leader_id: undefined }));
  }, []);

  const onSubmit = value => {
    if (isEdit) {
      const {
        quality_reviewer_id,
        team_leader_id,
        backend_reviewer_id,
      } = value;
      // We need to set these to null if the
      // user is removing an existing one
      const newQualityReviewerId =
        !!record.quality_reviewer_id && !quality_reviewer_id
          ? null
          : quality_reviewer_id;
      const newBackendReviewerId =
        !!record.backend_reviewer_id && !backend_reviewer_id
          ? null
          : backend_reviewer_id;
      const newTeamLeaderId =
        !!record.team_leader_id && !team_leader_id ? null : team_leader_id;
      return dataProvider
        .update('users', {
          id: record.id,
          data: {
            ...value,
            quality_reviewer_id: newQualityReviewerId,
            backend_reviewer_id: newBackendReviewerId,
            team_leader_id: newTeamLeaderId,
            phone: validatePhoneOnSubmit(value.phone),
            cell_phone:
              value.cell_phone === ''
                ? null
                : validatePhoneOnSubmit(value.cell_phone),
          },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('users', {
          data: {
            ...value,
            phone: validatePhoneOnSubmit(value.phone),
            cell_phone:
              value.cell_phone === ''
                ? null
                : validatePhoneOnSubmit(value.cell_phone),
          },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    /* Don't allow other roles together with Contract User, View Admin, Admin Assistant */
    if (name === 'roles') {
      const selectedRole = eventOrValue?.currentTarget?.dataset?.value;
      if (value.includes(7)) {
        if (selectedRole === '8' || selectedRole === '9') {
          value = [Number(selectedRole)];
        } else value = [7];
      }
      if (value.includes(8)) {
        if (selectedRole === '7' || selectedRole === '9') {
          value = [Number(selectedRole)];
        } else value = [8];
      }
      if (value.includes(9)) {
        if (selectedRole === '7' || selectedRole === '8') {
          value = [Number(selectedRole)];
        } else value = [9];
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='first_name'
                    label='First Name'
                    customOnChange={customOnChange}
                    validate={required()}
                    required
                  />
                  <FormTextField
                    name='last_name'
                    label='Last Name'
                    customOnChange={customOnChange}
                    validate={required()}
                    required
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='phone'
                    label='Phone'
                    customOnChange={customOnChange}
                    format='phone'
                    validate={composeValidators([
                      minLength(10, 'Invalid phone number'),
                    ])}
                  />
                  <FormTextField
                    name='extension'
                    label='Ext'
                    customOnChange={customOnChange}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='cell_phone'
                    label='Cell phone'
                    customOnChange={customOnChange}
                    format='phone'
                    validate={composeValidators([
                      minLength(10, 'Invalid phone number'),
                    ])}
                  />
                  {/* Needed for spacing */}
                  <div style={{ flex: '1 0 160px' }} />
                </div>
                <FormTextField
                  name='email'
                  label='Email'
                  customOnChange={customOnChange}
                  validate={composeValidators([required(), email()])}
                  required
                  fullWidth
                  type='email'
                />
                <SelectComponent
                  customOnChange={customOnChange}
                  name='division'
                  label='Division'
                  validate={required()}
                  required
                  multiple
                  renderWith='chip'
                  choices={divisionTypes}
                  fullWidth
                />
                <SelectComponent
                  customOnChange={customOnChange}
                  name='roles'
                  label='Roles'
                  validate={required()}
                  required
                  multiple
                  renderWith='chip'
                  choices={getRoleTypes(userPermissions)}
                  fullWidth
                />
                {(!isEdit || Array.isArray(currFacilities)) && (
                  <ReferenceInput
                    reference='facilities/list'
                    customOnChange={customOnChange}
                    setFormData={setFormData}
                    name='facilities'
                    label='Facilities'
                    validate={required()}
                    required
                    perPage={500}
                    multiple
                    fullWidth
                    selectMltc
                    options={{
                      filter: {
                        active: true,
                        inactive: true,
                        facility_ids: isEdit ? currFacilities : undefined,
                      },
                      sort: { field: 'name', order: 'ASC' },
                    }}
                  />
                )}
                <ReferenceInput
                  reference='quality-reviewer/list'
                  customOnChange={customOnChange}
                  name='quality_reviewer_id'
                  label='Quality Reviewer'
                  required={false}
                  fullWidth
                  shouldFetchMore
                  options={{
                    sort: { field: 'last_name', order: 'ASC' },
                  }}
                />
                {!!values.quality_reviewer_id && (
                  <Button
                    color='primary'
                    size='small'
                    onClick={clearQualityReviewer}
                    className={classes.removeSuperior}
                  >
                    Remove Quality Reviewer
                  </Button>
                )}
                <ReferenceInput
                  reference='backend-reviewer/list'
                  customOnChange={customOnChange}
                  name='backend_reviewer_id'
                  label='Backend Reviewer'
                  required={false}
                  fullWidth
                  shouldFetchMore
                  options={{
                    sort: { field: 'last_name', order: 'ASC' },
                  }}
                />
                {!!values.backend_reviewer_id && (
                  <Button
                    color='primary'
                    size='small'
                    onClick={clearBackendReviewer}
                    className={classes.removeSuperior}
                  >
                    Remove Backend Reviewer
                  </Button>
                )}
                <ReferenceInput
                  reference='team-leader/list'
                  customOnChange={customOnChange}
                  name='team_leader_id'
                  label='Team Leader'
                  required={false}
                  fullWidth
                  shouldFetchMore
                  options={{
                    sort: { field: 'last_name', order: 'ASC' },
                  }}
                />
                {!!values.team_leader_id && (
                  <Button
                    color='primary'
                    size='small'
                    onClick={clearTeamLeader}
                    className={classes.removeSuperior}
                  >
                    Remove Team Leader
                  </Button>
                )}
                <SwitchInput
                  name='is_active'
                  customOnChange={customOnChange}
                  label='Active User'
                  checked={formData.is_active}
                />
                <FormTextField
                  name='title'
                  label={`User's title`}
                  customOnChange={customOnChange}
                  fullWidth
                />
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

function getRoleTypes(role) {
  const notAdmin = !role.includes('admin');
  return [
    { id: 1, name: 'Admin', inactive: notAdmin },
    { id: 2, name: 'Supervisor' },
    { id: 3, name: 'Case Manager' },
    { id: 4, name: 'Quality Reviewer' },
    { id: 5, name: 'Team Leader' },
    { id: 6, name: 'Backend Reviewer' },
    { id: 8, name: 'Admin View Only', inactive: notAdmin },
    { id: 9, name: 'Admin Assistant', inactive: notAdmin },
    { id: 7, name: 'Contract User' },
    { id: 10, name: 'Covering User', inactive: true },
  ];
}

const divisionTypes = [
  { id: 'mltc', name: 'MLTC' },
  { id: 'subacute', name: 'Subacute' },
];

function sanitizeFields(record) {
  const {
    id,
    divisionArray,
    division,
    is_mltc,
    is_subacute,
    name,
    facilities = [],
    roles = [],
    is_active,
    QualityReviewer,
    BackendReviewer,
    TeamLeader,
    ...rest
  } = record;
  const facilityIds = facilities.map(f => f.id);
  const rolesIds = roles.map(r => r.id);
  return {
    ...rest,
    division: divisionArray,
    facilities: facilityIds,
    roles: rolesIds,
    is_active: !!is_active,
  };
}
