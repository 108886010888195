export const siteColors = {
  'mcomanager.cmsauths.com': {
    primary: {
      main: '#1061A0',
      text: '#000000',
      buttons: '#3B94D1',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#5C738E',
      text: '#EFF4FB',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#00000',
      secondary: '#5C738E',
      fontWeight: 300,
      fontFamily: 'Nunito Sans',
    },
  },
  'cms-client-2.bitbean.dev': {
    primary: {
      main: '#486B00',
      text: '#000000',
      buttons: '#A2C523',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#7D4427',
      text: '#AEBD38',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#00000',
      secondary: '#5C738E',
      fontWeight: 300,
      fontFamily: 'Comic Sans MS',
    },
  },
  'cms-client-3.bitbean.dev': {
    primary: {
      main: '#FA6775',
      text: '#000000',
      buttons: '#F52549',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFD64D',
      text: '#CB0000',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#00000',
      secondary: '#5C738E',
      fontWeight: 300,
      fontFamily: 'Courier New',
    },
  },
  'cms-client-4.bitbean.dev': {
    primary: {
      main: '#FA812F',
      text: '#000000',
      buttons: '#FA4032',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#C^0000',
      text: '#FAAF08',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#00000',
      secondary: '#5C738E',
      fontWeight: 300,
      fontFamily: 'Nunito Sans',
    },
  },
  'cmsauth.bitbean.dev': {
    primary: {
      main: '#1061A0',
      text: '#000000',
      buttons: '#3B94D1',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#5C738E',
      text: '#EFF4FB',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#00000',
      secondary: '#5C738E',
      fontWeight: 300,
      fontFamily: 'Nunito Sans',
    },
  },
  localhost: {
    primary: {
      main: '#486B00',
      text: '#000000',
      buttons: '#A2C523',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#7D4427',
      text: '#AEBD38',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#00000',
      secondary: '#5C738E',
      fontWeight: 300,
      fontFamily: 'Calibri',
    },
  },
};
