import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    backgroundImage:
      'url(/login-background-left.svg), url(/login-background-right.svg)',
    backgroundPosition: 'bottom -55% left -4% ,  bottom 300%  right -8%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '25%, 35%',
    backgroundOrigin: 'border-box',
  },
  ChangePasswordMain: {
    minHeight: 'inherit',
    background: 'white',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 524,
    width: 360,
    maxWidth: '96%',
    padding: '32px 48px',
    backgroundColor: 'white',
  },

  paperInstructions: {
    height: 235,
    width: 430,
    padding: '57px 48px',
  },
  paperReset: {
    height: 400,
    // padding: '57px 48px',
  },
  paperChangePassword: {
    height: 400,
    padding: '32px 48px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: 360, // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  submitWrapper: {
    width: '100%',
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  submit: {
    height: 48,
    width: '100%',
    fontSize: 15,
    textTransform: 'capitalize',
    borderRadius: '3px',
    backgroundColor: theme.palette.primary.buttons,
  },
  logo: {
    height: 100,
  },
  welcome: {
    lineHeight: '54px',
    width: 175,
    color: '#000000',
    fontSize: 40,
    fontWeight: 600,
    letter: 'normal',
    textAlign: 'center',
    paddingBottom: 10,
    paddingTop: 10,
  },
  welcomeForgotPassword: {
    fontSize: 40,
    width: 360,
    fontWeight: 800,
  },
  welcomeInstructions: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 15,
  },
  forgotSubmitWrapper: {
    textAlign: 'center',
  },

  buttonText: {
    height: '22',
    width: '310',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: '22px',
    textAlign: 'center',
    textTransform: 'none',
  },

  buttonLink: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '19px',
    width: '100%',
    marginTop: 10,
    height: 20,
    textAlign: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
    textUnderlinePosition: 'under',
  },
  inputWrapper: {
    height: 72,
    width: 360,
  },
  signIn: {
    fontSize: 14,
    width: 133,
    height: 22,
  },

  instructionsMsg: {
    fontSize: 14,
    width: 345,
    height: 44,
    marginTop: 8,
    textAlign: 'center',
  },

  mfaMsg: {
    fontSize: 14,
    width: 345,
    height: 44,
    textAlign: 'left',
    marginLeft: -10,
    paddingTop: 4,
  },
  resetMsg: {
    fontSize: 14,
    marginBottom: 20,
    width: '100%',
  },
  recoveryLink: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: '#66afdb',
  },
  version: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    padding: 5,
    '&:hover': {
      opacity: 1,
    },
  },
}));
