import { makeStyles } from '@material-ui/core';
import { styles } from '../design';
import { style } from '../resources/residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  actionHeader: {
    paddingTop: 8,
  },
  actionContainer: {
    width: '100%',
    ...styles.flexRow,
    ...styles.justifyBetween,
    flexWrap: 'wrap',
    marginTop: 20,
  },
  residentDivider: {
    color: '#829CB5',
    height: 2,
  },
  funcShowItem: {
    fontSize: 11,
  },
  commentField: {
    minWidth: '100%',
    marginBottom: 20,
  },
  listActionWrapper: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    width: '100%',
    flexWrap: 'wrap',
    paddingBottom: 0,
  },
  title: {
    fontFamily: theme.palette.text.fontFamily,
    fontSize: 20,
    fontWeight: 'bold',
    paddingLeft: 12,
    marginBottom: 10,
  },
}));
