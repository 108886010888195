import React, { useState, useEffect, Fragment } from 'react';

import clsx from 'clsx';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import {
  ReferenceInput,
  FormTextField,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  validateDate,
  maxTextLength,
  minDate,
} from '.';
import { SelectComponent, RadioInput, SwitchInput } from './inputs';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
import { authGet } from '../../../../server';
export const RatesForm = ({
  record = {},
  isEdit,
  meta,
  payerFacilityId,
  handleClose,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    payer_facility_id: payerFacilityId,
    line_of_businesses: [],
    is_percent_rate: 'false',
  });
  const lobPickList = (meta.line_of_businesses || []).map(lob => ({
    id: lob.id,
    name: lob.payer_type,
  }));
  const excludesPartB = !meta.line_of_businesses.find(row => row.id === 13);
  useEffect(() => {
    if (isEdit && record) {
      const {
        id,
        rev_codes: _rev_codes,
        payer_facility_lobs,
        ...rest
      } = record;
      const line_of_businesses = Array.isArray(payer_facility_lobs)
        ? payer_facility_lobs.map(lob => lob.line_of_business_id)
        : [];
      const rev_codes =
        _rev_codes && Array.isArray(_rev_codes) ? _rev_codes.join(',') : '';
      setFormData(f => ({
        ...f,
        ...rest,
        line_of_businesses,
        rev_codes,
        is_percent_rate: !!rest.percent ? 'true' : 'false',
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    if (isEdit) {
      const {
        percent,
        note,
        rate,
        rev_codes,
        is_percent_rate,
        ...rest
      } = value;
      const isPercent = is_percent_rate === 'true';
      return dataProvider
        .update('payers-facility/rates', {
          id: record.id,
          data: {
            percent: isPercent ? percent : null,
            note: isPercent ? note : null,
            rate: !isPercent ? rate : null,
            rev_codes: !isPercent ? rev_codes : null,
            ...rest,
          },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('payers-facility/rates', { data: { ...value } })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    if (name === 'level') {
      const isPercentRate = [1, 10, 18, 19].includes(value) ? 'true' : 'false';
      if (!isEdit) {
        const response = await authGet([
          '/global-rev-codes',
          {
            payer_facility_id: payerFacilityId,
            level: value,
          },
        ]);
        if (!response.error) {
          const revCodes = Array.isArray(response.data[0]?.rev_codes)
            ? response.data[0]?.rev_codes.join(',')
            : '';
          setFormData({
            ...formData,
            rev_codes: revCodes,
            is_percent_rate: isPercentRate,
            line_of_businesses:
              value === 18 && !excludesPartB
                ? [13]
                : formData.line_of_businesses,

            [name]: value,
          });
        }
      } else {
        setFormData({
          ...formData,
          is_percent_rate: isPercentRate,
          [name]: value,
        });
      }
      return;
    }
    if (name === 'is_percent_rate') {
      if (value === 'true') {
        setFormData({
          ...formData,
          percent: null,
          note: null,
          [name]: value,
        });
      } else {
        setFormData({
          ...formData,
          rev_codes: '',
          rate: null,
          [name]: value,
        });
      }
      return;
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    key={1}
                    reference='auth-types/list'
                    customOnChange={customOnChange}
                    name='level'
                    label='Rate type'
                    validate={required()}
                    required
                    options={{
                      filter: {
                        rate: 1,
                        exclude_part_b: excludesPartB,
                      },
                    }}
                  />
                  <FormTextField
                    name='sub_level'
                    customOnChange={customOnChange}
                    label='Sub-level'
                    setFormData={setFormData}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <SelectComponent
                    customOnChange={customOnChange}
                    validate={required()}
                    required
                    name='line_of_businesses'
                    label='Lines of business'
                    choices={lobPickList}
                    style={{ marginRight: 0 }}
                    fullWidth
                    multiple
                    renderWith='chip'
                  />
                  <FormTextField
                    name='effective_date'
                    label='Effective date'
                    customOnChange={customOnChange}
                    type='date'
                    validate={composeValidators([
                      required(),
                      validateDate(),
                      minDate(
                        record.effective_date,
                        'New effective date must be greater than previous effective date',
                      ),
                    ])}
                    required
                  />
                  <div style={{ flex: '1 0 160px' }} />
                  {isEdit && record.effective_date !== formData.effective_date && (
                    <DialogContentText color={'error'} variant='subtitle2'>
                      Note: Changing this date will affect authorization and
                      billing calculations for this rate.
                    </DialogContentText>
                  )}
                  <RadioInput
                    row
                    name='is_percent_rate'
                    customOnChange={customOnChange}
                    value={formData.is_percent_rate}
                    label=''
                    radios={[
                      {
                        label: 'Reimbursement per day',
                        value: 'false',
                      },
                      {
                        label: 'Percent of rate',
                        value: 'true',
                      },
                    ]}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  {formData.is_percent_rate === 'true' ? (
                    <FormTextField
                      name='percent'
                      customOnChange={customOnChange}
                      type='number'
                      label='Percent of rate'
                      step='any'
                      style={{ marginRight: 0 }}
                      setFormData={setFormData}
                    />
                  ) : (
                    <FormTextField
                      name='rate'
                      customOnChange={customOnChange}
                      type='number'
                      label='Reimbursement per day'
                      step='any'
                      style={{ marginRight: 0 }}
                      setFormData={setFormData}
                    />
                  )}
                </div>

                <div className={clsx(classes.inputContainerWrap)}>
                  {formData.is_percent_rate === 'true' ? (
                    <FormTextField
                      name='note'
                      label='Note (max 20 characters)'
                      multiline
                      fullWidth
                      customOnChange={customOnChange}
                      disabled={!!values.s_payer_rep}
                      validate={composeValidators([
                        required(),
                        maxTextLength(
                          20,
                          'Please enter an amount less than 20 characters',
                        ),
                      ])}
                      required
                      style={{ marginRight: 0 }}
                    />
                  ) : (
                    <Fragment>
                      <FormTextField
                        name='rev_codes'
                        label='Rev codes'
                        customOnChange={customOnChange}
                        fullWidth
                        style={{ marginRight: 0 }}
                      />
                    </Fragment>
                  )}
                </div>
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
