import React, { useState, useEffect, Fragment } from 'react';

import clsx from 'clsx';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import { authGet } from '../../../../server';
import {
  ReferenceInput,
  FormTextField,
  SelectComponent,
  handleNumbers,
  getDateInputValue,
} from '.';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
import { constants, ensureUrlProtocol } from '../../../../utils';
const { states } = constants;

export const GlobalPayerForm = ({ record = {}, isEdit, handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [formData, setFormData] = useState({
    line_of_businesses: [],
    admit_notice_required: false,
  });
  const [payerFields, setPayerFields] = useState({});
  useEffect(() => {
    if (isEdit && record) {
      const { Payers, ...rest } = sanitizeRecord(record);
      setPayerFields({ ...Payers });
      setFormData(f => ({
        ...f,
        ...rest,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    const {
      payer_id: _payer_id,
      in_network: _in_network,
      website: _website,
    } = value;

    const payer_id = _payer_id ? parseInt(_payer_id) : 0;
    const in_network =
      _in_network && !isNaN(_in_network) ? parseInt(_in_network) : _in_network;
    if (isEdit) {
      return dataProvider
        .update('global-payers', {
          id: record.id,
          data: {
            ...value,
            payer_id,
            in_network,
            website: ensureUrlProtocol(_website),
          },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('global-payers', {
          data: {
            ...value,
            payer_id,
            in_network,
            website: ensureUrlProtocol(_website),
          },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    if (name === 'payer_id') {
      if (value !== 0 && value !== null) {
        const data = await fetchResource('payers', value);
        if (data !== 'error') {
          setPayerFields({ [name]: value, ...data });
        }
      } else {
        setPayerFields({ [name]: value });
      }
      return;
    }

    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    // Set defaults lob - all except Workers Comp and No Fault
    !isEdit &&
      setFormData({
        line_of_businesses: [1, 2, 4, 5, 8, 10, 13],
      });
  }, [isEdit]);
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
          ...payerFields,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='name'
                    validate={required()}
                    required
                    label='Payer'
                    customOnChange={customOnChange}
                    fullWidth
                    style={{ marginRight: 0 }}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='parent_company'
                    label='Parent company'
                    customOnChange={customOnChange}
                  />
                  <FormTextField
                    name='seek_payer_code'
                    label='Seek ID'
                    customOnChange={customOnChange}
                    disabled={true}
                  />
                </div>
                <ReferenceInput
                  reference='lob/list'
                  customOnChange={customOnChange}
                  name='line_of_businesses'
                  label='Lines of business'
                  validate={required()}
                  required
                  multiple
                  renderWith='chip'
                  fullWidth
                />
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='submit_terms'
                    label='Claim submission terms'
                    customOnChange={customOnChange}
                  />
                  <FormTextField
                    name='payment_terms'
                    label='Claim payment terms'
                    customOnChange={customOnChange}
                    style={{ marginRight: 0 }}
                  />
                </div>
                Claims address
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='remit_address'
                    label='Address'
                    fullWidth
                    customOnChange={customOnChange}
                    style={{ marginRight: 0 }}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='city'
                    customOnChange={customOnChange}
                    label='City'
                  />
                  <SelectComponent
                    customOnChange={customOnChange}
                    label='State'
                    name='state'
                    choices={states}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='zip'
                    customOnChange={customOnChange}
                    label='Zip code'
                  />
                  {/* Needed for spacing */}
                  <div style={{ flex: '1 0 160px' }} />
                </div>
                Websites
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='website'
                    label='Website'
                    customOnChange={customOnChange}
                    fullWidth
                    style={{ marginRight: 0 }}
                  />
                </div>
                Pre-auth/eligibility contact
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='pre_auth_eligibility_contact'
                    label='Phone'
                    customOnChange={customOnChange}
                    fullWidth
                    style={{ marginRight: 0 }}
                  />
                </div>
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

function sanitizeRecord(record) {
  const {
    id,
    created_at,
    deleted_at,
    facility_id,
    updated_at,
    rates,
    line_of_businesses: _line_of_businesses,
    updated_by,
    created_by,
    payersFacilityCreatedBy,
    payersFacilityUpdatedBy,
    ...rest
  } = record;
  const rate = Array.isArray(rates) && rates.length ? rates[0].rate : null;
  const line_of_businesses = Array.isArray(_line_of_businesses)
    ? _line_of_businesses.map(lob => lob.id)
    : [];

  return { ...rest, line_of_businesses, rate };
}

async function fetchResource(resource, value) {
  const response = await authGet(`/${resource}/${value}`);
  if (response.error) return 'error';
  return response.data;
}
