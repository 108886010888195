import { makeStyles } from '@material-ui/core';
import { styles, colors } from '../../design';
export const useStyles = makeStyles(theme => ({
  barColorPrimary: {
    backgroundColor: '#AEDD45',
  },
  flexRow: {
    ...styles.flexRow,
    flexWrap: 'wrap',
  },
  flexColumn: {
    ...styles.flexColumn,
  },
  title: {
    fontFamily: theme.palette.text.fontFamily,
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.black,
  },
  tasksChartCard: {
    width: 435,
    height: 310,
    textAlign: 'center',
    fontSize: 15,
    alignItems: 'flex-start',
  },
  bottomText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
  },
  taskSmallCard: {
    height: 'calc(100% - 16px)',
    padding: 5,
    fontSize: 15,
    position: 'relative',
  },
  smallCardBox: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: -8,
    marginRight: 8,
    marginLeft: 8,
  },
  smallCardIcon: { color: '#fff', fontSize: 24 },
  smallCardContent: {
    textAlign: 'center',
    marginLeft: 40,
    minWidth: 120,
    fontWeight: 'bold',
    '& .title': {
      fontSize: 15,
      fontWeight: 500,
      flex: 1,
      textAlign: 'center',
    },
  },
  section: {
    alignItems: 'center',
    display: 'flex',
    // height: '100%',
    justifyContent: 'center',
    minHeight: 280,
    padding: 8,
  },
  rightTaskSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  donutChart: {
    width: 160,
    '& .pie-text': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -25%)',
      textAlign: 'center',
    },
    '& .pie-text:hover': {
      opacity: 0.7,
    },
  },
  sectionTitle: {
    color: colors.black,
    fontSize: 19,
    fontWeight: 300,
    margin: 24,
    textAlign: 'center',
  },
  todayTasksFilter: {
    position: 'absolute',
    right: 20,
    top: 0,
    width: 'auto',
  },
  tasksToday: {
    fontSize: 19,
    marginBottom: 18,
    textAlign: 'center',
  },
  residentsRequiringAction: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: -2,
  },
  openTasksCount: {
    color: '#66afdb',
  },
  caseBreakDownPaper: {
    height: 310,
    position: 'relative',
    '& .breakdown-info': {
      textAlign: 'right',
      width: 45,
      fontSize: 11,
      position: 'absolute',
      right: 15,
      top: 0,
    },
    '& h5': {
      fontSize: 18,
      margin: 0,
      marginBottom: 5,
    },
    '& .breakdown-section': {
      marginTop: 18,
    },
  },
  supervisorTaskSection: {
    padding: 15,
    width: 'initial',
    flex: '1 0 45%',
    boxSizing: 'border-box',
  },
  fullwidth: {
    flex: '1 0 100%',
    maxWidth: '100%',
  },
  footer: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 20,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(240, 244,  250)',
  },
  paper: {
    width: 400,
  },
}));
