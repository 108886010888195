import React, {
  Fragment,
  cloneElement,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import startCase from 'lodash/startCase';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  useRefresh,
  List,
  Show,
  ShowView,
  SimpleShowLayout,
  BulkDeleteButton,
  TextField,
  FunctionField,
  ReferenceField,
  ChipField,
  TopToolbar,
  ExportButton,
  downloadCSV,
  useUnselectAll,
  // ShowGuesser,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { useShowController } from '../hooks';
import {
  Drawer,
  IconButton,
  Chip,
  Divider,
  Button,
  Typography,
} from '@material-ui/core';
import { CustomButton } from '../design/material-ui';
import { getChip, constants, currencyFormat, limitStringSize } from '../utils';
import { authGet } from '../server';
import {
  DateField,
  Datagrid,
  StatusFilter,
  FilterChips,
  FilterMenu,
} from '../components/common';
import {
  NewCommunication,
  NewEvent,
  Attachments,
  NotesModal,
} from '../components/common/modals';
import { AddAttachmentModal } from '../components/common/modals/modalForms';
import { ResidentShowFields } from '../resources';
import {
  CloseIcon,
  AttachmentIcon,
  PharmIcon,
  AddIcon,
  EditIcon,
  InsertCommentIcon,
} from '../design';
import { useStyles } from './auths.styles';
const { eventTypes } = constants;

const exporter = fields => {
  const fieldsForExport = fields.map(field => {
    const {
      id,
      v_case,
      payer,
      payer_id,
      PharmLogDocs,
      case_id,
      created_at,
      deleted_at,
      updated_at,
      user_id,
      average_wholesale_price,
      price_per_day,
      total_price,
      pharm_log_status,
      pharm_log_status_id,
      ...rest
    } = field;
    rest.case_name = v_case?.case_name;
    rest.payer_name = payer?.name;
    rest.average_wholesale_price = currencyFormat(average_wholesale_price);
    rest.price_per_day = currencyFormat(price_per_day);
    rest.total_price = currencyFormat(total_price);
    rest.status = pharm_log_status?.status;
    return rest;
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'case_name',
        'status',
        'payer_name',
        'dispense_date',
        'rx_number',
        'drug_route',
        'medication',
        'order_type',
        'price_per_day',
        'total_price',
        'average_wholesale_price',
        'national_drug_code',
        'days_supply',
        'quantity',
        'jcode',
        'prescribing_doctor',
        'comments',
      ],
      rename: [
        'Case Name',
        'Status',
        'Insurance',
        'Dispensed',
        'Rx #',
        'Drug Route',
        'Medication',
        'Order Type',
        'PPD',
        'Total Price',
        'Avg Wholesale Price',
        'Drug Code',
        'Days Supply',
        'Quantity',
        'Jcode',
        'Doctor',
        'Comments',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, `Pharm_log_${format(new Date(), 'MM/dd/yyyy')}`);
    },
  );
};

const FacilityLevelActions = ({
  resource,
  currentSort,
  total,
  filterValues,
  permanentFilter,
  filterActions,
  filters,
  activeStatusFilters = {},
  loading,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <div style={{ display: 'flex', paddingBottom: 20 }}>
            <span className={classes.title}>Pharmacy log</span>
          </div>
          <div style={{ display: 'flex' }}>
            <CustomButton
              Icon={AddIcon}
              backgroundColor='#EFF4FB'
              label='Add'
              type='button'
              variant='text'
              size='small'
              onClick={() => setEventModal(true)}
              disabled={isViewAdmin}
            />
            <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={{ ...filterValues, ...permanentFilter }}
              exporter={exporter}
              className={classes.exportButton}
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <FilterMenu filterActions={filterActions} filters={filters} />
          <FilterChips
            activeFilters={activeStatusFilters}
            filterActions={filterActions}
            disabled={loading}
          />
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='pharmLog'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const CaseLevelActions = ({
  resource,
  showAll,
  setShowAll,
  currentSort,
  total,
  filterValues,
  permanentFilter,
  residentCaseCount,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <div style={{ display: 'flex' }}></div>
          <div style={{ display: 'flex' }}>
            <CustomButton
              Icon={AddIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label='Add'
              type='button'
              variant='text'
              size='small'
              onClick={() => setEventModal(true)}
              disabled={isViewAdmin}
            />
            <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={{ ...filterValues, ...permanentFilter }}
              exporter={exporter}
              className={classes.exportButton}
            />
          </div>
        </div>
        <div>
          {residentCaseCount > 1 && (
            <StatusFilter setShowAll={setShowAll} showAll={showAll} />
          )}
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='pharmLog'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const PharmLogShowActions = ({
  basePath,
  data = {},
  title,
  resourceRecord,
  closeAside,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const { pharm_log_status } = data || {};
  const status = pharm_log_status?.status;
  const [communicationOpen, setCommunicationModal] = useState(false);
  const [eventOpen, setEventModal] = useState(false);
  const [attchOpen, setAttchModal] = useState(false);
  const refresh = useRefresh();
  const hasAttachments =
    !!resourceRecord &&
    Array.isArray(resourceRecord.document_ids) &&
    !!resourceRecord.document_ids.length;
  const documentCount = resourceRecord?.document_ids?.length;
  return (
    <div>
      <TopToolbar className={classes.header}>
        <div className={classes.headerRow}>
          <span className={classes.title}>{title}</span>{' '}
          <span className={classes.statusChipWrapper}>
            <Chip
              size='small'
              label={status}
              className={clsx(classes.chip, classes[getChip(status)])}
            />
          </span>
          <IconButton onClick={closeAside} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.headerRow}>
          <div className={classes.actionContainer}>
            <span style={{ contentAlign: 'left' }}>
              <IconButton
                className={classes.showBtn}
                onClick={() => setEventModal(true)}
                size='small'
                disabled={isViewAdmin}
              >
                <EditIcon />
              </IconButton>
              {'         '}
              <IconButton
                className={classes.showBtn}
                onClick={() => setCommunicationModal(true)}
                size='small'
              >
                <InsertCommentIcon />
              </IconButton>
              {'         '}
              <CustomButton
                Icon={AttachmentIcon}
                className={classes.showBtn}
                variant='text'
                badgeContent={documentCount}
                onClick={() => setAttchModal(true)}
                size='small'
                notRed
                fullSize
              />
            </span>
          </div>
        </div>
      </TopToolbar>
      {communicationOpen && (
        <NewCommunication
          open={communicationOpen}
          handleClose={() => setCommunicationModal(false)}
        />
      )}
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          record={resourceRecord}
          form='pharmLog'
          isEdit
          refresh={refresh}
        />
      )}
      {attchOpen && (
        <Fragment>
          {hasAttachments ? (
            <Attachments
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              document_ids={resourceRecord.document_ids}
              title='Pharmacy log'
              eventId={resourceRecord.id}
              eventName={eventTypes.PHARM_LOG_NAME}
              caseId={resourceRecord.case_id}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          ) : (
            <AddAttachmentModal
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              title='Pharmacy log'
              eventId={resourceRecord.id}
              eventName={eventTypes.PHARM_LOG_NAME}
              caseId={resourceRecord.case_id}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

const BulkActionButtons = ({ toggleAside, recordOpenId, ...props }) => {
  const { selectedIds = [] } = props;
  function onClick() {
    if (
      typeof toggleAside === 'function' &&
      selectedIds.indexOf(recordOpenId) > -1
    ) {
      toggleAside(false);
    }
  }
  return <BulkDeleteButton {...props} onClick={onClick} />;
};

export const PharmList = ({ staticContext, ..._props }) => {
  const refresh = useRefresh();
  const { caseId, ...props } = getResourceProps(_props);
  const facilityLevel = props.resource === 'pharmacy';
  const classes = useStyles();
  const [asideOpen, toggleAside] = useState(false);
  const [rowRecord, setRowRecord] = useState({});
  const [showAll, setShowAll] = useState(true);
  const residentCaseCount = useSelector(state =>
    get(state, 'ui.residentCaseCount', 0),
  );
  const [filters, setFilters] = useState([]);
  const [activeStatusFilters, setActiveStatusFilters] = useState({});
  const firstRender = useRef(true);
  useEffect(() => {
    toggleAside(false);
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    refresh();
  }, [props.location.pathname, refresh]);

  const unselectAll = useUnselectAll('case-pharmacy');
  const unselectAllCases = useUnselectAll('pharmacy');
  useEffect(() => {
    return () => {
      unselectAllCases();
      unselectAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchFilters() {
      const response = await authGet('/pharm-log-statuses/list');
      const { data } = response;
      if (data) {
        setFilters(data);
      }
    }
    if (facilityLevel) {
      fetchFilters();
    }
  }, [facilityLevel]);

  const rowClick = (id, basePath, record) => {
    setRowRecord({
      ...record,
    });
    toggleAside(true);
    return null;
  };

  const listRowStyle = (record, index, activeId) => {
    if (record && record.id === activeId && asideOpen) {
      return { backgroundColor: '#EFF4FB' };
    } else if (caseId && parseInt(record?.case_id) !== parseInt(caseId)) {
      return { backgroundColor: '#EFF4FB', opacity: 0.5, fontStyle: 'italic' };
    }
  };

  const toggleShowAll = useCallback(value => {
    toggleAside(false);
    setShowAll(value);
  }, []);

  const filterActions = useCallback(({ actionType, id, name }) => {
    if (actionType === 'removeFilter') {
      setActiveStatusFilters(currentFilters => {
        const { [id]: filterToRemove, ...otherFilters } = currentFilters;
        return otherFilters;
      });
    } else if (actionType === 'addFilter') {
      setActiveStatusFilters(currentFilters => ({
        ...currentFilters,
        [id]: name,
      }));
    }
  }, []);

  const { permissions = '' } = props;
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  const isAdminOrSupervisor =
    userPermissions?.indexOf('admin') > -1 ||
    userPermissions?.indexOf('supervisor') > -1;
  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={false}
        bulkActionButtons={
          Array.isArray(userPermissions) && isAdminOrSupervisor ? (
            <BulkActionButtons
              toggleAside={toggleAside}
              recordOpenId={rowRecord.id}
            />
          ) : (
            false // disable delete
          )
        }
        className={clsx(classes.list, {
          [classes.listWithDrawer]: asideOpen,
        })}
        actions={
          facilityLevel ? (
            <FacilityLevelActions
              filterActions={filterActions}
              activeStatusFilters={activeStatusFilters}
              filters={filters}
              isViewAdmin={isViewAdmin}
            />
          ) : (
            <CaseLevelActions
              residentCaseCount={residentCaseCount}
              showAll={showAll}
              setShowAll={toggleShowAll}
              isViewAdmin={isViewAdmin}
            />
          )
        }
        filter={{
          all: showAll,
          filtered: facilityLevel
            ? Object.keys(activeStatusFilters)
            : undefined,
        }}
      >
        <Datagrid
          rowClick={rowClick}
          rowStyle={(record, index) =>
            listRowStyle(record, index, rowRecord.id)
          }
        >
          <FunctionField
            label='Status'
            source='status'
            render={record => (
              <ChipField
                record={record}
                source='pharm_log_status.status'
                className={clsx(
                  classes.chip,
                  classes[getChip(record.pharm_log_status?.status)],
                )}
              />
            )}
          />
          <FunctionField
            source='case_name'
            label='Case name'
            sortBy='case_name'
            render={record => {
              const { v_case: { case_name, id } = {} } = record;
              if (!case_name) return null;
              return (
                <Button
                  className={classes.referenceBtn}
                  color='primary'
                  onClick={e => e.stopPropagation()}
                  component={Link}
                  to={`/cases/${id}/timeline`}
                >
                  {case_name}
                </Button>
              );
            }}
          />
          <ReferenceField
            label='Case manager'
            source='user_id'
            reference='facility-users/list'
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>
          <FunctionField
            source='payer.name'
            label='Insurance'
            sortBy='payer_name'
            render={record => {
              const { payer: { name } = {} } = record;
              return name;
            }}
          />
          <DateField source='dispense_date' label='Dispensed' />
          <TextField source='rx_number' label='Rx #' />
          <TextField source='drug_route' label='Drug route' />
          <ReferenceField
            source='medication_id'
            label='Medication'
            headerClassName={classes.listItemLabel}
            reference='medications/list'
            link={false}
            sortable={false}
          >
            <TextField source='name' />
          </ReferenceField>
          <FunctionField
            source='order_type'
            label='Order type'
            render={record => startCase(record.order_type)}
          />
          <FunctionField
            source='price_per_day'
            label='PPD'
            render={record => currencyFormat(record.price_per_day)}
          />
        </Datagrid>
      </List>
      <Drawer
        variant='persistent'
        open={asideOpen}
        anchor='right'
        elevation={16}
        PaperProps={{ square: true }}
        classes={{
          paperAnchorDockedRight: clsx(classes.aside),
        }}
      >
        {asideOpen
          ? cloneElement(<PharmLogShow />, {
              ...props,
              id: rowRecord.id,
              closeAside: () => toggleAside(false),
              resource: 'pharmacy',
              classes: classes,
              residentId: rowRecord.v_case.resident_id,
              isViewAdmin: isViewAdmin,
            })
          : null}
      </Drawer>
    </Fragment>
  );
};

export const PharmLogShow = ({
  closeAside,
  classes,
  residentId,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const maxNotesLength = 160;
  const showProps = useShowController({ ...props });
  const { record } = showProps;
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <PharmLogShowActions
            closeAside={closeAside}
            title='Pharmacy log'
            resourceRecord={record}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <FunctionField
            source='payer.name'
            label='Insurance'
            textAlign='right'
            className={classes.showItem}
            render={record => {
              const { payer: { name } = {} } = record;
              return name;
            }}
          />
          <ReferenceField
            label='Case manager'
            source='user_id'
            reference='facility-users/list'
            link={false}
            textAlign='right'
            className={classes.showItem}
          >
            <TextField source='name' />
          </ReferenceField>
          <DateField
            source='dispense_date'
            label='Dispensed'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='rx_number'
            label='Rx #'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='drug_route'
            label='Drug route'
            textAlign='right'
            className={classes.showItem}
          />
          <ReferenceField
            source='medication_id'
            label='Medication'
            headerClassName={classes.listItemLabel}
            reference='medications/list'
            link={false}
            textAlign='right'
            className={classes.showItem}
          >
            <TextField source='name' />
          </ReferenceField>
          <TextField
            source='dosage'
            label='Dosage'
            className={classes.showItem}
          />
          <TextField
            source='strength'
            label='Strength'
            className={classes.showItem}
          />
          <FunctionField
            source='order_type'
            label='Order type'
            textAlign='right'
            className={classes.showItem}
            render={record => startCase(record.order_type)}
          />
          <FunctionField
            source='price_per_day'
            label='PPD'
            textAlign='right'
            className={classes.showItem}
            render={record => currencyFormat(record.price_per_day)}
          />
          <FunctionField
            source='total_price'
            label='Total price'
            textAlign='right'
            className={classes.showItem}
            render={record => currencyFormat(record.total_price)}
          />
          <FunctionField
            source='average_wholesale_price'
            label='Avg wholesale price'
            textAlign='right'
            className={classes.showItem}
            render={record => currencyFormat(record.average_wholesale_price)}
          />
          <TextField
            source='national_drug_code'
            label='Drug code'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='days_supply'
            label='Days supply'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='quantity'
            label='Quantity'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='jcode'
            label='Jcode'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='prescribing_doctor'
            label='Doctor'
            textAlign='right'
            className={classes.showItem}
          />
          {/* needed for spacing */}
          {/* <div className={classes.showItem} /> */}
          {/* <div className={classes.showItem} /> */}
          <FunctionField
            source='comments'
            label='Comments'
            textAlign='right'
            className={clsx(
              classes.showItem,
              classes.showFullWidth,
              classes.showMarginTop,
            )}
            style={{ marginTop: 10 }}
            render={record => {
              const { comments } = record;
              const truncated = limitStringSize(comments, maxNotesLength, true);
              return comments && comments.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setShowNotesModal(true)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          {showNotesModal && (
            <NotesModal
              open={showNotesModal}
              handleClose={() => setShowNotesModal(false)}
              note={record.comments}
            />
          )}
        </SimpleShowLayout>
      </ShowView>
      <Divider className={classes.residentDivider} />
      <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
        Resident info
      </p>
      <Show {...rest} resource='residents' id={residentId}>
        <ResidentShowFields classes={classes} includeCases />
      </Show>
    </div>
  );
};

export const pharmLogResource = {
  name: 'pharmacy',
  list: PharmList,
  // show: ResidentShow,
  icon: PharmIcon,
  options: { label: 'Pharm log' },
};

function getResourceProps(props) {
  if (props.basePath === '/pharmacy') return props;
  const {
    location: { pathname } = {},
    match: { params: { id: caseId } = {} } = {},
  } = props;
  const basePath = pathname || '/cases';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-pharmacy',
        hasList: true,
        hasEdit: !!pharmLogResource.edit,
        hasShow: !!pharmLogResource.show,
        hasCreate: !!pharmLogResource.create,
      };
  return { ...props, basePath, caseId, ...resource };
}
