import React, { useState, useCallback, Fragment, useEffect } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { required, email, usePermissions } from 'react-admin';
import { DialogContentText, Divider } from '@material-ui/core';
import {
  ReferenceInput,
  FormTextField,
  SelectComponent,
  composeValidators,
  handleNumbers,
  getDateInputValue,
  minLength,
  minReasonLength,
  CheckboxInput,
  RadioInput,
} from '../../modalForms';
import { AddIcon, RemoveIcon } from '../../../../../design';
import { AutocompleteInput, validateDate } from '../inputs';
import { useStyles } from '../../modal.styles';
import {
  DENIED,
  DENIAL_RECEIVED,
  HIGHEST_NO_AUTH_PAYER_ID,
  fetchResource,
  setScheduleType,
  ToggleInsuranceNetwork,
} from './helpers';
import { AuthFields } from './authFields';
import { ExternalPayerHeader } from './ExternalPayerHeader';
import {
  addDays,
  differenceInCalendarDays,
  format,
  isBefore,
  isFuture,
  isValid,
} from 'date-fns';
import { EligibilitySeeker } from './EligibilitySeeker';
import { useGetUserDivision } from '../../../../../hooks';

export const ExternalInsuranceInputs = props => {
  const { indexRank, values, setInsurances } = props;
  const myValues = values.insurances[props.indexRank];

  const classes = useStyles();
  const showInputs = myValues.is_unignored;

  const updateCheckbox = () =>
    setInsurances(f => {
      const currentValues = f[indexRank];
      const newValues = {
        ...currentValues,
        is_unignored: !currentValues.is_unignored,
      };
      f[indexRank] = newValues;
      return [...f];
    });

  return (
    <Fragment>
      <Divider className={classes.divider} />
      <ExternalPayerHeader
        indexRank={indexRank}
        payer_name={myValues.pcc_payer_name}
        payer_type={myValues.pcc_payer_type}
        is_primary={myValues.is_primary}
        checkboxDisabled={myValues.is_primary}
        toggleStatus={showInputs}
        toggleAction={updateCheckbox}
      />
      {showInputs && (
        <Fragment>
          <Divider className={classes.divider} />
          <InsuranceInputs {...props} />
        </Fragment>
      )}
    </Fragment>
  );
};

const emptyPayerRepFields = {
  // payer_rep: 0, // Only set payer_rep when selected by user
  rep_name: undefined,
  rep_email: undefined,
  rep_phone: undefined,
  rep_phone_ext: undefined,
  rep_fax: undefined,
  rep_comments: undefined,
};

const emptyAuthFields = {
  insurance_auth_number: undefined,
  authorization_status_id: undefined,
  authorization_type_id: undefined,
  rev_code: undefined,
  start_date: undefined,
  days_approved: undefined,
  end_date: undefined,
  days_available: undefined,
  rate: undefined,
  letter_received: undefined,
  auth_letter_status_id: undefined,
  auth_notes: undefined,
  authorization_id: undefined,
  initial_update_date: undefined,
  update_frequency: undefined,
  update_weekday: undefined,
  update_type: undefined,
  no_update_reason: undefined,
  update_notes: undefined,
  outpatient_services: undefined,
};

export const InsuranceInputs = ({
  // customOnChange: parentOnChange,
  values,
  isEdit,
  lastCase,
  setFormData,
  setInsurances,
  showAddNewOption = true,
  indexRank,
  primarySubLevelChoices,
  setPrimarySubLevelChoices,
}) => {
  const [authOpened, setAuthOpened] = useState(false);
  const myValues = values.insurances[indexRank];
  const primaryValues = values.insurances.find(ins => ins.is_primary);
  // secondary insurer is hardcoded as it eases handling auto-calc
  const secondaryValues = values.insurances[1];
  const isSecondary = indexRank === 1;

  const [isInNetwork, setIsInNetwork] = useState(true);
  const userId = useSelector(state => state.user.profile.id);
  const coveredUserId = useSelector(state => state.facility.covered_user_id);
  const userDivision = useGetUserDivision();
  const { permissions = '' } = usePermissions();
  const namePrefix = `insurances[${indexRank}].`;

  const updateMyInsuranceData = useCallback(
    fields => {
      if (values.is_readmission) {
        fields.start_date = values.admit_date;
      } else {
        fields.start_date = values.tracking_date;
      }
      const setMyInsurance = callback => {
        setInsurances(f => {
          f[indexRank] = callback(f[indexRank]);
          return [...f];
        });
      };

      setMyInsurance(currentValues => ({
        ...currentValues,
        ...fields,
      }));
    },
    [
      indexRank,
      setInsurances,
      values.admit_date,
      values.tracking_date,
      values.is_readmission,
    ],
  );

  /**
   * The state of the insurances fields is stored separately from the formData
   * state. Some components expect all form values to be in formData. This
   * setFormData replacement takes a standard setState callback and uses
   * the insurances property of its result to set insurances state.
   */
  const setFormDataForMyInsurance = callback => {
    setInsurances(f => {
      const formDataPartial = {
        insurances: { [indexRank]: f[indexRank] },
      };
      const newFormDataPartial = callback(formDataPartial);
      f[indexRank] = newFormDataPartial.insurances[indexRank];
      return [...f];
    });
  };

  const updateSecondaryInsuranceData = fields =>
    updateInsurancesData({ 1: fields });

  const updateInsurancesData = fieldsByIndex => {
    setInsurances(f => {
      Object.entries(fieldsByIndex).forEach(([index, fields]) => {
        const values = f[index];
        const newValues = { ...values, ...fields };
        f[index] = newValues;
      });
      return [...f];
    });
  };

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    /** @type string */
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    const simpleName = name.startsWith(namePrefix)
      ? name.substr(namePrefix.length)
      : null;

    switch (simpleName) {
      case 'payer_id':
        updateMyInsuranceData({
          ...emptyPayerRepFields,
          line_of_business_id: undefined,
          payer_id: value,
          case_type:
            userDivision === 'all' ? 0 : userDivision === 'subacute' ? 0 : 1,
          user_id: coveredUserId || userId,
        });
        break;
      case 'payer_rep':
        if (value === 0) {
          updateMyInsuranceData({
            ...emptyPayerRepFields,
            payer_rep: value,
            send_notification: null,
            send_notification_type: null,
          });
        } else {
          const data = await fetchResource('payer-reps', value);
          if (data !== 'error') {
            const {
              name: rep_name,
              email: rep_email,
              phone: rep_phone,
              phone_ext: rep_phone_ext,
              fax: rep_fax,
              comments: rep_comments,
            } = data;
            updateMyInsuranceData({
              payer_rep: value,
              rep_name,
              rep_email,
              rep_phone,
              rep_phone_ext,
              rep_fax,
              rep_comments,
              send_notification: null,
              send_notification_type: null,
            });
          }
        }
        break;
      case 'authorization_type_id': {
        setPrimarySubLevelChoices([]);
        updateMyInsuranceData({
          [simpleName]: value,
          rate_details: undefined,
          sub_level: undefined,
        });
        //TODO: Possibly add sub-level settings here
        break;
      }
      case 'days_of_coverage': {
        // expected to be only for primary insurance
        const _admit_date = values.admit_date;
        const days_used = +myValues.days_used || 0;
        const days_of_coverage = +value;
        const admit_date = new Date(`${_admit_date} 00:00`);
        const days_remaining = Math.max(days_of_coverage - days_used, 0);
        if (isValid(admit_date) && secondaryValues) {
          const copay_end_date = format(
            addDays(admit_date, +days_remaining - 1),
            'yyyy-MM-dd',
          );
          updateSecondaryInsuranceData({ copay_end_date });
        }

        updateMyInsuranceData({
          [simpleName]: value,
          days_remaining,
        });
        break;
      }
      case 'days_used': {
        // expected to be only for primary insurance
        const days_used = +value;
        const _admit_date = values.admit_date;
        const days_of_coverage = +myValues.days_of_coverage;

        const days_remaining = Math.max(
          days_of_coverage ? days_of_coverage - days_used : 0,
          0,
        );
        const admit_date = new Date(`${_admit_date} 00:00`);
        if (isValid(admit_date) && secondaryValues) {
          const copay_start_day = +secondaryValues.copay_start_day;

          const secondaryFields = {};
          if (copay_start_day) {
            secondaryFields.copay_start_date = format(
              addDays(admit_date, copay_start_day - days_used - 1),
              'yyyy-MM-dd',
            );
          }

          if (days_of_coverage && secondaryValues) {
            secondaryFields.copay_end_date = format(
              addDays(admit_date, days_remaining - 1),
              'yyyy-MM-dd',
            );
          }
          updateSecondaryInsuranceData(secondaryFields);
        }
        updateMyInsuranceData({
          days_used: value,
          days_remaining,
        });

        break;
      }
      case 'authorization_status_id': {
        const startDate = myValues.start_date;
        const start_date = new Date(`${startDate} 00:00`);
        const endDate = myValues.end_date;
        const end_date = new Date(`${endDate} 00:00`);

        updateMyInsuranceData({
          authorization_status_id: value,
          days_approved:
            value !== DENIED && value !== DENIAL_RECEIVED
              ? isValid(start_date) && isValid(end_date)
                ? isBefore(start_date, end_date)
                  ? differenceInCalendarDays(end_date, start_date)
                  : 0
                : 0
              : 0,
        });
        break;
      }

      case 'start_date': {
        const days_approved = myValues.days_approved;
        const start_date = new Date(`${value} 00:00`);

        if (isValid(start_date) && days_approved) {
          const end_date = new Date(
            addDays(new Date(`${value} 00:00`), +days_approved - 1),
          );
          const now = new Date();
          const availableFromDate = isFuture(start_date) ? start_date : now;
          updateMyInsuranceData({
            start_date: value,
            end_date: format(end_date, 'yyyy-MM-dd'),
            days_available: isBefore(availableFromDate, end_date)
              ? differenceInCalendarDays(end_date, availableFromDate)
              : 0,
          });
        } else {
          updateMyInsuranceData({ [simpleName]: value });
        }
        break;
      }
      case 'days_approved': {
        //TODO combine 'days_approved' and 'end_date' and perhaps 'start_date'
        const days_approved = value;
        const startDate = myValues.start_date;
        const start_date = new Date(`${startDate} 00:00`);
        const authorization_status_id = myValues.authorization_status_id;

        if (days_approved && isValid(start_date)) {
          const end_date = new Date(addDays(start_date, days_approved - 1));
          const now = new Date();
          const availableFromDate = isFuture(start_date) ? start_date : now;
          updateMyInsuranceData({
            days_approved:
              authorization_status_id !== DENIED &&
              authorization_status_id !== DENIAL_RECEIVED
                ? value
                : 0,
            end_date: format(end_date, 'yyyy-MM-dd'),
            days_available: isBefore(availableFromDate, end_date)
              ? differenceInCalendarDays(end_date, availableFromDate)
              : 0,
          });
        } else {
          updateMyInsuranceData({
            [simpleName]:
              authorization_status_id !== DENIED &&
              authorization_status_id !== DENIAL_RECEIVED
                ? value
                : 0,
          });
        }
        break;
      }
      case 'end_date': {
        const startDate = myValues.start_date;
        const start_date = new Date(`${startDate} 00:00`);
        const end_date = new Date(`${value} 00:00`);
        const authorization_status_id = myValues.authorization_status_id;

        if (isValid(end_date) && isValid(start_date)) {
          const now = new Date();
          const availableFromDate = isFuture(start_date) ? start_date : now;
          updateMyInsuranceData({
            days_approved:
              authorization_status_id !== DENIED &&
              authorization_status_id !== DENIAL_RECEIVED
                ? differenceInCalendarDays(end_date, start_date) + 1
                : 0,
            end_date: value,
            days_available: isBefore(availableFromDate, end_date)
              ? differenceInCalendarDays(end_date, availableFromDate)
              : 0,
          });
        } else {
          updateMyInsuranceData({ [simpleName]: value });
        }
        break;
      }
      case 'admit_date': // TODO admit_date is a non-insurance field but is not editable in the import form this section is used in. If it needs to be made editable the parent customOnChange must also be able to update insurances.
      case 'copay_start_day': {
        const _admit_date =
          simpleName === 'admit_date' ? value : values.admit_date;
        const s_copay_start_day =
          simpleName === 'copay_start_day'
            ? value
            : secondaryValues?.copay_start_day;
        const {
          days_used = 0,
          days_remaining,
          days_of_coverage,
        } = primaryValues;

        const admit_date = new Date(`${_admit_date} 00:00`);
        if (isValid(admit_date)) {
          const secondaryFields = {};
          if (s_copay_start_day) {
            secondaryFields.copay_start_date = format(
              addDays(admit_date, +s_copay_start_day - +days_used - 1),
              'yyyy-MM-dd',
            );
          }
          if (
            secondaryValues &&
            simpleName === 'admit_date' &&
            !isNaN(days_remaining) &&
            days_of_coverage
          ) {
            secondaryFields.copay_end_date = format(
              addDays(admit_date, +days_remaining - 1),
              'yyyy-MM-dd',
            );
          }
          if (isSecondary) {
            secondaryFields[simpleName] = value;
            updateSecondaryInsuranceData(secondaryFields);
          } else {
            // Will this even work or will one overwrite the other?
            updateSecondaryInsuranceData(secondaryFields);
            updateMyInsuranceData({ [simpleName]: value });
          }
        } else {
          updateMyInsuranceData({ [simpleName]: value });
        }
        break;
      }
      case 'authorization_id': {
        if (+value !== 0) {
          const data = await fetchResource('/readmission/auth-details', {
            id: value,
          });
          if (data !== 'error') {
            // the below complexity is for easier diff with main form customOnChange
            const fields = data;
            const _start_date = myValues.start_date;
            const _end_date = fields.end_date;
            const start_date = new Date(`${_start_date} 00:00`);
            const end_date = new Date(`${_end_date} 00:00`);
            const update_weekday = fields.update_weekday;
            const update_type = fields.update_type;
            const update_frequency_days = fields.update_frequency_days;
            const update_frequency = fields.update_frequency;
            const initial_update_date = fields.initial_update_date;
            const no_update_reason = fields.no_update_reason;
            const update_notes = fields.update_notes;
            const authorization_status_id = fields.authorization_status_id;

            if (isValid(start_date) && isValid(end_date)) {
              fields.days_approved =
                authorization_status_id !== DENIED &&
                authorization_status_id !== DENIAL_RECEIVED
                  ? isBefore(start_date, end_date)
                    ? differenceInCalendarDays(end_date, start_date)
                    : 0
                  : 0;
            }

            updateMyInsuranceData({
              [simpleName]: value,
              ...fields,
              update_weekday: Array.isArray(update_weekday)
                ? update_weekday
                : [],
              update_type: Array.isArray(update_type) ? update_type : [],
              schedule_type: setScheduleType({
                initial_update_date,
                update_frequency_days,
                update_frequency,
                update_weekday,
                no_update_reason,
                update_notes,
              }),
              update_frequency: update_frequency
                ? update_frequency
                : update_frequency_days
                ? 'freq_days'
                : undefined,
            });
          } else {
            updateMyInsuranceData({ [simpleName]: value });
          }
        } else {
          updateMyInsuranceData({ [simpleName]: value });
        }
        break;
      }
      case 'schedule_type': {
        const newState = { [simpleName]: value };
        if (value === 'one_time' || value === 'recurring_day') {
          newState.update_frequency = undefined;
          newState.update_frequency_days = undefined;
          newState.no_update_reason = undefined;
        }
        if (value === 'one_time' || value === 'recurring_date') {
          newState.update_weekday = [];
          newState.no_update_reason = undefined;
        }
        if (value === 'recurring_day') {
          newState.initial_update_date = undefined;
          newState.no_update_reason = undefined;
        }
        if (value === 'no_update') {
          newState.initial_update_date = undefined;
          newState.update_frequency = undefined;
          newState.update_frequency_days = undefined;
          newState.update_weekday = [];
          newState.update_type = [];
          newState.update_notes = undefined;
        }
        updateMyInsuranceData(newState);
        break;
      }
      case 'update_weekday': {
        const fields = { [simpleName]: value };
        if (Array.isArray(value) && value.length > 1) {
          fields.update_frequency = 'weekly';
        }
        updateMyInsuranceData(fields);
        break;
      }
      case 'ot_minutes':
      case 'pt_minutes':
      case 'speech_minutes': {
        const unitsName = simpleName.replace('minutes', 'units');
        const unitsValue = value ? parseInt(value) / 15 : null;
        updateMyInsuranceData({
          [simpleName]: value,
          [unitsName]: unitsValue,
        });
        break;
      }
      case 'ot_units':
      case 'pt_units':
      case 'speech_units': {
        const minutesName = simpleName.replace('units', 'minutes');
        const minutesValue = value ? parseInt(value) * 15 : null;
        updateMyInsuranceData({
          [simpleName]: value,
          [minutesName]: minutesValue,
        });
        break;
      }
      case 'user_id': {
        updateMyInsuranceData({
          [simpleName]: value,
          send_notification: null,
          send_notification_type: null,
        });
        break;
      }

      // case null:// unneeded as all fields are namePrefixed
      //   parentOnChange(eventOrValue, _name, type);
      //   break;
      default:
        updateMyInsuranceData({ [simpleName]: value });
    }
  };

  useEffect(() => {
    if (myValues.is_primary && authOpened && !myValues.user_id) {
      updateMyInsuranceData({
        user_id: coveredUserId || userId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, userId, updateMyInsuranceData, authOpened]);

  useEffect(() => {
    if (authOpened === true) {
      const fields = {
        update_type: [],
        update_weekday: [],
        schedule_type: '',
        case_type:
          userDivision === 'all' ? 0 : userDivision === 'subacute' ? 0 : 1,
        user_id: coveredUserId || userId,
      };
      if (values.is_readmission) {
        fields.start_date = values.admit_date;
      }
      updateMyInsuranceData(fields);
    } else {
      updateMyInsuranceData(emptyAuthFields);
    }
  }, [
    authOpened,
    updateMyInsuranceData,
    values.admit_date,
    values.is_readmission,
    coveredUserId,
    userId,
    userDivision,
  ]);

  const classes = useStyles();
  const showInsuranceInputs = myValues.payer_id === 0;
  const showInsuranceLists = !values.is_readmission && myValues.payer_id >= 0;

  return (
    <Fragment>
      {!values.is_readmission && !myValues.synced_payer_id && (
        <div
          className={clsx(classes.inputContainerWrap)}
          style={{ alignItems: 'center' }}
        >
          <AutocompleteInput
            key={isInNetwork + ''}
            reference='facility-payers/list'
            customOnChange={customOnChange}
            name={`${namePrefix}payer_id`}
            label='Map to MCO insurance company'
            validate={
              !values.is_readmission && !myValues.synced_payer_id
                ? required()
                : undefined
            }
            required={!values.is_readmission && !myValues.synced_payer_id}
            viewAll
            openOnFocus
            autocompleteProps={{ openOnFocus: true }}
            options={{ filter: { in_network: isInNetwork } }}
            fullWidth
          />
          <ToggleInsuranceNetwork
            state={isInNetwork}
            setState={value => {
              updateMyInsuranceData({ in_network: value });
              setIsInNetwork(value);
            }}
          />
        </div>
      )}
      {values.is_readmission && values.old_payer_id ? (
        <ReferenceInput
          reference='payers/list'
          perPage={1000}
          customOnChange={customOnChange}
          name='old_payer_id'
          label='Insurance'
          validate={required()}
          required
          fullWidth
          viewAll
          style={{ marginLeft: 0 }}
          disabled={values.old_payer_id}
        />
      ) : values.is_readmission && values.insurance ? (
        <ReferenceInput
          reference='payers/list'
          perPage={1000}
          customOnChange={customOnChange}
          name='insurance'
          label='Insurance'
          validate={required()}
          required
          fullWidth
          viewAll
          style={{ marginLeft: 0 }}
          disabled
        />
      ) : null}
      {!values.is_readmission && myValues.synced_payer_id && (
        <ReferenceInput
          reference='payers/list'
          perPage={1000}
          customOnChange={customOnChange}
          name={`${namePrefix}payer_id`}
          label='MCO insurance company'
          validate={required()}
          required
          fullWidth
          viewAll
          style={{ marginLeft: 0 }}
        />
      )}
      {showInsuranceLists && (
        <Fragment>
          {showInsuranceInputs && (
            <Fragment>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  name={`${namePrefix}insurance_name`}
                  validate={required()}
                  required
                  label='Company name'
                  customOnChange={customOnChange}
                />
                <ReferenceInput
                  key={[
                    `${myValues.insurance}_lob`,
                    values.tracking_date,
                    values.admit_date,
                    myValues.insurance,
                  ]}
                  reference='lob/list'
                  customOnChange={customOnChange}
                  name={`${namePrefix}line_of_business_id`}
                  label='Type (LOB)'
                  validate={required()}
                  required
                  options={{
                    filter: {
                      payer_id: myValues.payer_id,
                      effective_date: values.tracking_date || values.admit_date,
                      in_network: isInNetwork,
                    },
                  }}
                />
              </div>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  name={`${namePrefix}group_provider`}
                  label='Group provider'
                  customOnChange={customOnChange}
                />
                <FormTextField
                  name={`${namePrefix}remit_address`}
                  label='Remit to address'
                  fullWidth
                  customOnChange={customOnChange}
                />
              </div>
            </Fragment>
          )}
          <div className={clsx(classes.inputContainerWrap)}>
            <FormTextField
              name={`${namePrefix}residents_insurance_id`}
              validate={
                myValues.payer_id > HIGHEST_NO_AUTH_PAYER_ID
                  ? required()
                  : undefined
              }
              // disabling the member id field will break form when PCC doesn't have it
              // disabled={!!myValues.synced_payer_id}
              required={myValues.payer_id > HIGHEST_NO_AUTH_PAYER_ID}
              label='Member ID #'
              customOnChange={customOnChange}
            />
            {!showInsuranceInputs && (
              <ReferenceInput
                key={[
                  `${myValues.insurance}_lob`,
                  values.tracking_date,
                  values.admit_date,
                  myValues.insurance,
                ]}
                reference='lob/list'
                customOnChange={customOnChange}
                name={`${namePrefix}line_of_business_id`}
                label='Type (LOB)'
                validate={required()}
                required
                options={{
                  filter: {
                    payer_id: myValues.payer_id,
                    effective_date: values.tracking_date || values.admit_date,
                    in_network: isInNetwork,
                  },
                }}
              />
            )}
          </div>
          {!!myValues.is_primary && (
            <Fragment>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  customOnChange={customOnChange}
                  name={`${namePrefix}days_of_coverage`}
                  label='Total benefit days'
                  type='number'
                />
                <FormTextField
                  customOnChange={customOnChange}
                  name={`${namePrefix}days_used`}
                  label='Prior days used'
                  type='number'
                />
              </div>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  customOnChange={customOnChange}
                  name={`${namePrefix}days_remaining`}
                  label='Days remaining'
                  type='number'
                  disabled
                />
                <FormTextField
                  customOnChange={customOnChange}
                  name={`${namePrefix}copay`}
                  label='Copay info'
                />
              </div>
            </Fragment>
          )}
          {!!primaryValues.days_of_coverage && !myValues.is_primary && (
            <Fragment>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  customOnChange={customOnChange}
                  name={`${namePrefix}copay_start_day`}
                  label='Copay starts on day'
                  type='number'
                />
                <FormTextField
                  customOnChange={customOnChange}
                  name={`${namePrefix}copay_start_date`}
                  type='date'
                  label='Copay start date'
                  validate={composeValidators([validateDate()])}
                />
              </div>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  customOnChange={customOnChange}
                  name={`${namePrefix}copay_end_date`}
                  type='date'
                  label='Copay end date'
                  validate={composeValidators([validateDate()])}
                />
              </div>
            </Fragment>
          )}

          <EligibilitySeeker
            classes={classes}
            params={{
              first_name: values.first_name,
              last_name: values.last_name,
              dob: values.dob,
              gender: values.gender_id,
              insurance: myValues.payer_id,
              residents_insurance_id: myValues.residents_insurance_id,
            }}
          />
          {(myValues.payer_id === 0 ||
            myValues.payer_id > HIGHEST_NO_AUTH_PAYER_ID) && (
            <Fragment>
              <ReferenceInput
                key={`${myValues.payer_id}_rep`}
                reference='reps/list'
                customOnChange={customOnChange}
                name={`${namePrefix}payer_rep`}
                label='Insurance case manager name'
                fullWidth
                options={{
                  filter: { payer_id: myValues.payer_id },
                }}
                resetOption={{
                  id: 0,
                  name: 'Create new Insurance case manager',
                }}
              />

              {myValues.payer_rep === 0 && (
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    customOnChange={customOnChange}
                    name={`${namePrefix}rep_name`}
                    validate={required()}
                    required
                    label='Insurance case manager name'
                  />
                </div>
              )}

              {myValues.payer_rep >= 0 && (
                <Fragment>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      customOnChange={customOnChange}
                      name={`${namePrefix}rep_phone`}
                      type='text'
                      format='phone'
                      validate={composeValidators([
                        required(),
                        minLength(10, 'Invalid phone number'),
                      ])}
                      required
                      label='Insurance case manager phone'
                      disabled={!!myValues.payer_rep}
                    />
                    <FormTextField
                      customOnChange={customOnChange}
                      name={`${namePrefix}rep_phone_ext`}
                      label='Ext.'
                      disabled={!!myValues.payer_rep}
                    />
                  </div>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      customOnChange={customOnChange}
                      name={`${namePrefix}rep_fax`}
                      validate={composeValidators([
                        minLength(10, 'Invalid phone number'),
                      ])}
                      type='text'
                      format='phone'
                      label='Insurance case manager fax'
                      disabled={!!myValues.payer_rep}
                    />
                    <FormTextField
                      customOnChange={customOnChange}
                      name={`${namePrefix}rep_email`}
                      validate={composeValidators([email()])}
                      label='Insurance case manager email'
                      type='email'
                      disabled={!!myValues.payer_rep}
                      style={{ marginRight: 0 }}
                    />
                  </div>
                </Fragment>
              )}
              {/* Needed for spacing */}
              {/* <div style={{ flex: '1 0 160px' }} />
                <div style={{ flex: '1 0 160px' }} /> */}

              {myValues.payer_rep >= 0 && (
                <FormTextField
                  name={`${namePrefix}rep_comments`}
                  label='Insurance case manager notes'
                  multiline
                  fullWidth
                  customOnChange={customOnChange}
                  disabled={!!myValues.payer_rep}
                  validate={minReasonLength()}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
      {(myValues.payer_id === 0 ||
        myValues.payer_id > HIGHEST_NO_AUTH_PAYER_ID) &&
        !authOpened && (
          <div className={clsx(classes.inputContainerWrap)}>
            <SelectComponent
              customOnChange={customOnChange}
              label='Case type'
              validate={required()}
              required
              name={`${namePrefix}case_type`}
              choices={[
                { id: 0, name: 'Subacute' },
                { id: 1, name: 'MLTC' },
              ]}
            />
            <ReferenceInput
              reference='facility-users/list'
              customOnChange={customOnChange}
              name={`${namePrefix}user_id`}
              label='Case manager'
              validate={required()}
              required
              options={{
                filter: { create: !!!isEdit, all_roles: true },
                sort: { field: 'last_name', order: 'ASC' },
              }}
              style={{ marginRight: 0 }}
            />
          </div>
        )}
      {myValues.payer_rep >= 0 &&
        myValues.user_id &&
        (myValues.rep_fax || myValues.rep_email) && (
          <CheckboxInput
            name={`${namePrefix}send_notification`}
            label='Auto-send admission notification'
            customOnChange={customOnChange}
            checked={myValues.send_notification}
          />
        )}
      {myValues.send_notification && myValues.rep_fax && myValues.rep_email && (
        <RadioInput
          row
          name={`${namePrefix}send_notification_type`}
          customOnChange={customOnChange}
          value={myValues.send_notification_type}
          label=''
          radios={[
            {
              label: 'Fax',
              value: 'fax',
            },
            {
              label: 'Email',
              value: 'email',
            },
          ]}
        />
      )}
      {(values.is_readmission ||
        myValues.payer_id === 0 ||
        myValues.payer_id > HIGHEST_NO_AUTH_PAYER_ID) && (
        <div className={classes.secondaryActionContainer}>
          <DialogContentText
            color='primary'
            onClick={() => setAuthOpened(!authOpened)}
            className={classes.secondaryAction}
          >
            {authOpened ? <RemoveIcon /> : <AddIcon />}{' '}
            {authOpened ? 'Remove' : 'Add'} auth for{' '}
            {myValues.is_primary ? 'primary payer' : 'secondary payer'}
          </DialogContentText>
        </div>
      )}
      {authOpened &&
        Array.isArray(myValues.update_weekday) &&
        Array.isArray(myValues.update_type) && (
          <AuthFields
            customOnChange={customOnChange}
            values={values}
            setFormData={
              values.is_readmission ? setFormData : setFormDataForMyInsurance
            }
            type={namePrefix}
            lastCase={lastCase}
            subLevelChoices={primarySubLevelChoices}
          />
        )}
    </Fragment>
  );
};
