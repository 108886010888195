import { makeStyles } from '@material-ui/core';
import { styles } from '../../design';
export const useStyles = makeStyles(theme => ({
  drawerOpen: {
    height: '60vh',
    maxHeight: 782,
    width: 600,
    left: 'initial !important',
    top: 'initial !important',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fullHeight: {
    height: '85vh',
  },
  drawerMinimized: {
    left: 'initial !important',
    top: 'initial !important',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    height: 40,
    width: 260,
  },
  toolbar: {
    minHeight: 40,
    background: '#404040',
    color: '#FFFFFF',
    ...styles.flexRow,
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
  },
  addressPaper: {
    zIndex: 5,
  },
  toolbarMinimized: {
    cursor: 'pointer',
  },
  iconBtn: {
    color: '#FFFFFF',
  },
  content: {
    height: '65vh',
    padding: '0 16px',
    overflowY: 'auto',
  },
  chip: {
    margin: 3,
    height: 25,
    color: 'primary',
    backgroundColor: "'rgba(240,	244,	250)'",
  },
  inputRoot: {
    // width: '49%',
    width: 270,
  },
  autocompleteRoot: {
    width: 270,
  },
  faxInputs: {
    ...styles.flexRow,
    justifyContent: 'space-between',
  },
  listLine: {
    margin: 0,
  },
  attText: {
    backgroundColor: '#f5f5f5',
    border: '1px solid transparent',
    fontWeight: 'bold',
    margin: '0 0 9px',
    overflowY: 'hidden',
    padding: '4px 4px 4px 8px',
    cursor: 'pointer',
  },
  sendContainer: {
    ...styles.flexRow,
    padding: 16,
  },
}));
