import { makeStyles } from '@material-ui/core';
import { styles, colors } from '../design';
export const useStyles = makeStyles(theme => ({
  main: {
    padding: 40,
    ...styles.flexColumn,
    alignItems: 'flex-start',
  },
  title: {
    fontFamily: theme.palette.text.fontFamily,
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.black,
    marginBottom: 20,
  },
  tableContainer: {
    marginBottom: 15,
  },
  typesList: {
    minWidth: 230,
    marginBottom: 10,
  },
  deleteBtn: {
    background: theme.palette.error.main,
    color: '#FFFFFF',
    marginLeft: 7,
    '&:hover': {
      background: theme.palette.error.main,
    },
  },
}));
